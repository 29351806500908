import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { RestService } from './rest.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CalculateAutoOrderService {
  ItemLength: any;
  SmartOrderSubItems: any;
  SmartOrderItems: any;
  SmartOrdersSubTotal: any;
  SmartOrderBVTotal: any;
  customerDetail: any;
  SmartOrder: any;
  AllshipMethods: any;
  SmartOrderDetails: any = {};
  CustomShipMethods: any;
  IsCallCalculateOrder: boolean = false;
  ParentItemLength = 0;
  constructor(
    private _toastr: ToastrService, private _route: Router,
    private _restService: RestService) {
    const SmartOrderItems = JSON.parse(localStorage.getItem('SmartOrderItems'));
    this.customerDetail = JSON.parse(localStorage.getItem('customerDetail')) || {};
    this.SmartOrderDetails = JSON.parse(localStorage.getItem('SmartOrderDetails')) ? JSON.parse(localStorage.getItem('SmartOrderDetails')) : {};
    this.SmartOrderItems = SmartOrderItems || [];
    this.IsCallCalculateOrder = false;
    this.AllshipMethods = '';
    this.ItemLength = SmartOrderItems ? SmartOrderItems.length : 0;
    this.SmartOrdersSubTotal = JSON.parse(localStorage.getItem('SmartOrdersSubTotal'));
    this.SmartOrderBVTotal = JSON.parse(localStorage.getItem('SmartOrderBVTotal'));
    this.SmartOrderSubItems = JSON.parse(localStorage.getItem('SmartOrderSubItems')) || [];
    if (SmartOrderItems && SmartOrderItems.length > 0) {
      this.ParentItemLength = _.filter(SmartOrderItems, (items) => (items.ParentId === '' || items.ParentId === items.ItemCode || !items.ParentId)).length;
    }
  }

  TotalItemLength(TotalItemLength): any{
    this.ItemLength = TotalItemLength + 0;

  }

  gettotalItemLength(): any {
    return this.ItemLength;
  }

  SetSmartOrderSubItems(SmartOrderSubItems): any{
    this.SmartOrderSubItems = SmartOrderSubItems;
  }

  getSmartOrderSubItems(): any {
    return this.SmartOrderSubItems;
  }

  SetSmartOrderItems(SmartOrderItems): any{
    this.SmartOrderItems = SmartOrderItems;
    if (SmartOrderItems.length > 0) {
      this.ParentItemLength = _.filter(SmartOrderItems, (items) => (items.ParentId === '' || items.ParentId === items.ItemCode || !items.ParentId)).length;
    }
  }

  getSmartOrderItems(): any {
    return this.SmartOrderItems;
  }

  getIsCallCalculateOrder(): boolean {
    return this.IsCallCalculateOrder;
  }
  setIsCallCalculateOrder(IsCallCalculateOrder): any{
    this.IsCallCalculateOrder = IsCallCalculateOrder;

  }

  getSmartOrdersSubTotal(): any {
    return this.SmartOrdersSubTotal;
  }

  SetSmartOrdersSubTotal(SmartOrdersSubTotal): any{
    this.SmartOrdersSubTotal = SmartOrdersSubTotal;
  }

  getSmartOrderBVTotal(): any {
    return this.SmartOrderBVTotal;
  }

  SetSmartOrderBVTotal(SmartOrderBVTotal): any{
    this.SmartOrderBVTotal = SmartOrderBVTotal;
  }

  calculate(ShipMethodID, hideLoader = false): Promise<{}> {
    const promise = new Promise((resolve, reject) => {
      this.customerDetail = JSON.parse(localStorage.getItem('customerDetail')) || {};
      if (this.SmartOrderItems.length > 0) {
        if (!hideLoader) {
        }
        const itemDetail = [];
        let ParentItemCode = '';
        _.each(this.SmartOrderItems, (obj) => {
          if (obj.ParentId && obj.ParentId !== obj.ItemCode) {
            ParentItemCode = obj.ParentId.toString();

          }
          else {
            ParentItemCode = '';

          }
          itemDetail.push({ ItemCode: obj.ItemCode, Quantity: obj.Quantity, ParentItemCode: ParentItemCode });

        });
        if (itemDetail.length > 0) {
          this.ParentItemLength = _.filter(itemDetail, (items) => (items.ParentItemCode === '')).length;
        }
        const request = {
          ShipMethodId: ShipMethodID ? ShipMethodID : 0,
          ShippingCity: JSON.parse(localStorage.getItem('City')) ? JSON.parse(localStorage.getItem('City')) : '',
          State: JSON.parse(localStorage.getItem('State')) ? JSON.parse(localStorage.getItem('State')) : '',
          Zip: JSON.parse(localStorage.getItem('Zip')) ? JSON.parse(localStorage.getItem('Zip')) : '',
          Country: JSON.parse(localStorage.getItem('CountryCode')) ? JSON.parse(localStorage.getItem('CountryCode')) : '',
          Items: itemDetail,
          IsProfessional: false,
          IsNonprofit: false,
          OrderType: 4
        };
        return this.calculateSmartOrderOrder(request).subscribe((res: any) => {
          if (res) {
            if (res.Warnings && res.Warnings.length > 0) {
              this._toastr.error(res.Warnings[0], 'Error');
            }
            this.SmartOrderDetails.Total = res.Total;
            this.SmartOrderDetails.SubTotal = res.SubTotal;
            this.SmartOrderDetails.BV = res.BusinessVolumeTotal;
            this.SmartOrderDetails.Tax = res.TaxTotal;
            this.SmartOrderDetails.ShippingTotal = res.ShippingTotal;
            this.AllshipMethods = res.ShipMethods;
            this.CustomShipMethods = res.CustomShipMethods;
            localStorage.setItem('AllShipMethods', JSON.stringify(res.ShipMethods));
            localStorage.setItem('SmartOrderDetails', JSON.stringify(this.SmartOrderDetails));
            localStorage.setItem('CustomShipMethods', JSON.stringify(res.CustomShipMethods));
            resolve(res);
          }
          else {
            this._toastr.error(res.Message, 'Error');
            reject();
          }

        }, error => {
          this._toastr.error(error, 'Error');
          reject();
        });
      } else {
        this.IsCallCalculateOrder = false;
        resolve(0);
        return false;
      }
    });
    return promise;
  }
  
  calculateSmartOrderOrder(request: any): Observable<HttpResponse<object>>{
    const _url = '/RecurringOrders/CalculateOrder';
    return this._restService.Post(_url, request);
  }

  getSmartOrderDetails(): any {
    return this.SmartOrderDetails;
  }

  getAllshipMethods(): any {
    return this.CustomShipMethods;
  }

}
