import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API_URL } from 'app/constants';
import { AuthService } from 'app/shared/auth.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { RestService } from 'app/shared/rest.service';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-IncomeOpportunity',
  templateUrl: './IncomeOpportunity.component.html',
  styleUrls: ['./IncomeOpportunity.component.scss']
})
export class IncomeOpportunityComponent implements OnInit {

  webalias: string;
  realImpactBanner: any = [];
  BusinessBanner: any = [];
  IncomeChangeBanner: any = [];
  QuesConsiderBanner: any = [];
  CrownPersonBanner: any = [];
  togglePayForProducts: boolean = false;
  toggleGrowATeam: boolean = false;
  toggleGrowInLeader: boolean = false;
  toggleDiamondPecks: boolean = false;
  toggleSkyLimitCrown: boolean = false;
  @ViewChild('slickModal', { static: false }) slickModal: SlickCarouselComponent;
  @ViewChild('slickModal1', { static: false }) slickModal1: SlickCarouselComponent;
  @ViewChild('slickModal2', { static: false }) slickModal2: SlickCarouselComponent;
  @ViewChild('slickModal3', { static: false }) slickModal3: SlickCarouselComponent;
  @ViewChild('slickModal4', { static: false }) slickModal4: SlickCarouselComponent;
  public slideConfig = {
    "dots": false,
    "infinite": true,
    "speed": 500,
    "slidesToShow": 1,
    "adaptiveHeight": false,
    "autoplay": true,
    "autoplaySpeed": 5000,
    "dir": 'ltr',
    "arrows": false,
    "pauseOnHover": false
  };
  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    public _auth: AuthService,
    public _sharedMethodService: SharedMethodService,
    public _enrollSession: EnrollSessionService,
    private _restService: RestService,
    private _toastr: ToastrService,
    public _router: Router,
    private _activateRoute: ActivatedRoute,
  ) { 
    var url = new URL(window.location.href);
    this.webalias = this._activateRoute.snapshot.queryParamMap?.get('giftcard') || url?.searchParams.get("giftcard") || sessionStorage.getItem('giftCardName');
  }

  ngOnInit(): void {
    var LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    if (this._auth.isLoggedIn()) {
      if (LoggedInUser?.CustomerType == 20 || LoggedInUser?.CustomerType == 22) {
        this.getImage(LoggedInUser?.EnrollerID);
      } else {
        this.getImage(LoggedInUser?.CustomerID);
      }
    } else {
      this.giftcardFunctionality();
    }
    const script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
      script.text = `
      {
          "@context": "https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=258138394"
      }
    `;
    script.async = true;
    script.defer = true;
    this._renderer2.appendChild(this._document.body, script);
    this.realImpactBanner = [
      { url: '../../../assets/images/IncomeOpportunity/RealImpact1.png' },
      { url: '../../../assets/images/IncomeOpportunity/RealImpact2.png' },
    ];
    this.BusinessBanner = [
      { url: '../../../assets/images/IncomeOpportunity/BusinessAmyDave1.png' },
      { url: '../../../assets/images/IncomeOpportunity/BusinessAmyDave2.png' },
    ];
    this.IncomeChangeBanner = [
      { url: '../../../assets/images/IncomeOpportunity/lifeincomeslick1.png' },
      { url: '../../../assets/images/IncomeOpportunity/lifeincomeslick2.png' },
      { url: '../../../assets/images/IncomeOpportunity/lifeincomeslick3.png' }
    ];
    this.QuesConsiderBanner = [
      { url: '../../../assets/images/IncomeOpportunity/QuesConsider1.png' },
      { url: '../../../assets/images/IncomeOpportunity/QuesConsider2.png' },
      { url: '../../../assets/images/IncomeOpportunity/QuesConsider3.png' },
      { url: '../../../assets/images/IncomeOpportunity/QuesConsider4.png' }
    ];
    this.CrownPersonBanner = [
      { url: '../../../assets/images/IncomeOpportunity/CrownSonia.png' },
      { url: '../../../assets/images/IncomeOpportunity/CrownMellisa.png' },
      { url: '../../../assets/images/IncomeOpportunity/CrownArieal.png' },
      { url: '../../../assets/images/IncomeOpportunity/CrownAlisson.png' },
      { url: '../../../assets/images/IncomeOpportunity/CrownTracie.png' },
      { url: '../../../assets/images/IncomeOpportunity/CrownMarryBeth.png' },
    ]
  }
  nextRealImpact() {
    this.slickModal.slickNext();
  }
  prevRealImpact() {
    this.slickModal.slickPrev();
  }
  nextBusiness() {
    this.slickModal1.slickNext();
  }
  prevBusiness() {
    this.slickModal1.slickPrev();
  }
  nextIncomeChange() {
    this.slickModal2.slickNext();
  }
  prevIncomeChange() {
    this.slickModal2.slickPrev();
  }
  nextCrownPerson() {
    this.slickModal4.slickNext();
  }
  prevCrownPerson() {
    this.slickModal4.slickPrev();
  }
  giftcardFunctionality() {
    if (this.webalias && this.webalias != '' && this.webalias != null && this.webalias != "null") {
      const request = '?WebAlias=' + this.webalias;
      this._restService.GetCustomerSite(request).subscribe((result: any) => {
        if (result.Data != null) {
          this._enrollSession.data.webAlias = this.webalias;
          sessionStorage.setItem('giftCardName', this._enrollSession.data.webAlias);
          this._enrollSession.data.RetailEnroll.webAlias = result.Data.firstNameField + ' ' + result.Data.lastNameField;
          this.getImage(result.Data.customerIDField);
        } else {
          this._toastr.error('Invalid WebAlias.', 'Error');
        }
      }, error => {
        this._toastr.error('Somthing is wrong Please try again', 'Error');
      });
    }
  }
  getImage(id) {
    this._enrollSession.customerImage =  API_URL + "/NonProfitEnrollment/GetCustomerImageData?customerId=" + id || "../../../../assets/images/IncomeOpportunity/Michelle Boersma-3.svg";
  }
  ClickToOpenToggle(id: number) {
    const toggle = [this.togglePayForProducts, this.toggleGrowATeam, this.toggleGrowInLeader, this.toggleDiamondPecks, this.toggleSkyLimitCrown];
    toggle.forEach((_, index) => {
      toggle[index] = (id === index + 1) ? !toggle[index] : false;
    });
    [this.togglePayForProducts, this.toggleGrowATeam, this.toggleGrowInLeader, this.toggleDiamondPecks, this.toggleSkyLimitCrown] = toggle;
  }
}
