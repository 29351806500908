<div class="dialog-content-wrapper password-reset" id="two-way-wrapper">
    <mat-toolbar matDialogTitle class="m-0">
    </mat-toolbar>
    <div style="display:flex;overflow-y: hidden;" class="pop-up-background">
        <div class="pop-up-div" id="pop-up-div">
            <div class="pop-up-login-div" id="login_container">
                <h3 class="text-center p-t-20 f-s-24">Reset Password</h3>
                <br>
                <form [formGroup]="customerLoginForm" name="Login" id="login">
                    <div fxLayout="column" class="p-20-15-10">
                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                            <div fxLayout="column" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100"
                                style="max-width:400px;margin:auto" class="passwordDiv">
                                <div style="display: flex;">
                                    <input [type]="showPassword ? 'text' : 'password'"
                                        class="form-field w-input input-field"
                                        (keyup)="validatePassword(userInfo.password)" maxlength="256" name="password"
                                        formControlName="password" placeholder="Enter Password" minlength="8"
                                        id="Password" [(ngModel)]="userInfo.password" required>
                                    <span class="toggleEye" (click)="togglePasswordShowHide()"><i class="fa fa-eye"
                                            *ngIf="showPassword"></i><i class="fa fa-eye-slash"
                                            *ngIf="!showPassword"></i></span>
                                    <span class="rightIcon"><i class="fa fa-check"
                                            *ngIf="userInfo.password && userInfo.password.length > 0 && customerLoginForm.get('password').touched && (hasNum && hasUpper && hasLower && hasSpecial && hasLength)"></i></span>
                                    <span class="crossIcon"><i class="fa fa-close"
                                            *ngIf="userInfo.password && userInfo.password.length > 0 && customerLoginForm.get('password').touched && !(hasNum && hasUpper && hasLower && hasSpecial && hasLength)"></i></span>
                                </div>
                                <div class="error-box text-left">
                                    <span class="help-blocks"
                                        *ngIf="!customerLoginForm.get('password').valid && customerLoginForm.get('password').touched">
                                        <span *ngIf="customerLoginForm.get('password').errors['required']">This
                                            field is required<br></span>
                                    </span>
                                    <span class="Error-font-size"
                                        *ngIf="customerLoginForm.get('password').dirty && userInfo.password && userInfo.password.length > 0">
                                        <span *ngIf="!(hasNum && hasUpper && hasLower && hasSpecial && hasLength)">
                                            <span class="not-help-blocks">Password must contain </span><span
                                                [ngClass]="{'help-blocks': !hasLength, 'not-help-blocks':hasLength}">
                                                8 characters, </span><span
                                                [ngClass]="{'help-blocks': !hasNum, 'not-help-blocks':hasNum}">1
                                                numeric, </span><span
                                                [ngClass]="{'help-blocks': !hasUpper, 'not-help-blocks':hasUpper}">1
                                                upper case letter, </span><span
                                                [ngClass]="{'help-blocks': !hasLower, 'not-help-blocks':hasLower}">1
                                                lower case letter, </span><span
                                                [ngClass]="{'help-blocks': !hasSpecial, 'not-help-blocks':hasSpecial}">1
                                                special character</span>.</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                            <div fxLayout="column" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100"
                                style="max-width:400px;margin:auto" class="passwordDiv">
                                <div style="display: flex;">
                                    <input [type]="showResetPassword ? 'text' : 'password'" class="form-field w-input"
                                        [(ngModel)]="userInfo.reenterPassword" maxlength="256" name="reenterPassword"
                                        formControlName="reenterPassword" placeholder="Re-Enter Password"
                                        id="reenterPassword" required>
                                    <span class="toggleEye" (click)="toggleResetPasswordShowHide()"><i class="fa fa-eye"
                                            *ngIf="showResetPassword"></i><i class="fa fa-eye-slash"
                                            *ngIf="!showResetPassword"></i></span>
                                </div>
                                <div class="error-box text-left">
                                    <span class="help-blocks"
                                        *ngIf="!customerLoginForm.get('reenterPassword').valid && customerLoginForm.get('reenterPassword').touched">
                                        <span *ngIf="customerLoginForm.get('reenterPassword').errors['required']">This
                                            field is required</span>
                                        <span
                                            *ngIf="customerLoginForm.get('reenterPassword').errors['passwordMismatch']">Password
                                            Does Not Matched</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div layout="row" fxLayout-sm="row" class="m-t-20 login-bottom" fxLayout-xs="column"
                        fxLayout-align="start space-around" fxLayout-align-xs="center start"
                        fxLayout-align-sm="center start" layout-wrap>
                        <button type="submit" class="btn-project w-80 upper-case f-s-18 m-l-custom1" color="primary"
                            mat-raised-button [disabled]="!(hasNum && hasUpper && hasLower && hasSpecial && hasLength)"
                            (click)="reset()">Reset</button>
                        <button class="btn-project w-80 upper-case f-s-18 m-l-custom" color="primary" mat-raised-button
                            (click)="backtohome()">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>