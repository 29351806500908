<div class="page-wrapper">
    <div class="ult-checkout-section wf-section" *ngIf="false">
        <app-header></app-header>
        <app-stepper *ngIf="_enrollSession.mobileView && false"></app-stepper>
         
        <div class="checkout-div" id="login_container">
            <app-stepper *ngIf="!_enrollSession.mobileView"></app-stepper>
            <div id="login" class="font-IBM bgm-white">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" ng-cloak flex
                     class="p-10 login text-center">
                    <div fxLayout-padding fxFlex="50" fxFlex-xs="100">
                        <div class="p-20 mainborder">
                            <!------------------------------------- Login Form Section ---------------------------------------------->

                            <div class="m-t-20 border">
                                <h3 class="text-center p-t-20 f-s-24 ">CUSTOMER LOGIN</h3>
                                <form [formGroup]="customerLoginForm" name="Login" id="login">
                                    <div fxLayout="column" class="p-20-15-10">
                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                                            <div fxLayout="column" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" style="max-width:400px;margin:auto">
                                                <div>
                                                    <input type="text" class="form-field w-input input-field" maxlength="256"
                                                           name="userName" formControlName="userName" placeholder="Login Name"
                                                           id="Email" [(ngModel)]="userInfo.userName" required>
                                                    <!-- <i class="fa fa-user icon"></i> -->
                                                </div>
                                                <div class="error-box text-left">
                                                    <span class="help-block"
                                                          *ngIf="!customerLoginForm.get('userName').valid&&customerLoginForm.get('userName').touched">
                                                        <span *ngIf="customerLoginForm.get('userName').errors['required']">
                                                            This
                                                            field is required
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                                            <div fxLayout="column" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100">
                                                <div>
                                                    <input type="password" class="form-field w-input"
                                                           [(ngModel)]="userInfo.Password" maxlength="256" name="password"
                                                           formControlName="password" placeholder="password" id="password"
                                                           required>
                                                    <!-- <i class="fa fa-lock icon"></i> -->
                                                </div>
                                                <div class="error-box text-left">
                                                    <span class="help-block"
                                                          *ngIf="!customerLoginForm.get('password').valid&&customerLoginForm.get('password').touched">
                                                        <span *ngIf="customerLoginForm.get('password').errors['required']"
                                                              class="">
                                                            This
                                                            field is required
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div layout="row" fxLayout-sm="column" class="m-t-20 login-bottom"
                                         fxLayout-xs="column" fxLayout-align="start center"
                                         fxLayout-align-xs="center start" fxLayout-align-sm="center start" layout-wrap>
                                        <button type="submit" [ngStyle.lt-md]="{'width':100+'%'}" mat-raised-button
                                                class="btn-project w-80 upper-case f-w-600 f-s-18" color="primary"
                                                mat-raised-button (click)="SignIn()">
                                            SIGN IN
                                        </button>
                                    </div>
                                    <div layout="row" fxLayout-sm="column" class="m-t-20 login-bottom"
                                         fxLayout-xs="column" fxLayoutAlign="center start"
                                         fxLayoutAlign-xs="center start" fxLayout-align-sm="center start" layout-wrap>
                                        <a class="forgot-pass cursor-pointer m-t-20" routerLink="/forgot-password" id="forgot-pass"
                                           (click)="forgotPassword()">
                                            FORGOT
                                            PASSWORD ?
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ult-checkout-section wf-section">
       
    </div>
    </div>
