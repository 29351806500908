import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EnrollSessionService } from "app/shared/enrollSession.service";
import { ToastrService } from "ngx-toastr";



@Component({
    selector: 'ShippingWebAliasCheckDialog',
    templateUrl: './ShippingWebAliasCheckDialog.component.html',
    styleUrls: ['./ShippingWebAliasCheckDialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ShippingWebAliasCheckDialogComponent implements OnInit {

    ShippingWebAlias: FormControl = new FormControl('', Validators.required);
    existingCustomerData: any;
    constructor(
        public dialogRef: MatDialogRef<ShippingWebAliasCheckDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _toastr: ToastrService,
        public _enrollSession: EnrollSessionService,
    ){}

    ngOnInit(): void {
        this.existingCustomerData = JSON.parse(sessionStorage.getItem('CustomExistingCustomerType18&11'));
    }

    closePopup(){
        this.dialogRef.close();
    }
    submitButton(){
        if(this.ShippingWebAlias.invalid){
            this._toastr.error('', 'Please enter atleast one option.');
            return;
        }
        if(this.ShippingWebAlias.valid)
        {
            if(this.ShippingWebAlias.value == 0)
                {
                    this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
                    this._enrollSession.data.RetailEnroll.SponsorId = this.existingCustomerData.CustomerSite.customerIDField;
                    this._enrollSession.data.RetailEnroll.EnrollerId = this.existingCustomerData.CustomerSite.customerIDField;
                    this._enrollSession.data.RetailEnroll.webAliasField = this.existingCustomerData.CustomerSite.webAliasField;
                    this._enrollSession.data.CustomerWebAlias = this.existingCustomerData.CustomerSite.webAliasField;
                    this._enrollSession.data.ValidGiftCardCode = this._enrollSession.data.RetailEnroll.webAliasField;
                    this._enrollSession.data.RetailEnroll.webAlias = this.existingCustomerData.CustomerSite.firstNameField + ' ' + this.existingCustomerData.CustomerSite.lastNameField;
                    this._enrollSession.data.ReferredBy = this.existingCustomerData.CustomerSite.webAliasField;
                    this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode = this._enrollSession.data.RetailEnroll.webAliasField;
                    this._enrollSession.data.RetailEnroll.isValidwebalias = true;
                    this._enrollSession.data.isWebAliasUpdated = false;
                    this.dialogRef.close();
                }
                else
                {
                    this._enrollSession.data.isWebAliasUpdated = true;
                    this.dialogRef.close();
                }
        }
    }
}