<div id="TopHeader">
    <div class="row mt-10 wp-block-heading has-text-align-center"
        [ngClass]="{'header-padding':_sharedMethodService.isOpen,'header-padding-full':!_sharedMethodService.isOpen}"
        id="header-banner" *ngIf="!_router.url.includes('/Ultlifestyle')">
        <div fxFlex="90" fxFlex.lt-md="100" fxLayout="row">
            <mat-icon class="cursor-pointer" (click)="prevBanner()"> keyboard_arrow_left </mat-icon>
            <ngx-slick-carousel class="sliding" #slickModalBanner="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of bannerstext; let i = index" class="text-padding"  [ngClass]="{'text-underline-banner': (slide.url !== undefined && slide.url !== '' && slide.url !== null)}">
                <a class="bannerText" [ngStyle]="getColor()" [ngClass]="{'text-cursor': (slide.url !== undefined && slide.url !== '' && slide.url !== null)}" target="_blank" [href]="slide.url"><span id="textcolor">{{slide.text}}</span></a>
            </div>
            </ngx-slick-carousel>
            <mat-icon class="cursor-pointer" (click)="nextBanner()"> keyboard_arrow_right </mat-icon>
        </div>
    </div>
    <nav class="navbar navbar-expand-lg navbar-light navbarheader"
        [ngClass]="{'fixed-top':_enrollSession.SummaryCartMobOnly}"
        [ngClass]="{'noClick':_sharedMethodService.isOpenDialog}" id="header">
        <div class="container-fluid">
            <div fxFlex fxLayout="column" *ngIf="!_enrollSession.mobileViewMethod()">
                <div class="container mx-width" fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center" *ngIf="_router.url !== '/Confirmation' && _router.url !== '/Review-Page'">
                    <div class="collapse navbar-collapse m-t-5" fxFlex="50" fxLayoutAlign="start center" id="NavBarSize">
                        <div class="header-container mx-w-60" id="Menu_Bar" *ngIf="!_enrollSession.mobileViewMethod()">
                            <ul class="navbar-nav ms-auto me-auto mb-2 mb-lg-0" id="navbar-menu-desktop">
                                <li class="nav-item" *ngIf="(!_router.url.includes('/homepage') && !_router.url.includes('/Ultlifestyle'))">
                                    <a class="nav-link black-color f-s-vw"
                                        (click)="_sharedMethodService.closeCart()" routerLink="/Home">Home |</a>
                                </li>
                                <li class="nav-item" *ngIf="!_router.url.includes('/Ultlifestyle')">
                                    <a class="nav-link cursor-pointer black-color lifestyleheaders f-s-vw"
                                        (click)="RedirectToUltLifestyle();_sharedMethodService.closeCart()">Lifestyle
                                        Subscription</a>
                                </li>
                                <li class="nav-item customdropdown" *ngIf="hideListForUltlifestyle">
                                    <div class="dropdown">
                                        <a class="nav-link dropbtn black-color f-s-vw display-flex"
                                            >| Products&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<mat-icon
                                            class="keyboard_arrow_right arrow">keyboard_arrow_down</mat-icon></a>
                                        <div class="dropdown-content">
                                            <div class="menu-bar-divider-dropdown" *ngIf="hideListForUltlifestyle"></div>
                                            <ng-container *ngFor="let menuOption of _commonService.DropdownMenu">
                                                <a class="nav-link dropbtn cursor-pointer black-color f-s-1vw"
                                                    *ngIf="menuOption.WebCategoryID != '544';"
                                                    (click)="_commonService.ClickToWebCategory(menuOption.WebCategoryID)">{{menuOption.WebCategoryDescription}}</a>
                                                <div class="menu-bar-divider-dropdown" *ngIf="hideListForUltlifestyle && menuOption.WebCategoryID != '544';"></div>
                                            </ng-container>
                                            <a class="nav-link dropbtn black-color f-s-1vw"
                                            (click)="redirectToTodaysOrder()">All Products</a>
                                            <ng-container *ngFor="let menuOption of _commonService.DropdownMenu">
                                                    <div class="menu-bar-divider-dropdown" *ngIf="hideListForUltlifestyle && isBPCustomerExist() && menuOption.WebCategoryID == '544';"></div>
                                                    <a class="nav-link dropbtn cursor-pointer black-color f-s-1vw" *ngIf="isBPCustomerExist() && menuOption.WebCategoryID == '544';"
                                                        (click)="_commonService.ClickToWebCategory(menuOption.WebCategoryID)">{{menuOption.WebCategoryDescription}}</a>
                                            </ng-container>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div fxFlex="50" fxLayoutAlign="end center">
                        <!------------------------------    Search Button For Desktop   ------------------------------------>
                    <div *ngIf="(!_enrollSession.mobileViewMethod() && !_router.url.includes('/Ultlifestyle'))">
                        <!-- Desktop -->
                        <form class="search right-50" (click)="searchIconHandle()">
                            <div class="search__wrapper tooltip_header">
                                <input [matAutocomplete]="auto" type="text" name="searchedValue" placeholder="Search" id="open_input"
                                    (input)="onSearchChange($event)" [(ngModel)]="searchedValue"
                                    (keydown.enter)="selectProdcut(searchedValue)" class="searchValueDesktop colorGrey">
                                <button type="submit" class="search__icon"
                                    (click)="redirectToProduct(_sharedMethodService.headerSearchValue)">
                                    <img src="../../../assets/images/home_img/search-interface-symbol.png" alt="" class="w-20"></button>
                                <div style="width: 70%;">
                                    <div *ngIf="isDescriptionDisplayed()" class="border-div"></div>
                                    <mat-autocomplete #auto="matAutocomplete" class="bg-white search-border-box1"
                                        (optionSelected)="redirectToProduct($event)">
                                        <div *ngIf="_sharedMethodService.headerSearchValue?.length > 0" class="serach-margin">
                                            <mat-option #option class="search-option"
                                                *ngFor="let product of productList | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2,LongDetail1,LongDetail2': getHeaderSearchedValue()"
                                                [value]="product.Description" (click)="redirectProduct(product)">
                                                {{product.Description}}
                                            </mat-option>
                                        </div>
                                    </mat-autocomplete>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!------------------------------    Cart button for Desktop   ------------------------------------>
                    <div class="cartButtonDesktop" *ngIf="(!_enrollSession.mobileViewMethod() && showCart) || (_sharedMethodService.shareLinkCart())"
                        >
                        <div class="">
                            <button class="nav-item cart-icon" type="button"
                                (click)="_sharedMethodService.toggleSidenav(sideNav)">
                                <img src="assets/icons/add-to-cart.png" alt="" class="cart-w-20 pt-4px">
                                <sup class="f-w-600 c-green" *ngIf="getQuanity() > 0" [textContent]="getQuanity()"></sup>
                            </button>
                        </div>
                    </div>

                    <!------------------------------    not logged in   ------------------------------------>
                    <div fxLayoutAlign="center none" *ngIf="!_auth.isLoggedIn()">
                        <ul class="navbar-nav ul-login bg-c"
                            *ngIf="!_auth.isLoggedIn() && _router.url != '/Shipping' && _router.url != '/Checkout' && _router.url != '/Subscribe'"
                            id="bg-image">
                            <li class="nav-item" [ngClass]="{'noClick':_sharedMethodService.isOpenDialog}">
                                <button type="button" class="btn btn-secondary dropdown-toggle logintext"
                                    (click)="login()" data-bs-toggle="collapse" data-bs-target="#navLoginContent"
                                    aria-controls="navLoginContent" aria-haspopup="true" aria-expanded="false">
                                    <img src="../../../assets/icons/icon-profile.png" alt="" class="w-20">
                                </button>
                            </li>
                        </ul>
                    </div>

                <!------------------------------    logged in user   ------------------------------------>
                    <div class="mx-width-100" *ngIf="_auth.isLoggedIn()">
                        <div *ngIf="!_enrollSession.mobileViewMethod()">
                            <div class="navbar-nav bg-c"
                                *ngIf="_auth.isLoggedIn()" id="user_details">

                                <!-- Account Button For Desktop -->
                                <div class="btn-group" style="display: flex;flex-direction: column;">

                                    <div id="dropdownMenuButton" style="display: flex;border-radius: 100px;flex-direction: column;">
                                        <button type="button" class="dropdown-toggle accountDesktop"
                                            [ngClass]="{'buttonopened': isshowmenu}" aria-haspopup="true"
                                            aria-expanded="false" (click)="isshowmenu = !isshowmenu">
                                            Account&nbsp;&nbsp;&nbsp;&nbsp;
                                            <mat-icon
                                            class="keyboard_arrow_right" *ngIf="isshowmenu">keyboard_arrow_down</mat-icon>
                                        </button>
                                        <div *ngIf="isshowmenu" class="dropdown-content dropdownAccountBar">
                                            <div class="">
                                                <div class="border-line-bar"></div>
                                            </div>
                                            <a class="dropdown-item cursor-pointer myaccountText"
                                                (click)="ClicktoOrderHistory()">
                                                <!-- My Account -->
                                                <span>Order History</span>
                                            </a>
                                            <div class="">
                                                <div class="border-line-bar"></div>
                                            </div>
                                            <a class="dropdown-item cursor-pointer myaccountText"
                                                (click)="ClicktoSubscription()">
                                                <span>Subscription</span>
                                            </a>
                                            <div class="">
                                                <div class="border-line-bar"></div>
                                            </div>
                                            <a class="dropdown-item cursor-pointer myaccountText upgradeTxt" *ngIf="isUpgradableCustomer()" (click)="ClicktoUpgrade()">
                                                <span>Become a Brand Partner </span>
                                            </a>
                                            <div class="" *ngIf="isUpgradableCustomer()">
                                                <div class="border-line-bar"></div>
                                            </div>
                                            <a class="dropdown-item myaccountText"
                                                [ngClass]="{'myaccountText':!isshowreward,'myaccountTextReward':isshowreward}"
                                                (click)="isshowreward = !isshowreward">
                                                <span>Rewards Points</span><mat-icon class="keyboard_arrow_right"
                                                    *ngIf="!isshowreward">keyboard_arrow_right</mat-icon><mat-icon
                                                    class="keyboard_arrow_down"
                                                    *ngIf="isshowreward">keyboard_arrow_down</mat-icon>
                                                <!-- Submenu -->
                                                <a *ngIf="isshowreward" class="dropdown-item reward-points">
                                                    <span class="p-0"><b>{{_sharedMethodService.balanceField | number : '1.0-0'}}</b></span><br>
                                                    <span class="reward-point-text">Rewards Points Available</span>
                                                </a>
                                                <a *ngIf="isshowreward" class="dropdown-item reward-point-text color-green" (click)="ClickToLearnMoreRewardPoints()">Learn More</a>
                                            </a>
                                            <div class="">
                                                <div class="border-line-bar"></div>
                                            </div>
                                            <a class="dropdown-item cursor-pointer myaccountText"
                                                (click)="ClicktoSettings()">
                                                <span>Settings</span>
                                            </a>
                                            <div class="">
                                                <div class="border-line-bar"></div>
                                            </div>
                                            <span class="dropdown-item cursor-pointer logouttext"
                                                (click)="logout()">Sign Out</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

                <!------------------------------    Print and Order History on Confirmation Page for Desktop  ------------------------------------>
                <div class="container mx-width" id="printButtonNone" fxFlex fxLayout="row" fxFlex="100" *ngIf="_router.url === '/Confirmation' && _router.url !== '/Review-Page'">
                    <div class="collapse navbar-collapse m-t-5" fxFlex="100" id="NavBarSizeConfirmationPage">
                        <div class="header-container mx-w-60" id="Menu_Bar">
                            <ul class="navbar-nav ms-auto me-auto mb-2 mb-lg-0" id="navbar-menu-desktop">
                                <li class="nav-item" >
                                    <a class="nav-link f-s-vw"
                                        (click)="ClickToPrint()"><span class="print-order">Print</span> |</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link print-order cursor-pointer f-s-vw"
                                        (click)="ClicktoOrderHistory()">View Order History</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!------------------------------    Logo for Desktop   ------------------------------------>
                <div class="container mx-width" fxFlex fxLayout="column" fxFlex="100">
                    <div class="logoCenter"  [ngClass]="{'margin-top-logo': (_router.url !== '/Confirmation' && _router.url !== '/Review-Page' && !_router.url.includes('/Ultlifestyle')), 'margin-top-logo-Ultlifestyle-page': (_router.url.includes('/Ultlifestyle'))}">
                        <a class="navbar-brand" routerLink="/homepage" (click)="_sharedMethodService.closeCart()">
                            <img src="../../../assets/images/logos/Purium Side Logo and Transformation Company Desktop.png" alt="" id="head_logo"
                                class="head_logoGreenLeaf" width="100" (click)="OpenCartForShareLink()">
                        </a>
                    </div>
                </div>
            </div>
            <!------------------------------    SideBarMenu,Cart and Navbar toggle for mobile   ------------------------------------>
            <div class="mobile-search" style="display: contents;" *ngIf="_enrollSession.mobileViewMethod() && _router.url !== '/Confirmation' && _router.url !== '/Review-Page'">
                <div class="buttons" fxFlex fxLayout="row">
                    <div *ngIf="(!SidemenuBar && !_router.url.includes('/Ultlifestyle'))">
                        <button class="navbar-toggler" type="button" (click)="toggleSidenav()">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <div *ngIf="SidemenuBar" class="closemenuButton"><mat-icon
                            (click)="toggleSidenav()">clear</mat-icon></div>


                    <!------------------------------    Search button for mobile   ------------------------------------>
                    <div  *ngIf="(_enrollSession.mobileViewMethod() && !_router.url.includes('/Ultlifestyle'))">
                        <button class="color-white b-n" type="button"
                            (click)="navSearchContent()">
                            <img src="../../../assets/images/home_img/search-interface-symbol.png" alt="" class="w-20">
                        </button>
                    </div>
                    <div class="m-l-auto" *ngIf="showCart || (_sharedMethodService.shareLinkCart())">
                        <button class="cartButton" type="button" (click)="_sharedMethodService.openMobileSummaryCart()">
                            <img src="assets/icons/add-to-cart.png" alt="" class="w-30">
                            <sup class="f-w-600 c-green" *ngIf="getQuanity() > 0" [textContent]="getQuanity()"></sup>
                        </button>
                    </div>
                    <div class="btn-group" [ngClass]="{'m-l-auto': _router.url.includes('/Ultlifestyle')}" style="display: flex;
                    flex-direction: row;" *ngIf="_enrollSession.mobileViewMethod() && _auth.isLoggedIn()">

                        <div id="dropdownMenuButton" style="display: flex;border-radius: 100px;
                        flex-direction: column;">
                            <button type="button" class="accountMobile" *ngIf="!SideAccountBar"
                                (click)="toggleAccountSideBar()">
                                Account
                            </button>
                            <div *ngIf="SideAccountBar" class="closemenuButton"><mat-icon
                                    (click)="toggleAccountSideBar()">clear</mat-icon></div>
                        </div>
                    </div>
                    <div *ngIf="!_auth.isLoggedIn() && _router.url != '/Shipping' && _router.url != '/Checkout'  && _router.url != '/Subscribe'" [ngClass]="{'margin-unset': !_router.url.includes('/Ultlifestyle'), 'margin-inherit': _router.url.includes('/Ultlifestyle')}">
                        <button class="navbar-toggler b-n btn btn-secondary dropdown-toggle loginButton"
                            type="button" (click)="login()">
                            <img src="../../../assets/icons/icon-profile.png" alt="" class="profileiconformobile">
                        </button>
                    </div>

                </div>
            </div>


            <!------------------------------    Print and Order History on Confirmation Page for Mobile  ------------------------------------>
            <div class="mobile-search" id="printButtonNone" *ngIf="_enrollSession.mobileViewMethod() && _router.url === '/Confirmation'"
                id="Print-OrderMobile">
                <div fxFlex fxLayout="row">
                    <a class="b-n m-l-auto color-green" type="button" (click)="ClickToPrint()">
                        <span class="print-order">Print</span> <span class="f-x-small">&nbsp;|&nbsp;</span>
                    </a>
                    <a class="b-n color-green" type="button" (click)="ClicktoOrderHistory()">
                        <span class="print-order">View Order History</span>
                    </a>
                </div>
            </div>

            
            <!------------------------------    Logo for Mobile   ------------------------------------>
            <div class="header-container logo-mobile" *ngIf="_enrollSession.mobileViewMethod()">
                <div class="logoCenter margin-top-logo" [ngClass]="{'margin-top-logo-confirmation-page': (_router.url === '/Confirmation')}">
                    <a class="navbar-brand" routerLink="/Home" (click)="_sharedMethodService.closeCart()">
                        <img src="../../../assets/images/logos/logo new.png"
                            id="head_logo" alt="" class="head_logoCompany">
                    </a>
                </div>
            </div>
            
            <!------------------------------    Summary Cart   ------------------------------------>
            <ng-container *ngIf="!_enrollSession.mobileViewMethod()">
                <mat-sidenav *ngIf="_router.url !== '/Confirmation' && _router.url !== '/Review-Page' && !_router.url.includes('/Ultlifestyle')" #sideNav [mode]="mode.value || 'over'" closed>
                    <summary-cart [routPath]="routPath" [customerInfoForm]="customerInfoForm"
                        [accountInfoForm]="accountInfoForm" [shippingForm]="shippingForm"
                        [billingAddressForm]="billingAddressForm" [shippingMethodForm]="shippingMethodForm"
                        [referredForm]="referredForm" [paymentTypeForm]="paymentTypeForm"></summary-cart>
                </mat-sidenav>
            </ng-container>
            

            <!------------------------------    Summary Cart button  ------------------------------------>
            <ng-container *ngIf="_enrollSession.mobileViewMethod()">
                <summary-cart-button *ngIf="_router.url !== '/Confirmation' && _router.url !== '/Review-Page' && _router.url !== '/Ultlifestyle'" [routPath]="routPath" [customerInfoForm]="customerInfoForm"
                [accountInfoForm]="accountInfoForm" [shippingForm]="shippingForm"
                [billingAddressForm]="billingAddressForm" [shippingMethodForm]="shippingMethodForm"
                [referredForm]="referredForm" [paymentTypeForm]="paymentTypeForm"></summary-cart-button>
            </ng-container>
        </div>

        <!-- -------------------------    Search Side Bar for Mobile  ----------------------------->

        <ng-container *ngIf="_enrollSession.mobileViewMethod()">
            <mat-sidenav class="menuSide" [mode]="mode.value || 'over'" *ngIf="navInputSearch && _router.url !== '/Confirmation' && _router.url !== '/Review-Page'" closed>
                <div class="closemenuButtonSearch" (click)="navSearchContent()"><mat-icon>clear</mat-icon></div>
                <div *ngIf="_enrollSession.mobileViewMethod()">
                    <div class="mobile-search" (click)="searchIconHandle()">
                        <form class="js-bold tooltip_header tooltipforMobile">
                            <input [matAutocomplete]="auto" type="search" name="searchedValue"
                                placeholder="Search"
                                ng-class="{ openSearchBox: _sharedMethodService.headerSearchValue != ''}"
                                id="open_input" class="search__input_field js-bold" (input)="onSearchChange($event)"
                                [(ngModel)]="searchedValue" (keydown.enter)="selectProdcut(searchedValue)">
                                <button class="btn magnifyingglassMobile" type="submit"
                                (click)="redirectToProduct(_sharedMethodService.headerSearchValue);">
                                <img src="../../../assets/images/home_img/search-interface-symbol.png" alt="" class="w-20">
                            </button>
                            <mat-autocomplete #auto="matAutocomplete" class="bg-white search-border-box"
                                (optionSelected)="redirectToProduct($event)">
                                <div *ngIf="_sharedMethodService.headerSearchValue?.length > 0">
                                    <mat-option
                                        *ngFor="let product of productList | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2,LongDetail1,LongDetail2': getHeaderSearchedValue()"
                                        [value]="product.Description" (click)="redirectProduct(product)">
                                        {{product.Description}}
                                    </mat-option>
                                </div>
                            </mat-autocomplete>
        
                        </form>
                    </div>
                </div>
            </mat-sidenav>
        </ng-container>


        <!-- -------------------------    Account Side Bar for Mobile  ----------------------------->

        <mat-sidenav class="menuSide" [mode]="mode.value || 'over'" *ngIf="SideAccountBar && _router.url !== '/Confirmation' && _router.url !== '/Review-Page'" closed>
            <div class="">
                <ul class="navbar-nav ms-auto me-auto mb-2 mb-lg-0" id="nav">
                    <li class="nav-item sidebarFlex" (click)="isshowAccount = !isshowAccount">
                        <a id="btn" class="nav-link sideBarColor m-l5">Account</a><mat-icon
                            class="keyboard_arrow_right_menuBar" *ngIf="!isshowAccount" id="arrowIcon">keyboard_arrow_right</mat-icon>
                            <mat-icon
                            class="keyboard_arrow_right_menuBar" *ngIf="isshowAccount">keyboard_arrow_down</mat-icon>
                    </li>
                    <div *ngIf="isshowAccount">
                        <div class="menu-bar-divider-account"></div>
                    <li class="nav-item sidebarFlex" (click)="ClicktoOrderHistory()">
                        <a id="btn" class="nav-link sideBarColor m-l5" >Order
                            History</a>
                    </li>
                    <div class="menu-bar-divider-account"></div>
                    <li class="nav-item sidebarFlex" (click)="ClicktoSubscription()">
                        <a id="btn" class="nav-link sideBarColor m-l5" >Subscription</a>
                    </li>
                    <div class="menu-bar-divider-account" *ngIf="isUpgradableCustomer()"></div>
                    <li class="nav-item sidebarFlex upgradeTxt" *ngIf="isUpgradableCustomer()" (click)="ClicktoUpgrade()">
                        <a id="btn" class="nav-link sideBarColor m-l5" >Become a Brand Partner</a>
                    </li>
                    <div class="menu-bar-divider-account"></div>
                    <li id="btn" class="nav-item sidebarFlex" (click)="isshowreward = !isshowreward;">
                        <a class="nav-link sideBarColor m-l5">Rewards Points</a><mat-icon
                            class="keyboard_arrow_right_menuBar"
                            *ngIf="!isshowreward">keyboard_arrow_right</mat-icon><mat-icon
                            class="keyboard_arrow_right_menuBar" *ngIf="isshowreward">keyboard_arrow_down</mat-icon>
                    </li>
                    <li class="nav-item" *ngIf="isshowreward">
                        <a *ngIf="isshowreward" class="nav-link reward-points">
                            <b>{{_sharedMethodService.balanceField | number : '1.0-0'}} Rewards Points Available</b>
                        </a>
                        <a *ngIf="isshowreward" class="nav-link color-green margin-text" target="_blank" href="https://backoffice.mypurium.com/puriumrewards">Learn More</a>
                    </li>
                    <div class="menu-bar-divider-account"></div>
                    <li class="nav-item sidebarFlex" (click)="ClicktoSettings()">
                        <a id="btn" class="nav-link sideBarColor m-l5" >Settings</a>
                    </li>
                    <div class="menu-bar-divider-account"></div>
                    <!-- <li class="nav-item sidebarFlex" *ngIf="isBPCustomerExist()" (click)="ClicktoBPBackOffice()">
                        <a id="btn" class="nav-link sideBarColor m-l5" >BP Back Office</a>
                    </li>
                    <div class="menu-bar-divider-account" *ngIf="isBPCustomerExist()"></div> -->
                    <li id="btn" class="nav-item sidebarFlex" (click)="logout();">
                        <a class="nav-link sideBarColor m-l5">Sign Out</a>
                    </li>
                    <div class="menu-bar-divider-account"></div>
                    </div>
                </ul>
            </div>
        </mat-sidenav>

        <!-- -------------------------    Menu Side Bar for Mobile   ------------------------------->

        <mat-sidenav class="menuSide" [mode]="mode.value || 'over'" *ngIf="SidemenuBar && _router.url !== '/Confirmation' && _router.url !== '/Review-Page'" closed>
            <ul class="navbar-nav ms-auto me-auto mb-2 mb-lg-0">
                <li class="nav-item sidebarFlex" *ngIf="!_router.url.includes('/homepage')" (click)="RedirectToHome();_sharedMethodService.closeCart();">
                    <a class="nav-link sideBarColor"
                        routerLink="/Home">Home</a>
                </li>
                <li class="nav-item">
                    <div class="menu-bar-divider" *ngIf="!_router.url.includes('/homepage')"></div>
                    <div class="sidebarFlex" (click)="RedirectToUltLifestyle();_sharedMethodService.closeCart()">
                        <a class="nav-link cursor-pointer sideBarColor"
                            >Lifestyle
                            Subscription</a>
                    </div>
                </li>
                <li class="nav-item customdropdown" *ngIf="hideListForUltlifestyle">
                    <div class="menu-bar-divider" *ngIf="hideListForUltlifestyle"></div>
                    <ng-container *ngFor="let menuOption of _commonService.DropdownMenu">
                        <div class="sidebarFlex" (click)="_commonService.ClickToWebCategory(menuOption.WebCategoryID)">
                            <a class="cursor-pointer nav-link cursor-pointer sideBarColor"
                                *ngIf="menuOption.WebCategoryID != '544';"
                                >{{menuOption.WebCategoryDescription}}</a>
                        </div>
                        <div class="menu-bar-divider" *ngIf="hideListForUltlifestyle && (menuOption.WebCategoryID != 544);"></div>
                    </ng-container>
                </li>
                <li class="nav-item">
                    <div class="sidebarFlex" (click)="redirectToTodaysOrder()">
                        <a class="nav-link cursor-pointer sideBarColor"
                            *ngIf="hideListForUltlifestyle">
                            All Products
                        </a>
                    </div>
                </li>
                <li class="nav-item customdropdown" *ngIf="hideListForUltlifestyle">
                    <ng-container *ngFor="let menuOption of _commonService.DropdownMenu">
                        <div class="menu-bar-divider" *ngIf="hideListForUltlifestyle && (menuOption.WebCategoryID == 544) && isBPCustomerExist()"></div>
                        <div class="sidebarFlex" (click)="_commonService.ClickToWebCategory(menuOption.WebCategoryID)">
                            <a class="cursor-pointer nav-link cursor-pointer sideBarColor" *ngIf="isBPCustomerExist() && menuOption.WebCategoryID == '544';"
                            >{{menuOption.WebCategoryDescription}}</a>
                        </div>
                    </ng-container>
                </li>
            </ul>

        </mat-sidenav>

        <!-- -------------------------    Customer Image Banner for Mobile   ------------------------------->
        <ng-container *ngIf="_router.url.includes('homepage') && _enrollSession.mobileViewMethod()">
            <div [ngClass]="{'header-padding':_sharedMethodService.isOpen,'header-padding-full':!_sharedMethodService.isOpen}">
                <div fxFlex="100" class="mt-10 wp-block-heading has-text-align-center"
                    id="welcome-banner">
                    <div fxFlex="79" class="m-auto">
                        <span class="welcome-text" *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">Hi {{_enrollSession.data.LoggedInUserData?.FirstName ||
                            userData?.FirstName || _enrollSession.data.LoggedInUserData?.Company}},</span>
                            <br *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">
                            <br *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">
                        <span *ngIf="!_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()" class="p-15">Welcome to Purium The Superfoods Superstore!</span>
                        <span *ngIf="_auth.isLoggedIn() && !isBPCustomerExist()" class="welcome-text">Welcome to {{getEnrollerDetails()}}'s Superfoods Store</span>
                        <span *ngIf="_auth.isLoggedIn() && isBPCustomerExist()" class="welcome-text">Welcome to YOUR Superfoods Store</span>
                        <span *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod() && ((!_sharedMethodService.shareLinkCart()) || (getQuanity() == 0))" class="welcome-text">Welcome to {{(_enrollSession.data.RetailEnroll?.webAlias)}}'s Superfoods Store</span>
                        <span *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod() && (_sharedMethodService.shareLinkCart()) && (getQuanity() > 0)" class="welcome-text">Welcome! {{(_enrollSession.data.RetailEnroll?.webAlias)}} has created a cart just for you. <br *ngIf="_sharedMethodService.isOpen"><span class="click-here-underline" (click)="openCart()">Click Here.</span></span>
                    </div>
                    <span fxFlex="21" *ngIf="_auth.isLoggedIn()" id="userImagespan"><img src="{{_enrollSession.customerImage || '../../../assets/images/avatars/profile_icon.png'}}" onError="this.src='../../../assets/images/avatars/profile_icon.png';" class="userImage"></span>
                    <span fxFlex="21" *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod()" id="userImagespan"><img src="{{_enrollSession.customerImage || '../../../assets/images/avatars/profile_icon.png'}}" onError="this.src='../../../assets/images/avatars/profile_icon.png';" class="userImage"></span>
                </div>
            </div><br>
        </ng-container>
        <ng-container *ngIf="_router.url.includes('Ultlifestyle') && _enrollSession.mobileViewMethod()">
            <div class="header-padding-full">
                <div fxFlex="100" class="mt-10 wp-block-heading has-text-align-center"
                    id="welcome-banner">
                    <div fxFlex="79" class="m-auto">
                        <span class="welcome-text" *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">Hi {{_enrollSession.data.LoggedInUserData?.FirstName ||
                            userData?.FirstName || _enrollSession.data.LoggedInUserData?.Company}},</span>
                            <br *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">
                            <br *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">
                        <span *ngIf="!_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()" class="p-15">Welcome to The Lifestyle Subscription Page</span>
                        <span *ngIf="_auth.isLoggedIn() && !isBPCustomerExist()" class="welcome-text">Welcome to {{getEnrollerDetails()}}'s Lifestyle Subscription Page.</span>
                        <span *ngIf="_auth.isLoggedIn() && isBPCustomerExist()" class="welcome-text">Welcome to YOUR Lifestyle Subscription Page</span>
                        <span *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod() && ((!_sharedMethodService.shareLinkCart()))" class="welcome-text">Welcome to {{(_enrollSession.data.RetailEnroll?.webAlias)}}'s Lifestyle Subscription Page.</span>
                        <span *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod() && (_sharedMethodService.shareLinkCart())" class="welcome-text">Welcome! {{(_enrollSession.data.RetailEnroll?.webAlias)}}'s Lifestyle Subscription Page.</span>
                    </div>
                    <span fxFlex="21" *ngIf="_auth.isLoggedIn()" id="userImagespan"><img src="{{_enrollSession.customerImage || '../../../assets/images/avatars/profile_icon.png'}}" onError="this.src='../../../assets/images/avatars/profile_icon.png';" class="userImage"></span>
                    <span fxFlex="21" *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod()" id="userImagespan"><img src="{{_enrollSession.customerImage || '../../../assets/images/avatars/profile_icon.png'}}" onError="this.src='../../../assets/images/avatars/profile_icon.png';" class="userImage"></span>
                </div>
            </div><br>
        </ng-container>

        <!------------------------------    Summary Cart   ------------------------------------>
        <div class="container-fluid" *ngIf="_enrollSession.mobileViewMethod()">
            <mat-sidenav *ngIf="_router.url !== '/Confirmation' && _router.url !== '/Review-Page' && !_router.url.includes('Ultlifestyle')" #sideNav [mode]="mode.value || 'over'" closed>
                <summary-cart [routPath]="routPath" [customerInfoForm]="customerInfoForm"
                    [accountInfoForm]="accountInfoForm" [shippingForm]="shippingForm"
                    [billingAddressForm]="billingAddressForm" [shippingMethodForm]="shippingMethodForm"
                    [referredForm]="referredForm" [paymentTypeForm]="paymentTypeForm"></summary-cart>
            </mat-sidenav>
        </div>
    </nav>

    <!-- -------------------------    Customer Image Banner for Desktop   ------------------------------->

    <ng-container *ngIf="(_router.url.includes('homepage')) && !_enrollSession.mobileViewMethod()">
        <div class="row" [ngClass]="{'header-padding':_sharedMethodService.isOpen,'header-padding-full':!_sharedMethodService.isOpen}">
            <div fxFlex="100" class="mt-10 wp-block-heading has-text-align-center"
                id="welcome-banner" [ngClass]="{'directionFlexForPreLink': !_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod() && (_sharedMethodService.shareLinkCart()) && (getQuanity() > 0)}">
                <div fxFlex="79"  class="m-auto" [ngClass]="{'p-15':!_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod() && (!_sharedMethodService.shareLinkCart()),'margin-15':!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod() && (_sharedMethodService.shareLinkCart() && (getQuanity() > 0))}">
                    <span class="welcome-text" *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">Hi {{_enrollSession.data.LoggedInUserData?.FirstName ||
                        userData?.FirstName || _enrollSession.data.LoggedInUserData?.Company}},</span>
                        <br *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">
                        <br *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">
                    <span *ngIf="!_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()" class="p-15">Welcome to Purium The Superfoods Superstore!</span>
                    <span *ngIf="_auth.isLoggedIn() && !isBPCustomerExist()" class="welcome-text">Welcome to {{getEnrollerDetails()}}'s Superfoods Store</span>
                    <span *ngIf="_auth.isLoggedIn() && isBPCustomerExist()" class="welcome-text">Welcome to YOUR Superfoods Store</span>
                    <span *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod() && ((!_sharedMethodService.shareLinkCart()) || (getQuanity() == 0))" class="welcome-text">Welcome to {{(_enrollSession.data.RetailEnroll?.webAlias)}}'s Superfoods Store</span>
                    <span *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod() && (_sharedMethodService.shareLinkCart()) && (getQuanity() > 0)" [ngClass]="{'m-auto': _sharedMethodService.isOpen}">Welcome! {{(_enrollSession.data?.RetailEnroll?.webAlias)}} has created a cart just for you. <br *ngIf="_sharedMethodService.isOpen"><span class="click-here-underline" (click)="openCart()">Click Here.</span></span>
                </div>
                <span fxFlex="21" *ngIf="_auth.isLoggedIn()" id="userImagespan"><img src="{{_enrollSession.customerImage || '../../../assets/images/avatars/profile_icon.png'}}" onError="this.src='../../../assets/images/avatars/profile_icon.png';" class="userImage"></span>
                <span fxFlex="21" *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod()" id="userImagespan"><img src="{{_enrollSession.customerImage || '../../../assets/images/avatars/profile_icon.png'}}" onError="this.src='../../../assets/images/avatars/profile_icon.png';" class="userImage"></span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="(_router.url.includes('Ultlifestyle')) && !_enrollSession.mobileViewMethod()">
        <div class="row" [ngClass]="{'header-padding':_sharedMethodService.isOpen,'header-padding-full':!_sharedMethodService.isOpen}">
            <div fxFlex="100" class="mt-10 wp-block-heading has-text-align-center"
                id="welcome-banner">
                <div fxFlex="79"  class="m-auto" [ngClass]="{'p-15':!_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()}">
                    <span class="welcome-text" *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">Hi {{_enrollSession.data.LoggedInUserData?.FirstName ||
                        userData?.FirstName || _enrollSession.data.LoggedInUserData?.Company}},</span>
                        <br *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">
                        <br *ngIf="(_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()) || (_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod())">
                    <span *ngIf="!_auth.isLoggedIn() && !_sharedMethodService.giftcardNameMethod()" class="p-15">Welcome to The Lifestyle Subscription Page</span>
                    <span *ngIf="_auth.isLoggedIn() && !isBPCustomerExist()" class="welcome-text">Welcome to {{getEnrollerDetails()}}'s Lifestyle Subscription Page</span>
                    <span *ngIf="_auth.isLoggedIn() && isBPCustomerExist()" class="welcome-text">Welcome to YOUR Lifestyle Subscription Page</span>
                    <span *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod()" class="welcome-text">Welcome to {{(_enrollSession.data.RetailEnroll?.webAlias)}}'s Lifestyle Subscription Page</span>
                </div>
                <span fxFlex="21" *ngIf="_auth.isLoggedIn()" id="userImagespan"><img src="{{_enrollSession.customerImage || '../../../assets/images/avatars/profile_icon.png'}}" onError="this.src='../../../assets/images/avatars/profile_icon.png';" class="userImage"></span>
                <span fxFlex="21" *ngIf="!_auth.isLoggedIn() && _sharedMethodService.giftcardNameMethod()" id="userImagespan"><img src="{{_enrollSession.customerImage || '../../../assets/images/avatars/profile_icon.png'}}" onError="this.src='../../../assets/images/avatars/profile_icon.png';" class="userImage"></span>
            </div>
        </div>
    </ng-container>
</div>


<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css">