
<div class="homePage">
    <!-- ======================= PAGE CONTENT ====================================== -->
    <app-header></app-header>
<!-- Hero Carousel -->
<div style="padding:56.25% 0 0 0;position:relative;margin-top: 175px;"><iframe
  src="https://player.vimeo.com/video/847755474?badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0"
  allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;"
  title="Exposing the Fake Food Conspiracy"></iframe></div>
<script src="https://player.vimeo.com/api/player.js"></script>

  <!-- Plastic Saved -->
  <div class="text-center p-3">
    <p class="mb-0 bannerBottomText">
      <span class="fs-30">
        {{stats || '------'}}
      </span>
      <br>
      Pounds of Plastic Saved & Replaced by Compostable Packaging
    </p>
  </div>

  <!-- Shop 2 cols -->
  <div class="container mt-5">
    <div class="row mt-3 justify-content-center">
      <div class="col-12 col-md-5 text-center p-md-5">
        <img class="img-fluid" src="assets/homepage-assets/shop-ult.png" alt="Shop ULT" style="margin-bottom: 10px;">
        <a (click)="redirectToUltlifestyle()" class="mnsrt-md text-decoration-none cursor-pointer">
          <h5 class="mnsrt-md p-12" style="background-color:#3A5838;">Shop Ultimate<br>Lifestyle Transformations</h5>
        </a>
      </div>
      <div class="col-12 col-md-5 text-center p-md-5">
        <img class="img-fluid" src="assets/homepage-assets/shop-all.png" alt="Shop All" style="margin-bottom: 10px;">
        <a (click)="redirectToProduct()" class="mnsrt-md text-decoration-none cursor-pointer">
          <h5 class="mnsrt-md p-12" style="background-color:#45589e;">Shop<br>All Products</h5>
        </a>
      </div>
    </div>
  </div>

  <!-- Badges Section -->
  <div class="container text-center mt-5">
    <h1 class="lora-bold" style="color:#3a5838">Pure + Premium = Purium</h1>
    <div class="row mt-5">
      <div class="col-4 col-md-2 d-flex flex-column p-3">
        <img class="img-fluid align-self-center" src="assets/icons/badge-organic.png" style="height:70px;" alt=" USDA Organic">
        <p class="mt-auto mb-0 grayColor">Organic</p>
      </div>
      <div class="col-4 col-md-2 d-flex flex-column p-3">
        <img class="img-fluid align-self-center" src="assets/icons/badge-plasticfree.png" alt="Plastic Free">
        <p class="mt-auto mb-0 grayColor">Plastic-Free</p>
      </div>
      <div class="col-4 col-md-2 d-flex flex-column p-3">
        <img class="img-fluid align-self-center" src="assets/icons/badge-vegan.png" alt="Vegan">
        <p class="mt-auto mb-0 grayColor">Vegan</p>
      </div>
      <div class="col-4 col-md-2 d-flex flex-column p-3">
        <img class="img-fluid align-self-center" src="assets/icons/badge-nongmo.png" style="height:63px;" alt="Non GMO">
        <p class="mt-auto mb-0 grayColor">Non-GMO</p>
      </div>
      <div class="col-4 col-md-2 d-flex flex-column p-3">
        <img class="img-fluid align-self-center" src="assets/icons/badge-glutenfree.png" alt="Gluten Free">
        <p class="mt-auto mb-0 grayColor">Gluten-Free</p>
      </div>
      <div class="col-4 col-md-2 d-flex flex-column p-3">
        <img class="img-fluid align-self-center" src="assets/icons/badge-farming.png" alt="Regenerative Farming Practices">
        <p class="mt-auto mb-0 grayColor">Regenerative Farming Practices</p>
      </div>
    </div>
  </div>

  <!-- 10 points commitment -->
<div class="pt-5 pb-5 mt-5 bg-image" style="
  background-image: url('assets/backgrounds/10points.png');
  max-width:100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: white">
  
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h3 class="mb-5 mt-3 text-center lora-bold fs-30">10 point Quality Commitment</h3>

          <div class="mb-5" style="width: fit-content; margin:auto;">
            <div class="d-flex">
              <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
              <p class="d-inline-block pointText" style="margin-left: 30px;">Certified Organic Farmers</p>
            </div>
            <div class="d-flex">
              <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
              <p class="d-inline-block pointText" style="margin-left: 30px;">Regenerative Farming Practices</p>
            </div>
            <div class="d-flex">
              <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
              <p class="d-inline-block pointText" style="margin-left: 30px;">Synergistically Combined Formulas</p>
            </div>
            <div class="d-flex">
              <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
              <p class="d-inline-block pointText" style="margin-left: 30px;">On-site Microbiology Department</p>
            </div>
            <div class="d-flex">
              <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
              <p class="d-inline-block pointText" style="margin-left: 30px;">Ingredient Identification Testing</p>
            </div>
            <div class="d-flex">
              <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
              <p class="d-inline-block pointText" style="margin-left: 30px;">Allergen Program / Gluten Testing</p>
            </div>
            <div class="d-flex">
              <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
              <p class="d-inline-block pointText" style="margin-left: 30px;">OK Certified Kosher</p>
            </div>
            <div class="d-flex">
              <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
              <p class="d-inline-block pointText" style="margin-left: 30px;">QAI Certified Organic</p>
            </div>
            <div class="d-flex">
              <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
              <p class="d-inline-block pointText" style="margin-left: 30px;">GMP FDA Registered Facility</p>
            </div>
            <div class="d-flex">
              <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
              <p class="d-inline-block pointText" style="margin-left: 30px;">60-Day Money Back Guarante</p>
            </div>
          </div>
        
      </div>
      <div class="col-12 col-md-6">
        <h3 class="mb-5 mt-3 text-center lora-bold fs-30" style="padding: 0 10px;">10 point Purity Commitment</h3>

        <div class="mb-5" style="width: fit-content; margin:auto;">
          <div class="d-flex">
            <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
            <p class="d-inline-block pointText" style="margin-left: 30px;">No Artificial Colors</p>
          </div>
          <div class="d-flex">
            <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
            <p class="d-inline-block pointText" style="margin-left: 30px;">No Artificial Flavors</p>
          </div>
          <div class="d-flex">
            <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
            <p class="d-inline-block pointText" style="margin-left: 30px;">No Artificial Sweeteners</p>
          </div>
          <div class="d-flex">
            <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
            <p class="d-inline-block pointText" style="margin-left: 30px;">No Synthetic Vitamins</p>
          </div>
          <div class="d-flex">
            <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
            <p class="d-inline-block pointText" style="margin-left: 30px;">No Binders</p>
          </div>
          <div class="d-flex">
            <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
            <p class="d-inline-block pointText" style="margin-left: 30px;">No Fillers</p>
          </div>
          <div class="d-flex">
            <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
            <p class="d-inline-block pointText" style="margin-left: 30px;">No GMO's</p>
          </div>
          <div class="d-flex">
            <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
            <p class="d-inline-block pointText" style="margin-left: 30px;">No Pesticides</p>
          </div>
          <div class="d-flex">
            <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
            <p class="d-inline-block pointText" style="margin-left: 30px;">No Herbicides</p>
          </div>
          <div class="d-flex">
            <img class="d-inline-block align-self-start" src="assets/icons/Checkmark-white.png" alt="Checkmark">
            <p class="d-inline-block pointText" style="margin-left: 30px;">Plastic Free Packaging</p>
          </div>
        </div>

      </div>
    </div>
  </div>



</div>


<!-- Easily Create Healthy Habits -->
<div class="container text-center pt-5">
  <h1 class="m-3 pt-5 lora-bold" style="color:#3A5838; font-size: 46px;">Easily Create Healthy Habits</h1>
  <h3 class="m-3 js-reg" style="color: #1a1a1a;font-size: 34px;">Lifestyle Subscription</h3>
  <p style="font-size: 24px; line-height: 30px;">Purium’s proven lifestyle transformation programs have been the blueprint for permanent, lasting change for hundreds of thousands of customers. For each of them, it began with a commitment. A commitment made to themselves that they were ready to create a better life. At Purium, we are confident we have a program that’s right for you. Each program is built around four core nutrition products , is simple to follow and makes it easy to achieve your health, fitness and aesthetic goals. Be proud of your commitment, stay consistent and be transformed!</p>
  <img class="img-fluid mt-5" src="assets/homepage-assets/blender.png" alt="">
</div>


<!-- Phases of Transformation -->
<div class="container mt-5 pt-5 mb-5 text-center">
  <h1 class="text-center lora-bold" style="color:#12564b;">Phases of Transformation</h1>

  <div id="carouselExampleIndicators1" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleIndicators1" style="background-color: #ccc;" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators1" style="background-color: #ccc;" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators1" style="background-color: #ccc;" data-bs-slide-to="2" aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators1" style="background-color: #ccc;" data-bs-slide-to="3" aria-label="Slide 4"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="assets/homepage-assets/month-1.png" class="d-block w-100 p-md-5" style="max-width:800px; margin:auto;" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/homepage-assets/month-2.png" class="d-block w-100 p-md-5" style="max-width:800px; margin:auto;" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/homepage-assets/month-3.png" class="d-block w-100 p-md-5" style="max-width:800px; margin:auto;" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/homepage-assets/month-4.png" class="d-block w-100 p-md-5" style="max-width:800px; margin:auto;" alt="...">
      </div>
    </div>
  </div>
  <a href="https://ultlifestyle.com" class="btn mt-5 p-3" style="background-color:#617960; color:white; width:300px; border-radius:0; margin: auto;">Subscribe Today</a>
</div>


<!-- Habits Transformed -->
<div class="container mt-5 pt-5 mb-5">
  <h1 class="text-center lora-bold" style="color:#12564b;">Habits Transformed</h1>

  <div id="carouselExampleIndicators3" class="carousel slide p-md-5" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleIndicators3" style="background-color: #ccc;" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators3" style="background-color: #ccc;" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators3" style="background-color: #ccc;" data-bs-slide-to="2" aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators3" style="background-color: #ccc;" data-bs-slide-to="3" aria-label="Slide 4"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators3" style="background-color: #ccc;" data-bs-slide-to="4" aria-label="Slide 5"></button>
    </div>

    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="assets/homepage-assets/habits-1.png" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/homepage-assets/habits-2.png" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/homepage-assets/habits-3.png" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/homepage-assets/habits-4.png" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/homepage-assets/habits-5.png" class="d-block w-100" alt="...">
      </div>
    </div>
  </div>

</div>


<!-- Transform into your best life -->
<div class="" style="background-color: #7F927D; color:white;">
  <div class="container text-center pt-5 pb-5">
    <h1 class="lora-bold" style="font-size: 46px;">Transform into Your Best Life</h1>
    <p class="m-3" style="font-size: 24px; line-height: 30px;">The ULT floods your body with nutrient-rich superfoods without requiring harsh restrictions on your diet. By meeting your nutritional needs with high quality foods, cravings and habits that stem from undernourishment naturally lessen. Soon taste buds shift and stress eating comfort foods are not so comforting.</p>
    <div class="row justify-content-center d-flex flex-sm-row flex-column justify-content-center align-items-center" style="padding: 0 1rem">
      <!-- <div class="col col-xs-1"></div> -->
      <div class="col p-3 col-xs-6 col-sm-5 col-md-4 d-flex">
        <div class="align-self-center" style="margin:auto;">
          <h3>How will you transform?</h3>
          <hr style="height:3px; color: white; opacity: 1;">
          <div class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <h2>Weight Loss</h2>
              </div>
              <div class="carousel-item">
                <h2>Nutrition</h2>
              </div>
              <div class="carousel-item">
                <h2>Beautiful Aging</h2>
              </div>
              <div class="carousel-item">
                <h2>Fitness</h2>
              </div>
              <div class="carousel-item">
                <h2>Family</h2>
              </div>
              <div class="carousel-item">
                <h2>Performance</h2>
              </div>
              <div class="carousel-item">
                <h2>Immune Health</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-1"></div>
      <div class="col p-3 col-xs-6 col-sm-5 col-md-4 col-lg-3">
        <div class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner mt-0">
            <div class="carousel-item active">
              <img src="assets/homepage-assets/transform-1.png" class="d-block w-100" style="max-width: 340px;" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/homepage-assets/transform-2.png" class="d-block w-100" style="max-width: 340px;" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/homepage-assets/transform-3.png" class="d-block w-100" style="max-width: 340px;" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/homepage-assets/transform-4.png" class="d-block w-100" style="max-width: 340px;" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/homepage-assets/transform-5.png" class="d-block w-100" style="max-width: 340px;" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/homepage-assets/transform-6.png" class="d-block w-100" style="max-width: 340px;" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/homepage-assets/transform-7.png" class="d-block w-100" style="max-width: 340px;" alt="...">
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col col-xs-1"></div> -->
    </div>
    <a href="https://ultpack.com" class="btn mt-3" style="background-color: #E5E9E5; color:#7F927D; border-radius:3px; width:300px">Transform Today</a>
  </div>
</div>

<!-- Why Now? -->
<div class="container; mt-5 mb-5 p-2">
  <h1 class="text-center lora-bold" style="color:#7F927D;">Why Now?</h1>
  <div id="carouselExampleIndicators2" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleIndicators2" style="background-color: #ccc;" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators2" style="background-color: #ccc;" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators2" style="background-color: #ccc;" data-bs-slide-to="2" aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators2" style="background-color: #ccc;" data-bs-slide-to="3" aria-label="Slide 4"></button>
    </div>
    <div class="carousel-inner pb-5">
      <div class="carousel-item active">
        <img src="assets/homepage-assets/Whynow-1.png" class="d-block w-100" style="max-width:800px; margin:auto;" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/homepage-assets/whynow-2.png" class="d-block w-100" style="max-width:800px; margin:auto;" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/homepage-assets/whynow-3.png" class="d-block w-100" style="max-width:800px; margin:auto;" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/homepage-assets/whynow-4.png" class="d-block w-100" style="max-width:800px; margin:auto;" alt="...">
      </div>
    </div>
  </div>

  <!-- <hr style="height:5px; color:#617960; opacity:1; border-radius: 5px; margin: auto; max-width:1000px;"> -->
</div>

<!-- Cofounders -->
<div style="background-color:#EEF2FF">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 p-2" fxFlex.gt-md="50">
        <img fxFlex="100" src="assets/homepage-assets/AmyandDave.png" alt="" class="img-fluid" style="display:block; margin:auto;">
      </div>
      <div class="col-md-6 col-sm-12 p-5" style="background-color:#EEF2FF">
        <h1 class="lora-bold"  style="color:#6174B8;font-size: 50px;">Co-founders<br> with a <i>Passion</i></h1>
        <br>
        <p style="font-size: 20px;">Purposefully providing:</p>
        <ul style="font-size: 20px;">
          <li>Pure + Premium Products</li>
          <li>1% Highest Most Potent Food</li>
          <li>Proven Glyphosate Solution</li>
          <li>Plastic-free Packaging</li>
          <li>Most Efficient Food Delivery System</li>
        </ul>
        <a href="https://blog.puriumcorp.com/2021/02/08/meet-purium-co-founder-amy-venner/" class="btn mt-3" style="background-color: #7387D0; width:100%; color:white; max-width: 400px;">Learn more about Amy Venner</a>
        <a href="https://blog.puriumcorp.com/2021/02/08/meet-purium-co-founder-dave-sandoval/" class="btn mt-3" style="background-color: #7387D0; width:100%; color: white; max-width: 400px;">Learn more about Dave Sandoval</a>
      </div>
    </div>
  </div>
</div>

</div>
<div  class="footerContainer">
  <app-footercomponent></app-footercomponent>
</div>
