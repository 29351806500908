<ng-container *ngIf="!_enrollSession.SummaryCartMobOnly && currentPage()">
    <div class="order-summary-div-mobile order-summary-mobile" *ngIf="this._enrollSession.data.RetailOrderSummary && (this._enrollSession.data.RetailOrderSummary.Enrollpack?.length > 0 || this._enrollSession.data.RetailOrderSummary.InitialOrders?.length > 0)">
        <div [ngClass]="{'order-summary-continue-button-div': currentRouterPath != '/Shipping' &&  currentRouterPath != '/Checkout', 'order-summary-continue-button-div-center' : currentRouterPath == '/Shipping' ||  currentRouterPath == '/Checkout' }"
            *ngIf="currentRouterPath != '/Confirmation'">
            <a *ngIf="currentRouterPath != '/Subscribe' && currentRouterPath != '/Shipping' &&  currentRouterPath != '/Checkout' && currentRouterPath !== '/EnrollmentCheckout'"
                    data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                    class="button continue-button continue-button-mobile w-button"
                    (click)="onClickChange();_sharedMethodService.closeCart()">Continue</a>
            <a *ngIf="currentRouterPath === '/Subscribe'"
                    data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                    class="button continue-button continue-button-mobile w-button"
                    (click)="subscribeDialog();_sharedMethodService.closeCart()">Continue</a>

            <a *ngIf="currentRouterPath == '/Shipping'" data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                class="button continue-button continue-button-mobile w-button" (click)="onClickChange();">Continue
                To
                Payment</a>
            <a *ngIf="currentRouterPath == '/Checkout'" data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                class="button continue-button continue-button-mobile w-button" [class.disabled]="_sharedMethodService.submissionInProgress ? true: false"
                (click)="onClickChange(true); ">Review your Order</a>
        </div>
    </div>
</ng-container>