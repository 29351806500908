<div class="page-wrapper">
  <div class="ult-checkout-section wf-section">
      <app-header [customerInfoForm]="customerInfoForm" [shippingForm]="shippingForm"
                  [shippingMethodForm]="shippingMethodForm" [accountInfoForm]="accountInfoForm" [referredForm]="referredForm"></app-header>
    <app-stepper *ngIf="_enrollSession.mobileViewMethod()"></app-stepper>
    <div class="collection-padding-shipping" id="shipping-page">
      <app-stepper *ngIf="!_enrollSession.mobileViewMethod()"></app-stepper>
        <div class="m-t-30 d-flexCustom" *ngIf="!authService.isLoggedIn()">
            <span class="checkout-h1 payment-h1 mr-50">Referral Code</span>
            <span class="mr-50 ReferredBy" *ngIf="_enrollSession.data.RetailEnroll.webAlias">Referred By: {{_enrollSession.data.RetailEnroll.webAlias}}</span>
            <span class="referralText" *ngIf="_enrollSession.data.RetailEnroll.webAlias">Did someone else refer you? Please enter the correct Referral code below!</span>
        </div>
        <div class="referred-by-form-block w-form" *ngIf="!authService.isLoggedIn()">
            <form [formGroup]="referredForm" id="email-form-3" name="email-form-3" class="referred-by-form">
                <input type="text" class="referred-by-text-form-field w-input" maxlength="256" name="referralCode" formControlName="referralCode" [(ngModel)]="_enrollSession.data.RetailOrderSummary.referredFormData.referralCode"
                       placeholder="Enter your referral code*" id="Referral-Code" (focusout)="_enrollSession.data.RetailOrderSummary.referredFormData.referralCode?getCustomerSite(_enrollSession.data.RetailOrderSummary.referredFormData.referralCode,1):''">
                <span class="help-block" *ngIf="!referredForm.get('referralCode').valid&&referredForm.get('referralCode').touched">
                    <span *ngIf="referredForm.get('referralCode').errors['required']">This field is required</span>
                </span>
            </form>
        </div>
      <h1 class="checkout-h1 shipping-h1">Customer Information / Shipping Address</h1>
      <div class="w-form">
        <form [formGroup]="customerInfoForm" id="email-form1" name="email-form" class="customer-info-form custom">
          <div fxLayout="column">
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
              <div fxLayout="column" fxFlex="49" *ngIf="!IsCompanyName">
                <div>
                  <input type="text" class="form-field w-input" maxlength="30" name="First-Name"
                    formControlName="FirstName" placeholder="First Name*" id="First-Name" required [readonly]="authtoken? true :false">
                </div>
                <span class="help-block"
                  *ngIf="!customerInfoForm.get('FirstName')?.valid&&customerInfoForm.get('FirstName')?.touched">
                  <span *ngIf="customerInfoForm.get('FirstName').errors['required']">This field is required</span>
                </span>
              </div>
              <div fxLayout="column" fxFlex="49" *ngIf="!IsCompanyName">
                <div>
                  <input type="text" class="form-field  w-input" maxlength="30" name="Last-Name"
                    formControlName="LastName" placeholder="Last Name*" id="Last-Name-2" required [readonly]="authtoken? true :false">
                </div>
                <span class="help-block"
                  *ngIf="!customerInfoForm.get('LastName')?.valid&&customerInfoForm.get('LastName')?.touched">
                  <span *ngIf="customerInfoForm.get('LastName').errors['required']">This field is required</span>
                </span>
              </div>
              <div fxLayout="column" fxFlex="100" *ngIf="IsCompanyName">
                <div>
                  <input type="text" class="form-field w-input" maxlength="30" name="Company-Name"
                    formControlName="CompanyName" placeholder="Company Name*" id="First-Name" required [readonly]="authtoken? true :false">
                </div>
                <span class="help-block"
                  *ngIf="!customerInfoForm.get('CompanyName')?.valid&&customerInfoForm.get('CompanyName')?.touched">
                  <span *ngIf="customerInfoForm.get('CompanyName').errors['required']">This field is required</span>
                </span>
              </div>
            </div>
            <div fxLayout="row" fxFlex="100">
              <div fxLayout="column" fxFlex="100">
                <div>
                  <input type="text" class="form-field w-input" maxlength="50" name="Address-1"
                    formControlName="address1" placeholder="Address 1*" id="Address-1" required>
                </div>
                  <span class="help-block"
                    *ngIf="!customerInfoForm.get('address1').valid&&customerInfoForm.get('address1').touched">
                    <span *ngIf="customerInfoForm.get('address1').errors['required']">This field is required</span>
                  </span>
              </div>
            </div>
            <div fxLayout="row" fxFlex="100">
              <input type="text" class="form-field w-input" maxlength="50" name="address-2" formControlName="address2"
                placeholder="Address 2 (Optional)" id="address-3">
            </div>
            <div fxLayout="row" fxFlex="100">
              <div fxLayout="column" fxFlex="100">
                <div>
                  <input type="text" class="form-field w-input" maxlength="30" name="City" formControlName="City"
                    placeholder="City*" id="City-2" required>
                </div>
                  <span class="help-block p-l-10"
                    *ngIf="!customerInfoForm.get('City').valid&&customerInfoForm.get('City').touched">
                    <span *ngIf="customerInfoForm.get('City').errors['required']">This field is required</span>
                  </span>
              </div>
            </div>
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
              <div fxLayout="column" fxFlex="32">
                <div>
                  <select name="CountryCode" formControlName="CountryCode" class="form-field w-input"
                    [(ngModel)]="_enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode"
                    (change)="countryChange(_enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode,2)">
                    <option *ngFor="let country of countriesList" [value]='country.countryCodeField'>{{
                      country.countryNameField}} </option>
                  </select>
                </div>
                <div>
                </div>
              </div>
              <div fxLayout="column" fxFlex="32">
                <div>
                  <div>
                    <select name="State" id="" formControlName="State" class="form-field w-input"
                      [(ngModel)]="!_enrollSession.data.RetailOrderSummary.customerInfoData.State ? (customerDetail.MainState?customerDetail.MainState:'') : _enrollSession.data.RetailOrderSummary.customerInfoData.State"
                      (change)="stateChange(_enrollSession.data.RetailOrderSummary.customerInfoData.State,2)">
                      <option value="" disabled selected hidden>
                        {{customerDetail.MainState?customerDetail.MainState:(_enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode
                        =="US" ? "select state": "select province")}}</option>
                      <option *ngFor="let state of stateList" [ngValue]='state.regionCodeField'>
                        {{state.regionNameField}}</option>
                    </select>
                  </div>
                    <span class="help-block p-l-10"
                      *ngIf="!customerInfoForm.get('State').valid&&customerInfoForm.get('State').touched">
                      <span *ngIf="customerInfoForm.get('State').errors['required']">This field is required</span>
                    </span>
                </div>
              </div>
              <div fxLayout="column" fxFlex="32">
                <div>
                  <input type="text" class="form-field  w-input" maxlength="256" name="Zip-Code"
                    formControlName="ZipCode" placeholder="Zip Code*" id="Zip-Code-2" required
                    (keyup)="regexValidateZip1($event)"
                    (focusout)="(_enrollSession.data.RetailOrderSummary.customerInfoData.ZipCode?checkCalculateOrder():'');regexValidateZip1($event)">
                </div>
                  <span class="error-zip" *ngIf="invalidzip1">INVALID ZIP</span>
                  <span class="help-block"
                    *ngIf="!customerInfoForm.get('ZipCode').valid&&customerInfoForm.get('ZipCode').touched">
                    <span *ngIf="customerInfoForm.get('ZipCode').errors['required']">This field is required</span>
                  </span>
              </div>
            </div>

            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
              <div fxLayout="column" fxFlex="49">
                <div >
                  <input type="email" class="form-field w-input" maxlength="256" name="Email"
                    placeholder="Email Address*" id="Email-2" formControlName="Email" required [readonly]="(authtoken)? true :false"
                    (focusout)="_enrollSession.data.RetailOrderSummary.customerInfoData.Email?EmailRegistered(_enrollSession.data.RetailOrderSummary.customerInfoData.Email):''">
                </div>
                <span class="help-block"
                  *ngIf="!customerInfoForm.get('Email').valid&&customerInfoForm.get('Email').touched&&!authService.isLoggedIn()">
                  <span
                    *ngIf="!customerInfoForm.get('Email').errors['required'] && !customerInfoForm.get('Email').valid">E-mail
                    is invalid</span>
                  <span *ngIf="customerInfoForm.get('Email').errors['required']">This field is required</span>
                </span>
              </div>
              <div fxLayout="column" fxFlex="49">
                <div *ngIf="!authService.isLoggedIn()">
                  <input type="email" class="form-field w-input" maxlength="256" name="ConfirmEmail"
                    placeholder="ConfirmEmail Address*" id="ConfirmEmail-2" formControlName="ConfirmEmail" required
                    [readonly]="authtoken? true :false"
                    (focusout)="_enrollSession.data.RetailOrderSummary.customerInfoData.ConfirmEmail?EmailRegistered(_enrollSession.data.RetailOrderSummary.customerInfoData.ConfirmEmail):''">
                </div>
                <span class="help-block"
                  *ngIf="!customerInfoForm.get('ConfirmEmail').valid&&customerInfoForm.get('ConfirmEmail').touched">
                  <span *ngIf="customerInfoForm.get('ConfirmEmail').errors['EmailMismatch']">E-mail do not
                    Match</span>
                  <span *ngIf="customerInfoForm.get('ConfirmEmail').errors['required']">This field is required</span>
                </span>
              </div>
            </div>
            <input type="text" class="form-field w-input" maxlength="256" name="Phone" placeholder="Phone Number*"
              id="Phone" formControlName="Phone" required>
            <span class="help-block"
              *ngIf="!customerInfoForm.get('Phone').valid&&customerInfoForm.get('Phone').touched">
              <span *ngIf="customerInfoForm.get('Phone').errors['required']">This field is required</span>
              <span
                *ngIf="!customerInfoForm.get('Phone').valid && !customerInfoForm.get('Phone').errors['required']">Phone
                Number is invalid</span>
            </span>
            <label *ngIf="!authService.isLoggedIn()">
              <span class="labelFontWeight checkbox-label w-form-label m-t-10"><b>Keep me updated on new and exclusive
                  offers.</b></span>
            </label>
            <label class="w-checkbox" *ngIf="!authService.isLoggedIn()">
              <input type="checkbox" id="smsOptIn" name="smsOptIn" class="w-checkbox-input" formControlName="smsOptIn">
              <span class="checkbox-label w-form-label">I want to receive text communications.</span>
            </label>
            <label class="w-checkbox" *ngIf="!authService.isLoggedIn()">
              <input type="checkbox" id="emailOptIn" name="emailOptIn" class="w-checkbox-input"
                formControlName="emailOptIn">
              <b><span class="checkbox-label w-form-label">I want to receive e-mail communications.</span></b>
            </label>
          </div>
        </form>
      </div>
      <h1 class="checkout-h1 shipping-h1" *ngIf="!authService.isLoggedIn()">Account Info</h1>
      <div class="w-form" *ngIf="!authService.isLoggedIn()">
        <form [formGroup]="accountInfoForm" id="email-form1" name="email-form" class="account-info-form">
          <div fxLayout="column">
            <div fxLayout="row" fxFlex="100">
              <div fxLayout="column" fxFlex="100">
                <div>
                  <input type="text" class="form-field w-input" maxlength="256" name="LoginName"
                    formControlName="loginName" placeholder="Login Name" id="loginName" required
                    [readonly]="true"
                    (focusout)="_enrollSession.data.RetailOrderSummary.customerInfoData.ConfirmEmail?EmailRegistered(_enrollSession.data.RetailOrderSummary.customerInfoData.Email):''">
                </div>
                <span style="font-size: x-small;">
                  Your Login name is the email address you provided above. To change this please scroll back
                  up to the Customer Info section and change it there.
                </span>
                <span class="help-block"
                  *ngIf="!accountInfoForm.get('loginName').valid&&accountInfoForm.get('loginName').touched">
                  <span *ngIf="accountInfoForm.get('loginName').errors['required']">This field is required</span>
                </span>
              </div>
            </div>
            <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="row" fxFlex="100" fxLayoutAlign="space-between none">
              <div fxLayout="column" fxFlex="49" fxLayoutAlign="start none" *ngIf="!authService.isLoggedIn()"
                class="password-reset passwordDiv">
                <div style="display: flex;">
                  <input [type]="showPassword ? 'text' : 'password'" class="form-field w-input input-field"
                    [(ngModel)]="userInfo.password" (keyup)="validatePassword(userInfo.password)" maxlength="256"
                    name="Password" formControlName="Password" placeholder="Enter Password" minlength="8"
                    id="Password-2" required>
                  <span class="toggleEye" (click)="togglePasswordShowHide()"><i class="fa fa-eye"
                      *ngIf="showPassword"></i><i class="fa fa-eye-slash" *ngIf="!showPassword"></i></span>
                </div>
                <div class="error-box text-left">
                  <span class="help-blocks"
                    *ngIf="!accountInfoForm.get('Password').valid && accountInfoForm.get('Password').touched">
                    <span *ngIf="accountInfoForm.get('Password').errors['required']">This field is required<br></span>
                  </span>
                  <span class="Error-font-size"
                    *ngIf="accountInfoForm.get('Password').dirty && userInfo.password?.length > 0">
                    <span *ngIf="!(hasNum && hasUpper && hasLower && hasSpecial && hasLength)">
                      <span class="not-help-blocks">Password must contain </span><span
                        [ngClass]="{'help-blocks': !hasLength, 'not-help-blocks':hasLength}">
                        8 characters, </span><span [ngClass]="{'help-blocks': !hasNum, 'not-help-blocks':hasNum}">1
                        numeric, </span><span [ngClass]="{'help-blocks': !hasUpper, 'not-help-blocks':hasUpper}">1
                        upper case letter, </span><span
                        [ngClass]="{'help-blocks': !hasLower, 'not-help-blocks':hasLower}">1
                        lower case letter, </span><span
                        [ngClass]="{'help-blocks': !hasSpecial, 'not-help-blocks':hasSpecial}">1
                        special character</span>.</span>
                  </span>
                </div>
              </div>
              <div class="password-reset passwordDiv"  fxLayout="column" fxFlex="49" fxLayoutAlign="start none"
                *ngIf="!authService.isLoggedIn()">
                <div style="display: flex;">
                  <input [type]="ConfirmPassword ? 'text' : 'password'" class="form-field w-input"
                    [(ngModel)]="userInfo.reenterPassword" maxlength="256" name="ConfirmPassword"
                    formControlName="ConfirmPassword" placeholder="Re-Enter Password" id="Confirm-Password-2" required>
                  <span class="toggleEye" (click)="togglePasswordShowHide2()"><i class="fa fa-eye"
                      *ngIf="ConfirmPassword"></i><i class="fa fa-eye-slash" *ngIf="!ConfirmPassword"></i></span>
                </div>
                <div class="error-box text-left">
                  <span class="help-blocks"
                    *ngIf="!accountInfoForm.get('ConfirmPassword').valid && accountInfoForm.get('ConfirmPassword').touched">
                    <span *ngIf="accountInfoForm.get('ConfirmPassword').errors['required']">This
                      field is required</span>
                    <span *ngIf="accountInfoForm.get('ConfirmPassword').errors['passwordMismatch']">Password
                      Does Not Matched</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div>
        <label class="w-checkbox">
          <input type="checkbox" id="shiporder" [checked]='_enrollSession.data.isShippedToAnother' value="_enrollSession.data.isShippedToAnother" name="shiporder" class="w-checkbox-input" (click)="shipToAnotherAdd(_enrollSession.data.isShippedToAnother)">
          <span class="checkbox-label w-form-label"><b>SHIP THIS ORDER TO ANOTHER ADDRESS</b></span>
        </label>
      </div>
      <div class="w-form" *ngIf="_enrollSession.data.isShippedToAnother">
        <form [formGroup]="shippingForm" id="email-form2" name="email-form" class="shipping-info-form">
          <div fxLayout="column">
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
              <div fxLayout="column" fxFlex="49">
                <div>
                  <input type="text" class="form-field w-input" maxlength="30" name="First-Name"
                    formControlName="firstName" placeholder="First Name*" id="First-Name" required>
                </div>
                <div class="error-box">
                  <span class="help-block"
                    *ngIf="!shippingForm.get('firstName').valid&&shippingForm.get('firstName').touched">
                    <span *ngIf="shippingForm.get('firstName').errors['required']">This field is required</span>
                  </span>
                </div>
              </div>
              <div fxLayout="column" fxFlex="49">
                <div>
                  <input type="text" class="form-field  w-input" maxlength="30" name="Last-Name"
                    formControlName="lastName" placeholder="Last Name*" id="Last-Name-2" required>
                </div>
                <div class="error-box">
                  <span class="help-block p-l-10"
                    *ngIf="!shippingForm.get('lastName').valid&&shippingForm.get('lastName').touched">
                    <span *ngIf="shippingForm.get('lastName').errors['required']">This field is required</span>
                  </span>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxFlex="100">
              <div fxLayout="column" fxFlex="100">
                <div>
                  <input type="text" class="form-field w-input" maxlength="50" name="Address-1"
                    formControlName="address1" placeholder="Address 1*" id="Address-1" required>
                </div>
                <div class="error-box">
                  <span class="help-block"
                    *ngIf="!shippingForm.get('address1').valid&&shippingForm.get('address1').touched">
                    <span *ngIf="shippingForm.get('address1').errors['required']">This field is required</span>
                  </span>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxFlex="100">
              <input type="text" class="form-field w-input" maxlength="50" name="address-2" formControlName="address2"
                placeholder="Address 2 (Optional)" id="address-3">
            </div>
            <div fxLayout="row" fxFlex="100">
              <div fxLayout="column" fxFlex="100">
                <div>
                  <input type="text" class="form-field w-input" maxlength="30" name="City" formControlName="City"
                    placeholder="City*" id="City-2" required>
                </div>
                <div class="error-box">
                  <span class="help-block p-l-10"
                    *ngIf="!shippingForm.get('City').valid&&shippingForm.get('City').touched">
                    <span *ngIf="shippingForm.get('City').errors['required']">This field is required</span>
                  </span>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
              <div fxLayout="column" fxFlex="32">
                <div>
                  <select name="CountryCode" formControlName="CountryCode" class="form-field w-input"
                    [(ngModel)]="_enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode"
                    (change)="countryChange(_enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode,1)">
                    <option *ngFor="let country of countriesList" [value]='country.countryCodeField'>{{
                      country.countryNameField}} </option>
                  </select>
                </div>
                <div>
                </div>
              </div>
              <div fxLayout="column" fxFlex="32">
                <div>
                  <div>
                    <select name="State" id="" formControlName="State" class="form-field w-input"
                      [(ngModel)]="!_enrollSession.data.RetailOrderSummary.shippingFormData.State ? ('') : _enrollSession.data.RetailOrderSummary.shippingFormData.State"
                      (change)="stateChange(_enrollSession.data.RetailOrderSummary.shippingFormData.State,1)">
                      <option value="" disabled selected hidden>
                        {{customerDetail.MainState?customerDetail.MainState:(_enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode
                        =="US" ? "select state": "select province")}}</option>
                      <option *ngFor="let state of ShippingStateList" [ngValue]='state.regionCodeField'>
                        {{state.regionNameField}}</option>
                    </select>
                  </div>
                  <div class="error-box">
                    <span class="help-block p-l-10"
                      *ngIf="!shippingForm.get('State').valid&&shippingForm.get('State').touched">
                      <span *ngIf="shippingForm.get('State').errors['required']">This field is required</span>
                    </span>
                  </div>
                </div>
              </div>
              <div fxLayout="column" fxFlex="32">
                <div>
                  <input type="text" class="form-field  w-input" maxlength="256" name="Zip-Code"
                    formControlName="ZipCode" placeholder="Zip Code*" id="Zip-Code-2" required
                    (keyup)="regexValidateZip2($event)"
                    (focusout)="(_enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode?checkCalculateOrder():'');regexValidateZip2($event);">
                </div>
                <div class="error-box">
                  <span class="error-zip" *ngIf="invalidzip2">INVALID ZIP</span>
                  <span class="help-block"
                    *ngIf="!shippingForm.get('ZipCode').valid&&shippingForm.get('ZipCode').touched">
                    <span *ngIf="shippingForm.get('ZipCode').errors['required']">This field is required</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <h1 class="checkout-h1 shipping-h1">Shipping Method For Today&#x27;s Order</h1>
      <div class="w-form">
        <form [formGroup]="shippingMethodForm" id="email-form" name="email-form3" class="shipping-method-form">
          <div *ngFor="let shipping of _enrollSession.data.ShippingMethods">
            <label class="shipping-radio-button-field w-radio m-b-1">
              <input type="radio" name="shippingType" formControlName="shippingType" value="{{shipping.ShipMethodID}}"
                class="w-form-formradioinput shipping-method-radio-button w-radio-input">
              <span class="shipping-radio-button-label w-form-label">{{shipping.Description}}</span>
              <div class="shipping-price-text">${{shipping.ShippingAmount |number : '1.2-2'}}</div>
            </label>
          </div>
          <div>
            <label class="secure-shipping-align billing-address-radio-button-field w-radio">
              <span class="f-s-12 secureShippingTagLine">Protect your order with Signature Required ($6 fee) &nbsp; - &nbsp;</span>
                <div class="display-flex-radio">
                  <label>
                    <input type="radio" id="radio-3" name="SecureShipping" value="yes" formControlName="SecureShipping" class="w-form-formradioinput shipping-method-radio-button" (click)="secureShippingMethod(true)">
                  <span class="billing-address-radio-button-label w-form-label">Yes &nbsp; &nbsp;</span>
                  </label>
                </div>
                <div class="display-flex-radio">
                  <label>
                    <input type="radio" id="radio-4" name="SecureShipping" value="no" formControlName="SecureShipping" class="w-form-formradioinput shipping-method-radio-button" (click)="secureShippingMethod(false)" checked="true">
                  <span class="billing-address-radio-button-label w-form-label">No</span>
                  </label>
                </div>
            </label>
          </div>
        </form>
       
        <div *ngIf="!_enrollSession.mobileViewMethod() && !_sharedMethodService.isOpen">
          <a class="desktopButton" (click)="OpenDesktopCart();">Continue</a>
        </div>
      </div>
      <app-footercomponent></app-footercomponent>
    </div>
  </div>
</div>