<div class="dialog-content-wrapper" id="processTypePopup" *ngIf="data==1">
  <div style="display:flex;overflow-y: hidden;" class="pop-up-background">
    <div class="pop-up-div">
      <div class="m-0 close-text" (click)="closePopup()">Close
      </div>
      <h1 class="pop-up-headline"> Always Ship </h1>
      <div class="border-line"></div>
      <div class="ship-text">
        If you choose ALWAYS SHIP - your monthly subscription order will Always Ship on your Subscription date.
      </div>
    </div>
  </div>
</div>
    
<div class="dialog-content-wrapper" id="processTypePopup" *ngIf="data==2">
  <div style="display:flex;overflow-y: hidden;" class="pop-up-background">
    <div class="pop-up-div">
      <div class="m-0 close-text" (click)="closePopup()">Close
      </div>
      <h1 class="pop-up-headline">  Backup Order </h1>
      <div class="border-line"></div>
      <div class="ship-text">
        If you choose BACKUP ORDER - If you place another order before your subscription date, your subscription will not be processed for that current month. (Your Subscription will remain Active for future months).
      </div>
    </div>
  </div>
</div>





