import { FormBuilder,  FormGroup,  Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { ToastrService } from 'ngx-toastr';
import { RestService } from 'app/shared/rest.service';
import { Router } from '@angular/router';
import { RgetRegexService } from 'app/shared/getRegex.service';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { AuthService } from 'app/shared/auth.service';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { CalculateRetailOrderService } from 'app/shared/calculateRetailOrder.service';
declare var ka: any;
@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
   
    billingAddressForm: FormGroup;
    paymentTypeForm: FormGroup;
    rewardPointForm: FormGroup;
    currentYear = new Date().getFullYear();
    currentMonth = new Date().getMonth() + 1;
    monthList: any[] = [];
    yearList: any[] = [];
    invalidzip1: boolean = false;
    sameAddressStatus: boolean = true;
    SmartOrderDate: any;
    SubscriptionProcessType: any;
    siteType: any;
    LoggedInUser:any = JSON.parse(localStorage.getItem("LoggedInUserData")) || {}
    PaymentMethodDifferent: boolean = false;
    expirationYearField: any;
    expirationMonthField:any;
    creditCardNumberDisplayField:any
    paymentDetails : any;
    customerDetail: any;
    isOpenDiscountDropdown:boolean = false;
    hasCardNumberCheck:boolean;
    hasSecurityCodeForAmerican:boolean;
    hasSecurityCodeForVDM:boolean;
    private _unsubscribeAll: Subject<any>
    constructor(
        public _enrollSession: EnrollSessionService,
        private _toastr: ToastrService,
        private _formBuilder: FormBuilder,
        private _restService: RestService,
        private _route: Router,
        public _RgetRegexService: RgetRegexService,
        private router: Router,
        public authService: AuthService,
        public _sharedMethodService: SharedMethodService,
        public _calculateRetailOrderService: CalculateRetailOrderService,
    ) {
        this._unsubscribeAll = new Subject();
        this._sharedMethodService.submissionInProgress = false;
        this.SmartOrderDate = this._enrollSession.data.smartOrderDate;
        this.paymentDetails = JSON.parse(localStorage.getItem('billingInfo')) || {}
        
        let paymentTypeInfo = this._enrollSession.data.RetailOrderSummary?.paymentTypeData;
        let billingAddressInfo = this._enrollSession.data.RetailOrderSummary?.billingAddressData;
        this.paymentTypeForm = this.createPaymentTypeForm(paymentTypeInfo);
        this.billingAddressForm = this.createBillingAddressForm(billingAddressInfo);
        this.expirationYearField = this.paymentDetails?.primaryCreditCardField?.expirationYearField || this.paymentDetails?.secondaryCreditCardField?.expirationYearField;
        this.expirationMonthField = this.paymentDetails?.primaryCreditCardField?.expirationMonthField || this.paymentDetails?.secondaryCreditCardField?.expirationMonthField;
        this.creditCardNumberDisplayField = this.paymentDetails?.primaryCreditCardField?.creditCardNumberDisplayField || this.paymentDetails?.secondaryCreditCardField?.creditCardNumberDisplayField;
        for (let i = 1; i <= 12; i++) {
            this.monthList.push({ key: i, value: i });
        }
        for (let i = 0; i < 20; i++) {
            this.yearList.push({ key: (this.currentYear + i), value: (this.currentYear + i) });
        }
        this.siteType = sessionStorage.getItem("siteType");
        if(((this._enrollSession.data.RetailOrderSummary == undefined) || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.Enrollpack?.length == 0 && this._enrollSession.data.RetailOrderSummary.Enrollpack == undefined && this._enrollSession.data.RetailOrderSummary.Enrollpack?.length == 0) || (this._enrollSession.data.RetailOrderSummary.customerInfoData == undefined)) && (this.siteType && this.siteType!=null)){
            if(this._enrollSession.data.RetailOrderSummary.Enrollpack?.length == 0 || this._enrollSession.data.RetailOrderSummary == undefined) {
                this._toastr.warning("Please select Transformation Pack", '');
                this.router.navigate(['/AllProducts']);
                return
            }    
        }
        this._enrollSession.data.RetailOrderSummary.shippingFormData.CompanyName = this._enrollSession.data.RetailOrderSummary.customerInfoData?.CompanyName;
        if((this._enrollSession.data.RetailOrderSummary == undefined) ||  this._enrollSession.data.RetailOrderSummary.InitialOrders==undefined || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.InitialOrders?.length == 0) &&  (this.siteType && (this.siteType==null||this.siteType==''))){
            this._toastr.warning("Please select an item", '');
        this.router.navigate(['/AllProducts']);
       }
        if((this._enrollSession.data.RetailOrderSummary) && ( this._enrollSession.data.RetailOrderSummary.customerInfoData == undefined && _.isEmpty(this._enrollSession.data.RetailOrderSummary.customerInfoData) || this._enrollSession.data.RetailOrderSummary.customerInfoData.Email == undefined || (!this.authService.getToken() && this._enrollSession.data.RetailOrderSummary.accountInfoData.Password == undefined) || this._enrollSession.data.RetailOrderSummary.customerInfoData.Phone == undefined || ((this._enrollSession.data.RetailOrderSummary.shippingFormData.firstName == undefined || this._enrollSession.data.RetailOrderSummary.shippingFormData.lastName == undefined) && this._enrollSession.data.RetailOrderSummary.shippingFormData.CompanyName == undefined ) || this._enrollSession.data.RetailOrderSummary.shippingFormData.address1 == undefined || this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode == undefined || this._enrollSession.data.RetailOrderSummary.shippingFormData.City == undefined)) {
            this._toastr.warning("Please fill all required field", 'Warning');
            this.router.navigate(['/Shipping']);  
            return
        }
        if (this._enrollSession.data.RetailOrderSummary?.SmartOrders?.length>0) {
            this.SubscriptionProcessType = localStorage.getItem("SubscriptionProcessType")=='AutoOrderProcessTy1'?0:1;
            } else {
            this.SubscriptionProcessType = '';
        }
        this.customerDetail = JSON.parse(localStorage.getItem('LoggedInUserData')) || {};

    }
   
    createPaymentTypeForm(paymentTypeInfo:any): FormGroup {
        return this._formBuilder.group({
            primaryPaymentCard: [],
            cardNumber: ['', [Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.maxLength(16)]],
            nameOnCard: [paymentTypeInfo?.nameOnCard || '', [Validators.required, Validators.pattern(/^[a-zA-Z\s]+$/)]],
            expMonth: [paymentTypeInfo?.expMonth || '', [Validators.required]],
            expYear: [paymentTypeInfo?.expYear || '', [Validators.required]],
            securityCode: ['', [Validators.required, Validators.pattern(/^-?(0|[0-9]\d*)?$/)]]
        })
    }

    createBillingAddressForm(billingAddressInfo:any): FormGroup {
        return this._formBuilder.group({
            sameAddress: [],
            firstName: [ billingAddressInfo?.firstName || '', [Validators.required]],
            lastName: [billingAddressInfo?.lastName || '', [Validators.required]],
            address1: [billingAddressInfo?.address1 || '', [Validators.required]],
            address2: [billingAddressInfo?.address2 || ''],
            CountryCode: [billingAddressInfo?.CountryCode || '', Validators.required],
            ZipCode: [billingAddressInfo?.ZipCode || '', [Validators.required]],
            City: [billingAddressInfo?.City || '', Validators.required],
            State: [billingAddressInfo?.State || '', Validators.required]
        });
    }
    ngOnInit(): void {
        window.scrollTo(0,0);
        this._enrollSession.data.RetailOrderSummary.billingAddressData = this._enrollSession.data.RetailOrderSummary.billingAddressData || {};
        this.billingAddressForm.valueChanges.subscribe(selectedValue => {
            this._enrollSession.data.RetailOrderSummary.billingAddressData = selectedValue;
            this._enrollSession.data.RetailOrderSummary.billingAddressData.State = selectedValue.State
        });
        this._enrollSession.data.RetailOrderSummary.paymentTypeData = this._enrollSession.data.RetailOrderSummary.paymentTypeData || {};
        this.paymentTypeForm.valueChanges.subscribe(selectedValue => {
            this._enrollSession.data.RetailOrderSummary.paymentTypeData = selectedValue;
        });

        
        this.billingAddressForm.get('sameAddress').valueChanges
            .subscribe((value) => {
                if (!value) {
                    this._enrollSession.addValidators(this.billingAddressForm, "firstName");
                    this._enrollSession.addValidators(this.billingAddressForm, "lastName");
                    this._enrollSession.addValidators(this.billingAddressForm, "address1");
                    this._enrollSession.addValidators(this.billingAddressForm, "CountryCode");
                    this._enrollSession.addValidators(this.billingAddressForm, "ZipCode");
                    this._enrollSession.addValidators(this.billingAddressForm, "City");
                    this._enrollSession.addValidators(this.billingAddressForm, "State");
                }
                else {
                    this._enrollSession.removeValidators(this.billingAddressForm, "firstName");
                    this._enrollSession.removeValidators(this.billingAddressForm, "lastName");
                    this._enrollSession.removeValidators(this.billingAddressForm, "address1");
                    this._enrollSession.removeValidators(this.billingAddressForm, "CountryCode");
                    this._enrollSession.removeValidators(this.billingAddressForm, "ZipCode");
                    this._enrollSession.removeValidators(this.billingAddressForm, "City");
                    this._enrollSession.removeValidators(this.billingAddressForm, "State");
                }
            });

        this.paymentTypeForm.get('primaryPaymentCard').valueChanges.subscribe(selectValue => {
            if(selectValue == "true"){
                let paymentTypeInfo = this._enrollSession.data.RetailOrderSummary.paymentTypeData;
                this.paymentTypeForm.patchValue({
                    CreditDebitCard: paymentTypeInfo.CreditDebitCard || '',
                    cardNumber: '',
                    nameOnCard: paymentTypeInfo.nameOnCard || '',
                    expMonth: paymentTypeInfo.expMonth || '',
                    expYear: paymentTypeInfo.expYear || '',
                    securityCode: ''
                }); 
            }
            else if(selectValue == "false"){
                let paymentTypeInfo = this._enrollSession.data.RetailOrderSummary.paymentTypeData;
                this.paymentTypeForm.patchValue({
                    CreditDebitCard: paymentTypeInfo.CreditDebitCard,
                    cardNumber: '',
                    nameOnCard: paymentTypeInfo.nameOnCard,
                    expMonth: paymentTypeInfo.expMonth,
                    expYear: paymentTypeInfo.expYear,
                    securityCode: ''
                });
            }
        });
        if (this._enrollSession.data.RetailOrderSummary.paymentTypeData.primaryPaymentCard == "false") {
            this.paymentTypeForm.get("primaryPaymentCard").setValue("false");
        } else if (this._enrollSession.data.RetailOrderSummary.paymentTypeData.primaryPaymentCard == "true") {
            this.paymentTypeForm.get("primaryPaymentCard").setValue("true");
        } else {
            this.paymentTypeForm.get("primaryPaymentCard").setValue("true");
        }
        this.billingAddressForm.get('sameAddress').valueChanges.subscribe(selectedValue => {
            if (selectedValue == "true") {
                let billingAddressInfo = this._enrollSession.data.RetailOrderSummary.shippingFormData;
                this.billingAddressForm.patchValue({
                    firstName: billingAddressInfo.firstName,
                    lastName: billingAddressInfo.lastName,
                    address1: billingAddressInfo.address1,
                    address2: billingAddressInfo.address2,
                    CountryCode: billingAddressInfo.CountryCode,
                    ZipCode: billingAddressInfo?.ZipCode && billingAddressInfo.ZipCode?.split('-')[0],
                    City: billingAddressInfo.City,
                    State: billingAddressInfo.State
                });
            } 
        });
        if (this._enrollSession.data.RetailOrderSummary.billingAddressData.sameAddress == "false") {
            this.billingAddressForm.get("sameAddress").setValue("false");
        } else if (this._enrollSession.data.RetailOrderSummary.billingAddressData.sameAddress == "true") {
            this.billingAddressForm.get("sameAddress").setValue("true");
        } else {
            this.billingAddressForm.get("sameAddress").setValue("true");
        }
        if(!this.authService.isLoggedIn()){
            if (_.isEmpty(this._enrollSession.data.RetailOrderSummary.paymentTypeData)) {
                this.paymentTypeForm.patchValue({
                    expMonth: this.currentMonth,
                    expYear: this.currentYear,
                });
            }
            if (this._enrollSession.data.RetailOrderSummary.paymentTypeData) {
                let paymentTypeInfo = this._enrollSession.data.RetailOrderSummary.paymentTypeData;
                this.paymentTypeForm.patchValue({
                    CreditDebitCard: paymentTypeInfo.CreditDebitCard,
                    cardNumber: this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber,
                    nameOnCard: paymentTypeInfo.nameOnCard,
                    expMonth: paymentTypeInfo.expMonth,
                    expYear: paymentTypeInfo.expYear,
                    securityCode: paymentTypeInfo.securityCode,
                });
            }
        }
     
        if (this._enrollSession.data.RetailOrderSummary.billingAddressData) {
            let billingAddressInfo = this._enrollSession.data.RetailOrderSummary.billingAddressData;
            this.billingAddressForm.patchValue({
                firstName: billingAddressInfo.firstName,
                lastName: billingAddressInfo.lastName,
                address1: billingAddressInfo.address1,
                address2: billingAddressInfo.address2,
                CountryCode: billingAddressInfo.CountryCode,
                ZipCode: billingAddressInfo.ZipCode,
                City: billingAddressInfo.City,
                State: billingAddressInfo.State
            });
        }

       

        this.customerDetail = JSON.parse(this.authService.getUserData()) || {};
        this._sharedMethodService.isOpen = true;
    }


    differentPaymentMethod(type) {
        if(type=='paymentDifferent'){
            this.PaymentMethodDifferent = true;
        }
        else {
            this.PaymentMethodDifferent = false;
        }
        localStorage.setItem('PaymentMethodDifferent',JSON.stringify(this.PaymentMethodDifferent));
    }

    billToAnotherAdd(){
        let billingAddressInfoData = this._enrollSession.data.RetailOrderSummary?.billingAddressData;;
        if (!_.isEmpty(billingAddressInfoData)) {
            this.getStates('US');
            this.billingAddressForm.patchValue({
                firstName: '',
                lastName: '',
                address1: '',
                address2: '',
                CountryCode: this._enrollSession.data.countriesList ? this._enrollSession.data.countriesList[0].countryCodeField : '',
                ZipCode: '',
                City: '',
                State: ''
            });
        }
    }

    getStates(country): void {
        if (country) {
            const getStatesRequest = { CountryCode: country };
            this._restService.GetCountryRegions(getStatesRequest)
                .subscribe((result: any) => {
                    this._enrollSession.data.billingstateList = result.regionsField;
                    this._enrollSession.data.RetailOrderSummary.billingAddressData.State = ['US','CA'].indexOf(country) > -1 ? result.regionsField[0].regionCodeField : result.regionsField[0].regionCodeField || '';
                }
                );
        }

    }

    
 
    regexValidateZip1(event): void {
        const billingAddressForm = this.billingAddressForm ? this.billingAddressForm.getRawValue() : {};
        const regxExp = this._RgetRegexService.getRegex(billingAddressForm.CountryCode);
        const filter = new RegExp(regxExp);
        if (filter.test(event.target.value)) {
            this.invalidzip1 = false;
        } else if (event.target.value && (event.target.value.indexOf(' ') !== 0)) {
            this.invalidzip1 = true;
        }
        else {
            this.invalidzip1 = false;
        }
    }
    OpenDesktopCart()
    {
        this._sharedMethodService.isOpen = true;
    }
    
    ClickToOpenDiscount(){
        this.isOpenDiscountDropdown = true;
    }
    validateCardNumber(cardNumber?: any) {
        let length = cardNumber?.toString().length;
        let firstdigit = getDigit(cardNumber,length);
        if((firstdigit === 3 || firstdigit === 4 || firstdigit === 5 || firstdigit === 6 || firstdigit === 9)){
            this.hasCardNumberCheck = true;
        }
        else{
            this.hasCardNumberCheck = false;
        }
        this.validateSecurityCode(firstdigit);
    }
    validateSecurityCode(firstdigit?:any){
        if(firstdigit === 3){
            this.hasSecurityCodeForAmerican = true;
            this.hasSecurityCodeForVDM = false;
        }
        else if(firstdigit === 4 || firstdigit === 5 || firstdigit === 6 || firstdigit === 9){
            this.hasSecurityCodeForVDM = true;
            this.hasSecurityCodeForAmerican = false;
        }
        else{
            this.hasSecurityCodeForVDM = false;
            this.hasSecurityCodeForAmerican = false;
        }
    }
}
function getDigit(number, n) {
    return Math.floor((number / Math.pow(10, n - 1)) % 10);
  }
