<div class="dialog-content-wrapper" id="two-way-wrapper" *ngIf="currentRouterPath == '/OrderReview'">
    <mat-toolbar matDialogTitle class="m-0">
    </mat-toolbar>
    <div style="display:flex" class="pop-up-background">
      <div class="pop-up-div">
            <h2 class="pop-up-headline f-s-18 m-t-20 p-20" *ngIf="authService.isLoggedIn()">
              Please note that the Smart Order Subscription 
              that you are subscribing to today is in addition to your Smart Order
              you currently have on file. <br>
               If you wish modify your Smart Order on file, 
              please login to your account and click on Smart Order.</h2>
        <div class="pop-up-button-div">
            <button mat-button class="pop-up-button w-button w--current"
            cdkFocusInitial [mat-dialog-close]="true">Close</button></div>
      </div>
    </div>
  </div>
  
  
  