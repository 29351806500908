import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class EnrollSessionService {
  data: any;
  mobileView: boolean;
  SummaryCartMobOnly: boolean;
  submitApplicationData: any;
  mediumScreen: any;
  editTransformationPack: any;
  SessionKey:string;
  productList:any = [];
  customerImage:any;
      constructor(private _activateRoute: ActivatedRoute) {
      this.data = localStorage.getItem('EnrollSession') ? JSON.parse(localStorage.getItem('EnrollSession')) : {};
      this.submitApplicationData = JSON.parse(sessionStorage.getItem('submitApplicationData')) ? JSON.parse(sessionStorage.getItem('submitApplicationData')) : {};
      this.editTransformationPack = sessionStorage.getItem('editTransformationPack') ? sessionStorage.getItem('editTransformationPack') : '';
    }  
    resetEnrollSessionServiceData(page): any {
      if(this.editTransformationPack != 'true') {
        this.data = {};
        if (this.data && Object.keys(this.data).length === 0) {
            this.data.IsShowCart = false;
            this.data.page = page;
            this.data.TotalItemLength = 0;
            this.data.RetailEnroll = {};
            this.data.InitOrder = [];
            this.data.InitialOrdersSubTotal = 0;
            this.data.RetailOrderSummary = {};
            this.data.RetailOrderSummary.Enrollpack = [];
            this.data.EligibleForFreeUpgrade = false;
            this.data.RetailEnroll.IsNonprofit = false;
            this.data.FileUploaded = false;
            this.data.RetailEnroll.ShippingMethod = '';
            this.data.usePrimaryCard = null;
            this.data.primaryCreditCardField = {};
            this.data.EnrollmentType = this.data.EnrollmentType || 1;
            this.data.IsLoggedIn=false;
            this.data.LoggedInUserData={};
            this.data.IsUpGrade =
                this._activateRoute.snapshot.queryParamMap.get('customerId') ? this._activateRoute.snapshot.queryParamMap.get('customerId') : false;
            if (this.data.IsUpGrade) {
                localStorage.setItem('customerId', this.data.IsUpGrade);
            }
            this.data.Volume109 = 0;
            this.data.SmartOrderBVTotal = 0;
            this.data.smartOrderDate = 0;
            this.data.InitialOrdersQVTotal = 0;
            this.data.AddOnOrdersQVTotal = 0;
        }
      }  
    }

    removeValidators(form: FormGroup, formControl: any) {
      form.get(formControl).clearValidators();
      form.get(formControl).updateValueAndValidity();
    }
    addValidators(form: FormGroup, formControl: any) {
      form.get(formControl).setValidators([Validators.required, trimValidator]);
      form.get(formControl).updateValueAndValidity();
  }
  mobileViewMethod(){
    let screenWidth = window.innerWidth
    if(screenWidth < 551){
      return true;
    }
    else{
      return false;
    }
  }
}
const trimValidator: ValidatorFn = (control: FormControl) => {
  if (control && control.value && typeof control.value == "string" && !control.value.replace(/\s/g, '').length) {
      return {
          'trimError': { value: 'control has leading whitespace' }
      };
  }

  return null;
};
