import { Component, OnInit, ViewEncapsulation } from "@angular/core";



@Component({
    selector: 'secureShippingCheckNoDialog',
    templateUrl: './secureShippingCheckNoDialog.component.html',
    styleUrls: ['./secureShippingCheckNoDialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class secureShippingCheckNoDialogComponent implements OnInit {


    constructor(){}

    ngOnInit(): void {
        
    }
}