<!-- PROGRESS BAR -->
<!-- <fuse-progress-bar></fuse-progress-bar> -->
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<!-- <ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container> -->
<!-- / VERTICAL LAYOUT 1 -->
<!-- <app-my-loader></app-my-loader> -->
<router-outlet (activate)="onActivate($event)"></router-outlet>
<app-my-loader></app-my-loader>
<button class="chatbot-button" (click)="toggleChatbot()">
    <span *ngIf="!isChatbotOpen" class="text-white font-class">Q&A</span>
    <mat-icon *ngIf="isChatbotOpen" class="text-white">close</mat-icon>
</button>
<div class="chatbot-popup" *ngIf="isChatbotOpen">
    <app-faq-chatbox [faqsList]="faqsList" [faqsCatList]="faqsCatList" [isChatbotOpen]="isChatbotOpen"></app-faq-chatbox>
</div>