import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/shared/auth.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { SubscribeDialogComponent } from 'app/shared/subscribe-dialog/subscribe-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  steps: any;
  current_stepper_title: any = '';
  currentRouterPath: any;
  ProductCode: any;
  dialogRef: any;

  constructor(
    private _activateRoute: ActivatedRoute,
    public _enrollSession: EnrollSessionService,
    public _auth: AuthService,
    public _sharedMethodService: SharedMethodService,
    public router: Router,
    private _toastr: ToastrService,
    private _matDialog: MatDialog,
  ) {
    this.ProductCode = this._activateRoute.snapshot.paramMap.get('id');
    this.currentRouterPath = this.router.url.split('?')[0];
  }

  ngOnInit(): void {
    if (sessionStorage.getItem("siteType") == 'ultpack') {
        this.steps = [
          {
            stepper_title: ["TODAY", "ADD-ONS", "SUBSCRIBE-PRODUCTS", "SHIPPING", "CHECKOUT"],
            stepper_back: '',
            stepper_images: [],
            routes: []
          }
        ];
    } else if (sessionStorage.getItem("siteType") == 'ultlifestyle') {
      this.steps = [
        {
          stepper_title: ["REVIEW", "ADD-ONS", "SHIPPING", "CHECKOUT"],
          stepper_back: '',
          stepper_images: [],
          routes: []
        }
      ];
    }
    else {
      if(this._auth.isLoggedIn() && localStorage.getItem("isExistingAutoship") == "true"){
        this.steps = [
          {
            stepper_title: ["TODAY", "SHIPPING", "CHECKOUT"],
            stepper_back: '',
            stepper_images: [],
            routes: []
          }
        ];
      }
      else{
          this.steps = [
            {
              stepper_title: ["TODAY", "SUBSCRIBE-PRODUCTS", "SHIPPING", "CHECKOUT"],
              stepper_back: '',
              stepper_images: [],
              routes: []
            }
          ];
      }
    }
    this.current_stepper_title = this._activateRoute.snapshot.url[0].path;
    if (sessionStorage.getItem("siteType") == 'ultpack') {
        switch (this.current_stepper_title) {
          case 'AllProducts':
            this.steps[0].stepper_back = ""
            this.steps[0].stepper_images = ["assets/images/stepper-logo/Step-1-Green.svg",
              "assets/images/stepper-logo/Step-2-Gray.svg",
              "assets/images/stepper-logo/Step-3-Gray.svg",
              "assets/images/stepper-logo/Step-4-Gray.svg",
              "assets/images/stepper-logo/Step-5-Gray.svg"
            ]
            this.steps[0].routes = ["../AllProducts"]
            break;
          case 'products':
            this.steps[0].stepper_back = ""
            this.steps[0].stepper_images = ["assets/images/stepper-logo/Step-1-Green.svg",
              "assets/images/stepper-logo/Step-2-Gray.svg",
              "assets/images/stepper-logo/Step-3-Gray.svg",
              "assets/images/stepper-logo/Step-4-Gray.svg",
              "assets/images/stepper-logo/Step-5-Gray.svg"
            ]
            this.steps[0].routes = ["../AllProducts"]
            break;
          case 'Add-ons':
            this.steps[0].stepper_back = "../AllProducts"
            this.steps[0].stepper_images = ["assets/images/stepper-logo/Circle-Check.svg",
              "assets/images/stepper-logo/Step-2-Green.svg",
              "assets/images/stepper-logo/Step-3-Gray.svg",
              "assets/images/stepper-logo/Step-4-Gray.svg",
              "assets/images/stepper-logo/Step-5-Gray.svg"
            ]
            this.steps[0].routes = ["../AllProducts", "../Add-ons"]
            break;


          case 'Subscribe':
            this.steps[0].stepper_back = "../Add-ons"
            this.steps[0].stepper_images = ["assets/images/stepper-logo/Circle-Check.svg",
              "assets/images/stepper-logo/Step-2-Green.svg",
              "assets/images/stepper-logo/Step-3-Gray.svg",
              "assets/images/stepper-logo/Step-4-Gray.svg",
              "assets/images/stepper-logo/Step-5-Gray.svg"
            ]
            this.steps[0].routes = ["../AllProducts", "../Add-ons", "../Subscribe"]
            break;

          case 'Shipping':
            this.steps[0].stepper_back = "../Subscribe"
            this.steps[0].stepper_images = ["assets/images/stepper-logo/Circle-Check.svg",
              "assets/images/stepper-logo/Circle-Check.svg",
              "assets/images/stepper-logo/Step-3-Green.svg",
              "assets/images/stepper-logo/Step-4-Gray.svg",
              "assets/images/stepper-logo/Step-5-Gray.svg"
            ]
            this.steps[0].routes = ["../AllProducts", "../Add-ons", "../Subscribe", "../Shipping"]
            break;

          case 'Checkout':
            this.steps[0].stepper_back = "../Shipping"
            this.steps[0].stepper_images = ["assets/images/stepper-logo/Circle-Check.svg",
              "assets/images/stepper-logo/Circle-Check.svg",
              "assets/images/stepper-logo/Circle-Check.svg",
              "assets/images/stepper-logo/Step-4-Green.svg",
              "assets/images/stepper-logo/Step-5-Gray.svg"
            ]
            this.steps[0].routes = ["../AllProducts", "../Add-ons", "../Subscribe", "../Shipping", "../Checkout"]
            break;
        }
      }
      // switch case for images and routerlink
      //switch close
    else if (sessionStorage.getItem("siteType") == 'ultlifestyle') {
      // switch case for images and routerlink
      switch (this.current_stepper_title) {
        case 'OrderReview':
          this.steps[0].stepper_back = ""
          this.steps[0].stepper_images = ["assets/images/stepper-logo/Step-1-Green.svg",
            "assets/images/stepper-logo/Step-2-Gray.svg",
            "assets/images/stepper-logo/Step-3-Gray.svg",
            "assets/images/stepper-logo/Step-4-Gray.svg"
          ]
          this.steps[0].routes = ["../OrderReview"]
          break;

        case 'Add-ons':
          this.steps[0].stepper_back = "../OrderReview"
          this.steps[0].stepper_images = ["assets/images/stepper-logo/Circle-Check.svg",
            "assets/images/stepper-logo/Step-2-Green.svg",
            "assets/images/stepper-logo/Step-3-Gray.svg",
            "assets/images/stepper-logo/Step-4-Gray.svg"
          ]

          this.steps[0].routes = ["../OrderReview", "../Add-ons"]
          break;

        case 'Shipping':
          this.steps[0].stepper_back = "../Add-ons"
          this.steps[0].stepper_images = ["assets/images/stepper-logo/Circle-Check.svg",
            "assets/images/stepper-logo/Circle-Check.svg",
            "assets/images/stepper-logo/Step-3-Green.svg",
            "assets/images/stepper-logo/Step-4-Gray.svg"
          ]

          this.steps[0].routes = ["../OrderReview", "../Add-ons", "../Shipping"]
          break;

        case 'Checkout':
          this.steps[0].stepper_back = "../Shipping"
          this.steps[0].stepper_images = ["assets/images/stepper-logo/Circle-Check.svg",
            "assets/images/stepper-logo/Circle-Check.svg",
            "assets/images/stepper-logo/Circle-Check.svg",
            "assets/images/stepper-logo/Step-4-Green.svg"
          ]
          this.steps[0].routes = ["../OrderReview", "../Add-ons", "../Shipping", "../Checkout"]
          break;
      }
      //switch close
    }
    else {
      if(this._auth.isLoggedIn() && localStorage.getItem("isExistingAutoship") == "true"){
        switch (this.current_stepper_title) {
          case 'AllProducts':
            this.steps[0].stepper_back = ""
            this.steps[0].stepper_images = ["assets/images/stepper-logo/Step-1-Green.svg",
              "assets/images/stepper-logo/Step-2-Gray.svg",
              "assets/images/stepper-logo/Step-3-Gray.svg",
            ]
            this.steps[0].routes = ["../AllProducts"]
            break;
          case 'products':
            this.steps[0].stepper_back = ""
            this.steps[0].stepper_images = ["assets/images/stepper-logo/Step-1-Green.svg",
              "assets/images/stepper-logo/Step-2-Gray.svg",
              "assets/images/stepper-logo/Step-3-Gray.svg",
            ]
            this.steps[0].routes = ["../AllProducts"]
            break;

          case 'Shipping':
            this.steps[0].stepper_back = "../AllProducts"
            this.steps[0].stepper_images = ["assets/images/stepper-logo/Circle-Check.svg",
              "assets/images/stepper-logo/Step-2-Green.svg",
              "assets/images/stepper-logo/Step-3-Gray.svg",
            ]
            this.steps[0].routes = ["../AllProducts", "../Shipping"]
            break;


          case 'Checkout':
            this.steps[0].stepper_back = "../Shipping"
            this.steps[0].stepper_images = ["assets/images/stepper-logo/Circle-Check.svg",
              "assets/images/stepper-logo/Circle-Check.svg",
              "assets/images/stepper-logo/Step-3-Green.svg",
            ]
            this.steps[0].routes = ["../AllProducts", "../Shipping", "../Checkout"]

            break;

        }
      }
      else{
          // switch case for images and routerlink
          switch (this.current_stepper_title) {
            case 'AllProducts':
              this.steps[0].stepper_back = ""
              this.steps[0].stepper_images = ["assets/images/stepper-logo/Step-1-Green.svg",
                "assets/images/stepper-logo/Step-2-Gray.svg",
                "assets/images/stepper-logo/Step-3-Gray.svg",
                "assets/images/stepper-logo/Step-4-Gray.svg",
              ]
              this.steps[0].routes = ["../AllProducts"]
              break;
            case 'products':
              this.steps[0].stepper_back = ""
              this.steps[0].stepper_images = ["assets/images/stepper-logo/Step-1-Green.svg",
                "assets/images/stepper-logo/Step-2-Gray.svg",
                "assets/images/stepper-logo/Step-3-Gray.svg",
                "assets/images/stepper-logo/Step-4-Gray.svg",
              ]
              this.steps[0].routes = ["../AllProducts"]
              break;
            case 'Subscribe':
              this.steps[0].stepper_back = "../AllProducts"
              this.steps[0].stepper_images = ["assets/images/stepper-logo/Circle-Check.svg",
                "assets/images/stepper-logo/Step-2-Green.svg",
                "assets/images/stepper-logo/Step-3-Gray.svg",
                "assets/images/stepper-logo/Step-4-Gray.svg",
              ]
              this.steps[0].routes = ["../AllProducts", "../Subscribe"]
              break;
  
            case 'Shipping':
              this.steps[0].stepper_back = "../Subscribe"
              this.steps[0].stepper_images = ["assets/images/stepper-logo/Circle-Check.svg",
                "assets/images/stepper-logo/Circle-Check.svg",
                "assets/images/stepper-logo/Step-3-Green.svg",
                "assets/images/stepper-logo/Step-4-Gray.svg",
              ]
              this.steps[0].routes = ["../AllProducts", "../Subscribe", "../Shipping"]
              break;
  
  
            case 'Checkout':
              this.steps[0].stepper_back = "../Shipping"
              this.steps[0].stepper_images = ["assets/images/stepper-logo/Circle-Check.svg",
                "assets/images/stepper-logo/Circle-Check.svg",
                "assets/images/stepper-logo/Circle-Check.svg",
                "assets/images/stepper-logo/Step-4-Green.svg",
              ]
              this.steps[0].routes = ["../AllProducts", "../Subscribe", "../Shipping", "../Checkout"]
  
              break;
  
          }
          //switch close
        }
    }
  }

  hide(i) {
    if (this.steps[0].stepper_title.length - 1 == i) {
      return false;
    }
    else {
      return true;
    }
  }
  closeMobileSummaryCart() {
    this._enrollSession.SummaryCartMobOnly = !this._enrollSession.SummaryCartMobOnly;
}
  subscribeDialog() {
    if ((this.currentRouterPath == "/Subscribe" || this.currentRouterPath == "/Subscribe/" + this.ProductCode) && this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0) {
      if (window.innerWidth < 550) {
        this.closeMobileSummaryCart();
      }
      else {
        this._sharedMethodService.toggleSidenavforaddtocart();
      }
      this.openCart();
    }
    else if (this.currentRouterPath == "/Subscribe" || this.currentRouterPath == "/Subscribe/" + this.ProductCode) {
      this.dialogRef = this._matDialog.open(SubscribeDialogComponent, {
        width: '500px',
        maxWidth: '500px',
        height: '375px',
        panelClass: '',
        disableClose: true
      });
      this.dialogRef.afterClosed()
        .subscribe(response => {
          if (!response) {
            return;
          } else {
            if (response == 'false') {
              if (window.innerWidth < 550) {
                this.closeMobileSummaryCart();
              }
              else {
                this._sharedMethodService.toggleSidenavforaddtocart();
              }
              this.openCart();
              this.router.navigate(['/Shipping']);
            }
            if (response == 'true') {
              return
            }
          }

        });
    }
  }
  openCart() {
    if (this.currentRouterPath.includes("Subscribe") && this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 && (this._enrollSession.data.smartOrderDate == undefined || this._enrollSession.data.smartOrderDate == null || this._enrollSession.data.smartOrderDate == '')) {
      this._toastr.error('Please select Smart Order Date', 'error');
      const firstElementWithError = document.querySelector('.dateValidation') as HTMLElement;
      if (firstElementWithError) {
        const scrollPositionAbove = firstElementWithError.offsetTop - 100; // Adjust the value as needed
        window.scrollTo({ top: scrollPositionAbove, behavior: 'smooth' });
      }
      return;
    }
    else
      if (this.currentRouterPath.includes("Subscribe")) {
        this.router.navigate(['/Shipping']);
      }
  }
}
