import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { EnrollSessionService } from '../enrollSession.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestService } from '../rest.service';
import * as _ from 'lodash';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { RgetRegexService } from '../getRegex.service';
import { CalculateRetailInitialOrderService } from '../calculateRetailInitialOrder.service';
import { CalculateRetailSmartOrderService } from '../CalculateRetailSmartOrder.service';
import { secureShippingCheckNoDialogComponent } from 'app/main/Shipping/modals/SecureShippingCheckNoDialog/secureShippingCheckNoDialog.component';
import { CountrychangeComponent } from 'app/main/Shipping/modals/Countrychange/Countrychange.component';
import { AuthService } from '../auth.service';
import { CalculateRetailOrderService } from '../calculateRetailOrder.service';

const trimValidator: ValidatorFn = (control: FormControl) => {
    if (control.value && control.value.startsWith(' ')) {
        return {
            'trimError': { value: 'This field is required' }
        };
    }
};

@Component({
  selector: 'app-review-page-dialog',
  templateUrl: './review-page-dialog.component.html',
  styleUrls: ['./review-page-dialog.component.scss']
})
export class ReviewPageDialogComponent implements OnInit {
  currentRouterPath: any;
  ReviewshippingForm: FormGroup;
  ReviewshippingMethodForm: FormGroup;
  ReviewbillingAddressForm: FormGroup;
  ReviewpaymentTypeForm: FormGroup;
  countriesListAll: any[] = [];
    countriesList: any[] = [];
    USOnlyAutoOrderItems: any[] = [];
    USOnlyOrderItems: any[] = [];
    USOnlyAddonOrderItems: any[] = [];
    stateList: any[] = [];
    ShippingStateList: any[] = [];
    CalculateInitialOrderPrice: any = {};
    billAdd: boolean;
    invalidzip1: boolean = false;
    invalidzip2: boolean = false;
    subscribtionStatus: any;
    selectedStateValue = ""
    SubscriptionProcessType: any;
    siteType: any;
    customerDetail: any;
    authtoken: any;
    showPassword: boolean = false;
    ConfirmPassword: boolean = false;
    public userInfo: any = {};
    public isStateCalling = false;
    PaymentMethodDifferent: boolean = false;
    expirationYearField: any;
    expirationMonthField:any;
    creditCardNumberDisplayField:any
    paymentDetails : any;
    currentYear = new Date().getFullYear();
    currentMonth = new Date().getMonth() + 1;
    monthList: any[] = [];
    yearList: any[] = [];
  /**
    * Constructor
    *
    * @param {FuseConfigService} _fuseConfigService
    * @param {FormBuilder} _formBuilder
    */
  constructor(
    public dialogRef: MatDialogRef<ReviewPageDialogComponent>,
    public _enrollSession: EnrollSessionService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _toastr: ToastrService, public _restService: RestService,
    private _formBuilder: FormBuilder,
    public _RgetRegexService: RgetRegexService,
    private _calculateRetailInitialOrder: CalculateRetailInitialOrderService,
    private _calculateRetailSmartOrder: CalculateRetailSmartOrderService,
    private _matDialog: MatDialog,
    public authService: AuthService,
    public _calculateRetailOrderService: CalculateRetailOrderService,) {
    this.currentRouterPath = this.router.url.split('?')[0];
    let shippingFormData = this._enrollSession.data.RetailOrderSummary?.shippingFormData;
    let billingFormData = this._enrollSession.data.RetailOrderSummary?.billingAddressData;
    let paymentMethodFormData = this._enrollSession.data.RetailOrderSummary?.paymentTypeData;
    this.ReviewshippingForm = this.createShippingForm(shippingFormData);
    this.ReviewshippingMethodForm = this.createShippingMethodForm();
    this.ReviewpaymentTypeForm = this.createPaymentTypeForm(paymentMethodFormData);
    this.ReviewbillingAddressForm = this.createBillingAddressForm(billingFormData);
    console.log("this.data",this.data);
  }

  ngOnInit(): void {
    this._enrollSession.data.RetailOrderSummary.shippingFormData = this._enrollSession.data.RetailOrderSummary.shippingFormData || {};
    this.paymentDetails = JSON.parse(localStorage.getItem('billingInfo')) || {}
        this.expirationYearField = this.paymentDetails?.primaryCreditCardField?.expirationYearField || this.paymentDetails?.secondaryCreditCardField?.expirationYearField;
        this.expirationMonthField = this.paymentDetails?.primaryCreditCardField?.expirationMonthField || this.paymentDetails?.secondaryCreditCardField?.expirationMonthField;
        this.creditCardNumberDisplayField = this.paymentDetails?.primaryCreditCardField?.creditCardNumberDisplayField || this.paymentDetails?.secondaryCreditCardField?.creditCardNumberDisplayField;
        for (let i = 1; i <= 12; i++) {
            this.monthList.push({ key: i, value: i });
        }
        for (let i = 0; i < 20; i++) {
            this.yearList.push({ key: (this.currentYear + i), value: (this.currentYear + i) });
        }
}

  Close(): void {
    this.dialogRef.close();
  }
  onSave(){
    this.ReviewshippingForm.valueChanges.subscribe(selectedValue => {
        this._enrollSession.data.RetailOrderSummary.shippingFormData = selectedValue;
        this._enrollSession.data.RetailOrderSummary.shippingFormData.OldState = this._enrollSession.data.RetailOrderSummary.shippingFormData.State;
        this._enrollSession.data.RetailOrderSummary.shippingFormData.OldCountryCode = this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode;
    });
    this.ReviewshippingForm.controls['ZipCode'].valueChanges.subscribe(value => {
        if (!this.ReviewshippingForm.controls['ZipCode'].touched && this._enrollSession.data.isShippedToAnother) {
            this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode = value;
        }
    });
    this.ReviewshippingMethodForm.valueChanges.subscribe(selectedValue => {
        this._enrollSession.data.RetailEnroll.ShippingMethod = selectedValue.shippingType;
        this._enrollSession.data.RetailEnroll.SecureShipping = selectedValue.SecureShipping;
        this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod);
        this._calculateRetailSmartOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod);
    });
    this.dialogRef.close();
  }

  createShippingForm(shippingFormData): FormGroup {
    return this._formBuilder.group({
        firstName: [shippingFormData.firstName, [Validators.required, trimValidator],],
        lastName: [shippingFormData.lastName, [Validators.required, trimValidator],],
        address1: [shippingFormData.address1, [Validators.required]],
        address2: [shippingFormData.address2],
        CountryCode: [shippingFormData.CountryCode, Validators.required],
        ZipCode: [shippingFormData.ZipCode, Validators.required],
        City: [shippingFormData.City, Validators.required],
        State: [shippingFormData.State, Validators.required]
    });
}
createShippingMethodForm(): FormGroup {
    return this._formBuilder.group({
        shippingType: ['', [Validators.required]],
        SecureShipping: [this._enrollSession.data?.RetailEnroll && this._enrollSession.data.RetailEnroll?.SecureShipping ? this._enrollSession.data.RetailEnroll?.SecureShipping : '', [Validators.required]],
    });
}
createPaymentTypeForm(paymentMethodFormData): FormGroup {
    return this._formBuilder.group({
        primaryPaymentCard: [],
        cardNumber: [paymentMethodFormData?.cardNumber || ''],
        nameOnCard: [paymentMethodFormData?.nameOnCard || '', [Validators.required]],
        expMonth: [paymentMethodFormData?.expMonth || '', [Validators.required]],
        expYear: [paymentMethodFormData?.expYear || '', [Validators.required]],
        securityCode: [paymentMethodFormData?.securityCode || '', [Validators.required, Validators.pattern(/^\d*$/)]]
    })
}

createBillingAddressForm(billingFormData): FormGroup {
    return this._formBuilder.group({
        firstName: [billingFormData.firstName || '', [Validators.required]],
        lastName: [billingFormData.lastName || '', [Validators.required]],
        address1: [billingFormData.address1 || '', [Validators.required]],
        address2: [billingFormData.address2 || ''],
        CountryCode: [billingFormData.CountryCode || '', Validators.required],
        ZipCode: [billingFormData.ZipCode || '', Validators.required],
        City: [billingFormData.City || '', Validators.required],
        State: [billingFormData.State || '', Validators.required]
    });
}

MatchPassword(Password: any, ConfirmPassword: any): any {
    return (customerInfoForm: FormGroup) => {
        const PasswordControl = customerInfoForm.controls[Password];
        const ConfirmPasswordControl = customerInfoForm.controls[ConfirmPassword];
        if (!PasswordControl || !ConfirmPasswordControl) {
            return null;
        }
        if (PasswordControl.value && PasswordControl.value?.length < 6) {

            return customerInfoForm.controls[Password].setErrors({ passwordLength: true });
        }

        if (!ConfirmPasswordControl.value) {
            return customerInfoForm.controls[ConfirmPassword].setErrors({ required: true });
        }

        if (PasswordControl.value && PasswordControl.value !== ConfirmPasswordControl.value) {
            return customerInfoForm.controls[ConfirmPassword].setErrors({ passwordMismatch: true });
        } else {
            return customerInfoForm.controls[ConfirmPassword].setErrors(null);
        }
    };
}
MatchEmail(Email: any, ConfirmEmail: any): any {
    return (customerInfoForm: FormGroup) => {
        const EmailControl = customerInfoForm.controls[Email];
        const ConfirmEmailControl = customerInfoForm.controls[ConfirmEmail];
        if (!EmailControl || !ConfirmEmailControl) {
            return null;
        }

        if (!ConfirmEmailControl.value) {
            return customerInfoForm.controls[ConfirmEmail].setErrors({ required: true });
        }

        if (EmailControl.value && EmailControl.value !== ConfirmEmailControl.value) {
            return customerInfoForm.controls[ConfirmEmail].setErrors({ EmailMismatch: true });
        }
        else {
            return customerInfoForm.controls[ConfirmEmail].setErrors(null);
        }
    };
}
getCountryList(): void {
    const getStatesRequest = { CountryCode: this._enrollSession.data?.RetailOrderSummary?.shippingFormData.CountryCode || 'US' };
    this._restService.GetCountryRegions(getStatesRequest)
        .subscribe((result: any) => {
            try {
                this.countriesListAll = result.countriesField;
                this.countriesList = _.filter(result.countriesField, (item) => {
                    return (item.countryCodeField === 'US' || item.countryCodeField === 'CA');
                });
                this._enrollSession.data.countriesList = this.countriesList ? this.countriesList : '';
                this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode = this._enrollSession.data.RetailOrderSummary?.customerInfoData?.CountryCode || this.customerDetail.MainCountry || result.countriesField[0].countryCodeField;
                this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode = this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode || this.customerDetail.MainCountry || result.countriesField[0].countryCodeField;
                this._enrollSession.data.RetailOrderSummary.billingAddressData.CountryCode = this._enrollSession.data.RetailOrderSummary.billingAddressData.CountryCode || this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode || result.countriesField[0].countryCodeField;
                this.countryChange(this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode,2);
                this.countryChange(this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode,1);
                this.countryChange(this._enrollSession.data.RetailOrderSummary.billingAddressData.CountryCode,3);
            } catch (ex) {
                console.log('ex', ex);

            }
        }
        );
}
countryChange(countryCode,type): void {
    if (['US'].indexOf(countryCode) === -1) {
        _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item, index) => {
            if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                this.USOnlyOrderItems.push(item);
            }
        });
        _.each(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item, index) => {
            if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                this.USOnlyAddonOrderItems.push(item);
            }
        });
        _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item, index) => {
            if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                this.USOnlyAutoOrderItems.push(item);
            }
        });
        if (this.USOnlyOrderItems?.length > 0 || this.USOnlyAutoOrderItems?.length > 0 || this.USOnlyAddonOrderItems?.length > 0) {
            this.popupLoad('countrychange', countryCode, '', 'countrychange');
        }
        else {
            this.getStates(countryCode, type);
        }
    }
    else {
        this.getStates(countryCode, type);
    }
}
getStates(country, type): void {
    this.isStateCalling = true;
    const getStatesRequest = { CountryCode: country };
    this._restService.GetCountryRegions(getStatesRequest)
        .subscribe((result: any) => {
            try {
                if (type === 1) {
                    if (sessionStorage.getItem('temp1')) {
                        this._enrollSession.data.RetailOrderSummary.shippingFormData.TempCountryCode = sessionStorage.getItem('temp1');
                    }
                    this.ShippingStateList = result.regionsField;
                    this._enrollSession.data.ShippingStateList = this.ShippingStateList ? this.ShippingStateList : '';
                    this._enrollSession.data.stateListOnBilling = this._enrollSession.data.stateListOnBilling || result.regionsField;
                    if (this._enrollSession.data.RetailOrderSummary.shippingFormData.TempCountryCode && this._enrollSession.data.RetailOrderSummary.shippingFormData.TempCountryCode !== this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode) {
                        this._enrollSession.data.RetailOrderSummary.shippingFormData.State = ['US', 'CA'].indexOf(country) > -1 ?  result.regionsField[0].regionCodeField : result.regionsField[0].regionCodeField || '';
                        this._enrollSession.data.RetailOrderSummary.shippingFormData.OldState = ['US', 'CA'].indexOf(country) > -1 ? '' : result.regionsField[0].regionCodeField;
                        this._enrollSession.data.RetailOrderSummary.shippingFormData.TempCountryCode = this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode;
                        sessionStorage.setItem('temp1', this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode)
                        this._enrollSession.data.RetailEnroll.ShippingMethod = '';
                    }
                    else {
                        this._enrollSession.data.RetailOrderSummary.shippingFormData.State =  (this._enrollSession.data.RetailOrderSummary.shippingFormData.State ? this._enrollSession.data.RetailOrderSummary.shippingFormData.State : "");
                        this._enrollSession.data.RetailOrderSummary.shippingFormData.OldState = this._enrollSession.data.RetailOrderSummary.shippingFormData.OldState || result.regionsField[0].regionCodeField;
                        sessionStorage.setItem('temp1', this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode);
                    }
                    this._enrollSession.data.RetailOrderSummary.shippingFormData.OldCountryCode = this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode;
                    if (['US', 'CA'].indexOf(this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode) > -1 && ['NE', 'KS'].indexOf(this._enrollSession.data.RetailOrderSummary.shippingFormData.State) > -1) {
                        this.stateChange(this._enrollSession.data.RetailOrderSummary.shippingFormData.State, type);
                    }
                }
                else if(type === 3){
                    this._enrollSession.data.stateList = result.regionsField;
                    this._enrollSession.data.RetailOrderSummary.billingAddressData.State = ['US','CA'].indexOf(country) > -1 ? '' : result.regionsField[0].regionCodeField;
                }
                else {
                    if (sessionStorage.getItem('temp2')) {
                        this._enrollSession.data.RetailOrderSummary.customerInfoData.TempCountryCode = sessionStorage.getItem('temp2');
                    }
                    this.stateList = result.regionsField;
                    this._enrollSession.data.stateList = this.stateList ? this.stateList : '';
                    this._enrollSession.data.stateListOnBilling = this._enrollSession.data.stateListOnBilling || result.regionsField;
                    if (this._enrollSession.data.RetailOrderSummary.customerInfoData.TempCountryCode && this._enrollSession.data.RetailOrderSummary.customerInfoData.TempCountryCode !== this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode) {
                        this._enrollSession.data.RetailOrderSummary.customerInfoData.State = result.regionsField[0].regionCodeField || '';
                        this._enrollSession.data.RetailOrderSummary.customerInfoData.OldState = ['US', 'CA'].indexOf(country) > -1 ? '' : result.regionsField[0].regionCodeField;
                        this._enrollSession.data.RetailOrderSummary.customerInfoData.TempCountryCode = this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode;
                        sessionStorage.setItem('temp2', this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode)
                        this._enrollSession.data.RetailEnroll.ShippingMethod = '';
                    }
                    else {
                        this._enrollSession.data.RetailOrderSummary.customerInfoData.State = this.customerDetail.MainState ? this._enrollSession.data.RetailOrderSummary.customerInfoData.State || this.customerDetail.MainState : (this._enrollSession.data.RetailOrderSummary.customerInfoData.State ? this._enrollSession.data.RetailOrderSummary.customerInfoData.State : "");
                        this._enrollSession.data.RetailOrderSummary.customerInfoData.OldState = this._enrollSession.data.RetailOrderSummary.customerInfoData.OldState || result.regionsField[0].regionCodeField;
                        sessionStorage.setItem('temp2', this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode);
                    }
                    this._enrollSession.data.RetailOrderSummary.customerInfoData.OldCountryCode = this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode;
                    if (['US', 'CA'].indexOf(this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode) > -1 && ['NE', 'KS'].indexOf(this._enrollSession.data.RetailOrderSummary.customerInfoData.State) > -1) {
                        if (type==2) {
                            this.stateChange(this._enrollSession.data.RetailOrderSummary.customerInfoData.State, type);
                        }
                        else
                        {
                            this.stateChange(this._enrollSession.data.RetailOrderSummary.shippingFormData.State, type);
                        }
                    }
                    this._enrollSession.data.stateListOnBilling = result.regionsField;
                    if (this.billAdd) {

                        this._enrollSession.data.RetailOrderSummary.shippingFormData.BillState = ['US', 'CA'].indexOf(country) > -1 ? '' : result.regionsField[0].regionCodeField;
                    }

                }
                this.isStateCalling = false;
                this.stateChange(this._enrollSession.data.RetailOrderSummary.customerInfoData.State, 2);
            } catch (ex) {
                console.log('ex', ex);

            }
        }
        );
}
stateChange(stateCode, type): void {
    if(!this._enrollSession.data.isShippedToAnother && type == 2){
        this._enrollSession.data.RetailOrderSummary.shippingFormData.State = this._enrollSession.data.RetailOrderSummary.customerInfoData.State;
    }

    if (type==1 && ['US'].indexOf(this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode) > -1 && ['NE', 'KS'].indexOf(stateCode) > -1) {
        _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item, index) => {
            if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                this.USOnlyOrderItems.push(item);
            }
        });
        _.each(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item, index) => {
            if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                this.USOnlyAddonOrderItems.push(item);
            }
        });
        _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item, index) => {
            if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                this.USOnlyAutoOrderItems.push(item);
            }
        });
        if (this.USOnlyOrderItems?.length > 0 || this.USOnlyAutoOrderItems?.length > 0 || this.USOnlyAddonOrderItems?.length > 0) {
            this.popupLoad('countrychange', '', type, 'statechange');
        }
        else {
            if (parseInt(type, 10) !== 2) {
                this.removeMarketSpecificItems();
            }
            this.checkCalculateOrder();
        }
    }
    else  if (type==2 && ['US'].indexOf(this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode) > -1 && ['NE', 'KS'].indexOf(stateCode) > -1) {
        _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item, index) => {
            if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                this.USOnlyOrderItems.push(item);
            }
        });
        _.each(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item, index) => {
            if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                this.USOnlyAddonOrderItems.push(item);
            }
        });
        _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item, index) => {
            if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                this.USOnlyAutoOrderItems.push(item);
            }
        });
        if (this.USOnlyOrderItems?.length > 0 || this.USOnlyAutoOrderItems?.length > 0 || this.USOnlyAddonOrderItems?.length > 0) {
            this.popupLoad('countrychange', '', type, 'statechange');
        }
        else {
            if (parseInt(type, 10) !== 2) {
                this.removeMarketSpecificItems();
            }
            this.checkCalculateOrder();
        }
    }
    else {
        if(!this.isStateCalling){
            this.checkCalculateOrder();
        }
        
    }
}
removeMarketSpecificItems(): void {
    if (this.USOnlyOrderItems?.length > 0 || this.USOnlyAutoOrderItems?.length > 0 || this.USOnlyAddonOrderItems?.length > 0) {
        _.each(this.USOnlyOrderItems, (item, index) => {
            this.removeInitOrder({ Quantity: item.Quantity, parentId: item.ItemCode, true: Boolean });
        });
        _.each(this.USOnlyAutoOrderItems, (item, index) => {
            this.removeSmartOrder({ Quantity: item.Quantity, parentId: item.ItemCode, true: Boolean });
        });
        _.each(this.USOnlyAddonOrderItems, (item, index) => {
            this.removeAddonOrder({ Quantity: item.Quantity, parentId: item.ItemCode, true: Boolean });
        });

        if (this.USOnlyOrderItems?.length > 0 || this.USOnlyAddonOrderItems?.length > 0) {
            this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
                this.CalculateInitialOrderPrices(result);
            });
        }
        if (this.USOnlyAutoOrderItems?.length > 0) {
            this._calculateRetailSmartOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod);
        }
        this.USOnlyOrderItems = [];
        this.USOnlyAutoOrderItems = [];
        this.USOnlyAddonOrderItems = [];
    }
    else {
        this.calculateOrder();
    }
}
isAnyItemInCart = () => {
    return (this._enrollSession.data.RetailOrderSummary.Enrollpack && this._enrollSession.data.RetailOrderSummary.Enrollpack?.length > 0 || this._enrollSession.data.RetailOrderSummary.InitialOrders && this._enrollSession.data.RetailOrderSummary.InitialOrders?.length > 0 || this._enrollSession.data.RetailOrderSummary.AddOnOrders && this._enrollSession.data.RetailOrderSummary.AddOnOrders?.length > 0)
}
eligibleForRetailDeliveryFees = () => {
    return (this._enrollSession.data.RetailOrderSummary.shippingFormData.State == 'CO' && this.isAnyItemInCart() && this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.Tax > 0);
}
checkCalculateOrder(): void {
    this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailOrderSummary.shippingFormData;
    if (!this._enrollSession.data.RetailEnroll.webAlias && this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode) {
        this.getCustomerSite(this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode, 1);
    }
    this._enrollSession.data.RetailOrderSummary.shippingFormData.OldState = _.cloneDeep(this._enrollSession.data.RetailOrderSummary.shippingFormData.State);
    this._calculateRetailInitialOrder.calculate(this._enrollSession.data?.ShippingMethods[0]?.ShipMethodID).then((result) => {
        this.CalculateInitialOrderPrices(result);
        this.checkShipMethod(this._enrollSession.data?.ShippingMethods[0]?.ShipMethodID);
        this._enrollSession && this._enrollSession.data && this._enrollSession.data.ShippingMethods && this._enrollSession.data.ShippingMethods[0].ShipMethodID
    });
    this._calculateRetailSmartOrder.calculate(this._enrollSession.data?.ShippingMethods[0]?.ShipMethodID).then((result) => {
    });
}
CalculateInitialOrderPrices(items): void {
    _.each(items.CustomDetails, (item) => {
        if (item.ParentItemCode && item.ParentItemCode !== null && item.ParentItemCode !== 'null') {
            this.CalculateInitialOrderPrice[item.ItemCode + '_' + item.ParentItemCode] = item.PriceEach;
        }
        else {
            this.CalculateInitialOrderPrice[item.ItemCode] = item.PriceEach;
        }

        //adding Retail Delivery Fees for Colorado State
        if (item.ItemCode == "CO_DeliveryFee" && this.eligibleForRetailDeliveryFees()) {
            this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees = {
                price: item.PriceEach,
                description: 'Retail Delivery Fees',
                quantity: item.Quantity,
                image: 'assets/images/image/retail-delivery-fees.png'
            };
        } else {
            this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees = {};
        }
    });
}
calculateOrder(): void {
    this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailOrderSummary.shippingFormData;
    this._calculateRetailInitialOrder.calculate(this._enrollSession.data?.ShippingMethods[0].ShipMethodID).then((result) => {
        this.ReviewshippingMethodForm.value.shippingType = this._enrollSession.data?.ShippingMethods[0].ShipMethodID || this._enrollSession.data.ShippingMethods[0].ShipMethodID;
        this.CalculateInitialOrderPrices(result);
        this.checkShipMethod(this.ReviewshippingMethodForm.value.shippingType);
    });
    this._calculateRetailSmartOrder.calculate(this.ReviewshippingMethodForm.value.shippingType).then((result) => {
    });

}
checkShipMethod(shipMethodId): void {
    var isExist = _.filter(this._enrollSession.data.ShippingMethods, function (item) {
        return parseInt(item.ShipMethodID, 10) == parseInt(shipMethodId, 10);
    });
    if (isExist?.length == 0) {
        this.ReviewshippingMethodForm.value.shippingType = this._enrollSession.data.ShippingMethods[0].ShipMethodID;
    }


}
removeInitOrder(Value: any): any {
    this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
    for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.InitialOrders?.length; arrIndex++) {
        if ((!this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ParentId ||
            this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ParentId === Value.parentId)
            && this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ItemCode === Value.parentId) {
            this._enrollSession.data.RetailOrderSummary.InitialOrders.splice(arrIndex, 1);
        }
    }

    this._enrollSession.data.InitialOrdersSubTotal = 0;
    this._enrollSession.data.InitialOrdersQVTotal = 0;
    _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
        this._enrollSession.data.InitialOrdersSubTotal = this._enrollSession.data.InitialOrdersSubTotal + (item.Quantity * item.Price);
        this._enrollSession.data.InitialOrdersQVTotal = this._enrollSession.data.InitialOrdersQVTotal + (item.Quantity * item.BV);

    });

    this._enrollSession.data.RetailOrderSummary.InitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {

        return item.ParentId !== Value.parentId;
    });

    this._enrollSession.data.TotalItemLength = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
        return !item.ParentId || (parseInt(item.ParentId, 10) === parseInt(item.ItemCode, 10));
    });
    const filterInitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
        return item.ParentId !== undefined;
    });
    this._enrollSession.data.InitialOrdersSubItems = _.groupBy(filterInitialOrders, 'ParentId');
    if (!Value.check) {
        this.CheckRegisterType();
        this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
            this.CalculateInitialOrderPrices(result);
        });
    }
}
removeAddonOrder(Value: any): any {
    this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
    for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.AddOnOrders?.length; arrIndex++) {
        if ((!this._enrollSession.data.RetailOrderSummary.AddOnOrders[arrIndex].ParentId ||
            this._enrollSession.data.RetailOrderSummary.AddOnOrders[arrIndex].ParentId === Value.parentId)
            && this._enrollSession.data.RetailOrderSummary.AddOnOrders[arrIndex].ItemCode === Value.parentId) {
            this._enrollSession.data.RetailOrderSummary.AddOnOrders.splice(arrIndex, 1);
        }
    }

    this._enrollSession.data.AddOnOrdersSubTotal = 0;
    this._enrollSession.data.AddOnOrdersQVTotal = 0;
    _.each(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {
        this._enrollSession.data.AddOnOrdersSubTotal = this._enrollSession.data.AddOnOrdersSubTotal + (item.Quantity * item.Price);
        this._enrollSession.data.AddOnOrdersQVTotal = this._enrollSession.data.AddOnOrdersQVTotal + (item.Quantity * item.BV);

    });

    this._enrollSession.data.RetailOrderSummary.AddOnOrders = _.filter(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {

        return item.ParentId !== Value.parentId;
    });

    this._enrollSession.data.TotalItemLength = _.filter(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {
        return !item.ParentId || (parseInt(item.ParentId, 10) === parseInt(item.ItemCode, 10));
    });
    const filterAddOnOrders = _.filter(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {
        return item.ParentId !== undefined;
    });
    this._enrollSession.data.AddOnOrdersSubItems = _.groupBy(filterAddOnOrders, 'ParentId');
    if (!Value.check) {
        this.CheckRegisterType();
        this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
            this.CalculateInitialOrderPrices(result);
        });
    }
}
removeSmartOrder(Value: any): void {
    this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
    for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.SmartOrders?.length; arrIndex++) {
        if ((!this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ParentId ||
            this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ParentId === Value.parentId)
            && this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ItemCode === Value.parentId) {
            this._enrollSession.data.RetailOrderSummary.SmartOrders.splice(arrIndex, 1);
        }
    }

    this._enrollSession.data.RetailOrderSummary.SmartOrders = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
        return item.ParentId !== Value.parentId;
    });
    const filterResult = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
        return item.ParentId !== undefined;
    });
    this._enrollSession.data.SmartOrderSubItems = _.groupBy(filterResult, 'ParentId');
    if (!Value.check) {
        this._calculateRetailSmartOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod);
    }
    this._enrollSession.data.SmartOrdersSubTotal = 0;
    this._enrollSession.data.SmartOrderBVTotal = 0;
    _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
        this._enrollSession.data.SmartOrdersSubTotal = this._enrollSession.data.SmartOrdersSubTotal + (item.Quantity * item.Price);
        this._enrollSession.data.SmartOrderBVTotal = this._enrollSession.data.SmartOrderBVTotal + (item.Quantity * item.BV);
    });
}
CheckRegisterType(): void {
    this._enrollSession.data.isBeautyWellness = false;
    this._enrollSession.data.RetailEnroll.ShippingMethod = '';
    if (this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber === 'NP') {
        this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber = '';

    }
    if (this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 !== 'HealthProfessional') {
        if (this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'Nonprofit' ||
            this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'Influencer' ||
            this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'BeautyWellnessProfessional') {
            this._enrollSession.data.FileUploaded = this._enrollSession.data.FileUploaded || false;
        }
        else {
            this._enrollSession.data.FileUploaded = false;
        }
        this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
            this.CalculateInitialOrderPrices(result);
        });
    }
    else if (this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'HealthProfessional' && this._enrollSession.data.InitialOrdersQVTotal < 500) {
        this._enrollSession.data.FileUploaded = false;
        this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
            this.CalculateInitialOrderPrices(result);
        });

    }
    else {
        this._enrollSession.data.FileUploaded = false;
        this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
            this.CalculateInitialOrderPrices(result);
        });
    }

}
popupLoad(modaltype: any, countrycode: any = '', type: any = 0, action: string = ''): any {
    let componentName: any;
    if (modaltype === 'countrychange') {
        componentName = CountrychangeComponent;
    }
    const dialogRef = this._matDialog.open(componentName, {
        width: '630px',
        panelClass: []
    });
    dialogRef.afterClosed().subscribe(result => {
        if (modaltype === 'countrychange') {
            if (action === 'countrychange') {
                this.ChangeCountryCloseAction(result, countrycode);
            }
            else if (action === 'statechange') {
                this.ChangeCountryStateCloseAction(result, type);
            }
        }

    });
}
ChangeCountryCloseAction(modalaction, countrycode): any {
    if (modalaction === 'No') {
        this.USOnlyOrderItems = [];
        this.USOnlyAutoOrderItems = [];
        this._enrollSession.data.RetailEnroll.CountryCode = 'US';
        if (this._enrollSession.data.RetailEnroll.OldCountryCode !== 'US') {
            this.getStates(this._enrollSession.data.RetailEnroll.CountryCode, 1);
        }
    }
    else {
        this.getStates(countrycode, 1);
    }
}
ChangeCountryStateCloseAction(modalaction, type): any {
    if (modalaction === 'No') {
        if (type && parseInt(type, 10) === 1) {
            this._enrollSession.data.RetailEnroll.State = this.stateList[0].regionCodeField;
            this._enrollSession.data.RetailEnroll.OldState = this.stateList[0].regionCodeField;
        }
        else {
            this._enrollSession.data.RetailEnroll.State = _.cloneDeep(this._enrollSession.data.RetailEnroll.OldState);
        }
        this.USOnlyOrderItems = [];
        this.USOnlyAutoOrderItems = [];
        this.USOnlyAddonOrderItems = [];
        this.checkCalculateOrder();
    }
    else {
        this.removeMarketSpecificItems();
        this.checkCalculateOrder();
    }
}
regexValidateZip1(event): void {
    const billingAddressForm = this.ReviewbillingAddressForm ? this.ReviewbillingAddressForm.getRawValue() : {};
        const regxExp = this._RgetRegexService.getRegex(billingAddressForm.CountryCode);
        const filter = new RegExp(regxExp);
        if (filter.test(event.target.value)) {
            this.invalidzip1 = false;
        } else if (event.target.value && (event.target.value.indexOf(' ') !== 0)) {
            this.invalidzip1 = true;
        }
        else {
            this.invalidzip1 = false;
        }
}
regexValidateZip2(event): void {
    const shippingForm = this.ReviewshippingForm ? this.ReviewshippingForm.getRawValue() : {};
    const regxExp = this._RgetRegexService.getRegex(shippingForm.CountryCode);
    const filter = new RegExp(regxExp);
    if (filter.test(event.target.value)) {
        this.invalidzip2 = false;
    } else if (event.target.value && (event.target.value.indexOf(' ') !== 0)) {
        this.invalidzip2 = true;
    }
    else {
        this.invalidzip2 = false;
    }
}
getCustomerSite(webAlias, type): void {
    console.log("webalias",webAlias,type);
    try {
        if (webAlias) {
            const request = '?WebAlias=' + webAlias;
            this._restService.GetCustomerSite(request).subscribe((result: any) => {
                if (type === 1) {
                    if (result.Data) {
                        this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
                        this._enrollSession.data.RetailEnroll.SponsorId = result.Data.customerIDField;
                        this._enrollSession.data.RetailEnroll.EnrollerId = result.Data.customerIDField;
                        this._enrollSession.data.RetailEnroll.webAliasField = result.Data.webAliasField;
                        this._enrollSession.data.CustomerWebAlias = result.Data.webAliasField;
                        this._enrollSession.data.ValidGiftCardCode = this._enrollSession.data.RetailEnroll.webAliasField;
                        this._enrollSession.data.RetailEnroll.webAlias = result.Data.firstNameField + ' ' + result.Data.lastNameField;
                        this._enrollSession.data.ReferredBy = result.Data.webAliasField;
                        this._enrollSession.data.RetailEnroll.isValidwebalias = true;
                    } else {
                        this._enrollSession.data.RetailEnroll.isValidwebalias = 'false';
                        this._toastr.error('Invalid WebAlias.', 'Error');
                        this._enrollSession.data.CustomerWebAlias = '';
                        this._enrollSession.data.ReferredBy = '';
                        this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode = '';

                    }
                }
                else {
                    if (result.Data) {
                        this._enrollSession.data.ReferredByGiftCard = '';
                        this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode = '';
                        this._toastr.error('This Referral Code is not Available', 'Error');
                    }
                    else {
                        
                    }
                }
            }, (err) => {
                console.log(err);
                this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode = '';
                this._toastr.error('Somthing is wrong Please try again.', 'Error');
            }, () => {

            });
        }
    } catch (e) {
        this._toastr.error('Somthing is wrong Please try again.', 'Error');
    }
}
secureShippingMethod(checkSecureShipping?:boolean)
{
    console.log("secureshipping",checkSecureShipping);
    if (checkSecureShipping) {
        localStorage.setItem("SecureShipping",'true');
    } else {
        localStorage.setItem("SecureShipping", 'false');
        const dialogRef = this._matDialog.open(secureShippingCheckNoDialogComponent, {
            width: '500px',
            maxWidth: '500px',
            height: '400px',
            panelClass: '',
            disableClose: true,
            data: {

            }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }
}

differentPaymentMethod(type) {
    if(type=='paymentDifferent'){
        this.PaymentMethodDifferent = true;
    }
    else {
        this.PaymentMethodDifferent = false;
    }
   
}

}
