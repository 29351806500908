<div class="dialog-content-wrapper" id="two-way-wrapper SecureShippingDialog">
    <mat-toolbar matDialogTitle class="m-0">
    </mat-toolbar>
    <div style="display:flex" class="pop-up-background">
      <div class="pop-up-div">
            <h2 class="pop-up-headline f-s-18 m-t-20 p-20">
                You have chosen not to protect your order with Signature Required. 
                Please note our replacement policy states that if you choose not to protect your order 
                with Signature Required and your order is confirmed delivered by FedEx, 
                a replacement order will not be sent for orders not received.</h2>
        <div class="pop-up-button-div m-t-10">
            <button mat-button class="pop-up-button w-button w--current"
            cdkFocusInitial [mat-dialog-close]="true">Close</button></div>
      </div>
    </div>
</div>