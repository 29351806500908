import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/shared/auth.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { RestService } from 'app/shared/rest.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-shopify-password',
  templateUrl: './reset-shopify-password.component.html',
  styleUrls: ['./reset-shopify-password.component.scss']
})
export class ResetShopifyPasswordComponent implements OnInit {
  customerLoginForm: FormGroup;
  public userInfo: any = {};
  resetPasswordStatus: boolean;
  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    public _enrollSession: EnrollSessionService,
    public router: Router, private _auth: AuthService,
    private _formBuilder: FormBuilder,
    public _toastr: ToastrService,
    public _restService: RestService) {
    this.customerLoginForm = this.createCustomerLoginForm();
  }
  ngOnInit(): void {
   this.resetPasswordStatus=false;
  }
  createCustomerLoginForm(): FormGroup {
    return this._formBuilder.group({
      userName: ['', [Validators.required]],
      password: ['', [Validators.required]]
    })
  }

  SendLink()
  {
    if (this.userInfo.userName && (this.userInfo.userName != '')) {
      let req = "?email=" + (this.userInfo.userName);
      this._restService.GetResetPasswordLink(req).subscribe(
        (res: any) => {
          this.resetPasswordStatus=true;
        },
        error => {
          this._toastr.error('Error', 'Oops Something Went Wrong');
        }
      )
    }
   
  }
}
