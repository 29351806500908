import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { RgetRegexService } from '../../shared/getRegex.service';
import { FormBuilder, FormGroup, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { RestService } from '../../shared/rest.service';
import * as _ from 'lodash';
import { CalculateRetailInitialOrderService } from 'app/shared/calculateRetailInitialOrder.service';
import { CalculateRetailSmartOrderService } from 'app/shared/CalculateRetailSmartOrder.service';
import * as moment from 'moment';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CalculateAutoOrderService } from 'app/shared/calculateAutoOrder.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { MatDialog } from '@angular/material/dialog';
import { CountrychangeComponent } from './modals/Countrychange/Countrychange.component';
import { startOfDay, addWeeks, subWeeks, startOfMonth, endOfWeek, subMonths, addMonths, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, getMonth } from 'date-fns';
import { CalendarMonthViewDay, CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/auth.service';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { secureShippingCheckNoDialogComponent } from './modals/SecureShippingCheckNoDialog/secureShippingCheckNoDialog.component';
import { ShippingWebAliasCheckDialogComponent } from './modals/ShippingWebAliasCheckDialog/ShippingWebAliasCheckDialog.component';
type CalendarPeriod = 'month';

const trimValidator: ValidatorFn = (control: FormControl) => {
    if (control.value && control.value.startsWith(' ')) {
        return {
            'trimError': { value: 'This field is required' }
        };
    }
};
@Component({
    selector: 'Shipping',
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    templateUrl: './Shipping.component.html',
    styleUrls: ['./Shipping.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ShippingComponent implements OnInit {
    referredForm: FormGroup;
    routPath: any = "Checkout";
    customerInfoForm: FormGroup;
    shippingForm: FormGroup;
    shippingMethodForm: FormGroup;
    accountInfoForm: FormGroup;
    countriesListAll: any[] = [];
    countriesList: any[] = [];
    USOnlyAutoOrderItems: any[] = [];
    USOnlyOrderItems: any[] = [];
    USOnlyAddonOrderItems: any[] = [];
    stateList: any[] = [];
    ShippingStateList: any[] = [];
    CalculateInitialOrderPrice: any = {};
    billAdd: boolean;
    invalidzip1: boolean = false;
    invalidzip2: boolean = false;
    currentYear = new Date().getFullYear();
    viewDate: any = this._enrollSession.data.smartOrderDate ? this._enrollSession.data.smartOrderDate : addMonths(new Date(), 1);
    view: CalendarView = CalendarView.Month;
    CalendarView = CalendarView;
    refresh: Subject<any> = new Subject();
    activeDayIsOpen: boolean = true;
    datesDisabled: any;
    _selectedDay: any;
    AutoShipStartDate: any;
    prevBtnDisabled: boolean = false;
    LoggedInUserData: any;
    nextBtnDisabled: boolean = false;
    checked: boolean = true;
    minDate: Date = subMonths(new Date(), 0);
    maxDate: Date = addMonths(new Date(), 1);
    disableDaysOfMonth = [22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    subscribtionStatus: any;
    selectedStateValue = ""
    SubscriptionProcessType: any;
    siteType: any;
    customerDetail: any;
    authtoken: any;
    showPassword: boolean = false;
    ConfirmPassword: boolean = false;
    IsCompanyName: boolean = false;
    public hasLower: boolean = false;
    public hasUpper: boolean = false;
    public hasNum: boolean = false;
    public hasSpecial: boolean = false;
    public hasLength: boolean = false;
    public userInfo: any = {};
    public isStateCalling = false;
    constructor(
        private _formBuilder: FormBuilder,
        public _RgetRegexService: RgetRegexService,
        private _restService: RestService,
        public _enrollSession: EnrollSessionService,
        private _calculateRetailInitialOrder: CalculateRetailInitialOrderService,
        private _calculateRetailSmartOrder: CalculateRetailSmartOrderService,
        private _matDialog: MatDialog,
        public _calculateAutoOrder: CalculateAutoOrderService,
        private router: Router,
        private _toastr: ToastrService,
        public authService: AuthService,
        public _sharedMethodService: SharedMethodService
    ) {
        this.siteType = sessionStorage.getItem("siteType");
        if ((this._enrollSession.data?.RetailOrderSummary == undefined) || (this._enrollSession.data?.RetailOrderSummary && this._enrollSession.data?.RetailOrderSummary?.Enrollpack?.length == 0 && this._enrollSession.data?.RetailOrderSummary?.Enrollpack == undefined && this._enrollSession.data?.RetailOrderSummary?.Enrollpack?.length == 0) && (this.siteType && this.siteType != null)) {
            if (this._enrollSession.data?.RetailOrderSummary?.Enrollpack?.length == 0) {
                this._toastr.warning("Please select an item ", '');
            }
            this.router.navigate(['/AllProducts']);
        }
        if ((this._enrollSession.data?.RetailOrderSummary == undefined) || this._enrollSession.data.RetailOrderSummary?.InitialOrders == undefined || (this._enrollSession.data?.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary?.InitialOrders?.length == 0) && (this.siteType && (this.siteType == null || this.siteType == ''))) {
            this._toastr.warning("Please select an item", '');
            this.router.navigate(['/AllProducts']);
        }
        this.referredForm = this.createReferredForm();
        this._enrollSession.data.RetailEnroll.CurrencyCode = 'USD';
        this._enrollSession.data.RetailEnroll.AgreeTerms = true;
        this._enrollSession.data.RetailEnroll.CardType = this._enrollSession.data.RetailEnroll.CardType || 'VISA';
        this.CalculateInitialOrderPrice = {};
        this.customerDetail = JSON.parse(localStorage.getItem('LoggedInUserData')) || {};
        this.IsCompanyName = this.customerDetail.FirstName == '' && this.customerDetail.LastName == '' && this.customerDetail.Company != '' ? true : false;
        this.customerInfoForm = this.IsCompanyName ? this.createCompanyInfoForm(this.customerDetail) : this.createCustomerInfoForm(this.customerDetail);
        this.shippingForm = this.createShippingForm(this.customerDetail);
        this.shippingMethodForm = this.createShippingMethodForm();
        this.accountInfoForm = this.createAccountInfoForm(this.customerDetail);
        this.authtoken = localStorage.getItem('authtoken');
    }
    createReferredForm(): FormGroup {
        return this._formBuilder.group({
            referralCode: ['', Validators.required]
        })
    }
    ngOnInit(): void {
        this._enrollSession.data.isShippedToAnother = this._enrollSession.data.isShippedToAnother || false;
        this._enrollSession.data.RetailOrderSummary.customerInfoData = this._enrollSession.data.RetailOrderSummary.customerInfoData || {};
        this._enrollSession.data.RetailOrderSummary.accountInfoData = this._enrollSession.data.RetailOrderSummary.accountInfoData || {};
        this.customerInfoForm.valueChanges.subscribe(selectedValue => {
            this._enrollSession.data.RetailOrderSummary.customerInfoData = selectedValue;
            if (!this._enrollSession.data.isShippedToAnother) {
                this.removeShippingFormValidation();
            }
            this.accountInfoForm.patchValue({
                loginName: this._enrollSession.data.RetailOrderSummary.customerInfoData.Email || this.customerInfoForm.get('Email').value
            });
        });
        this._enrollSession.data.RetailOrderSummary.referredFormData = this._enrollSession.data.RetailOrderSummary.referredFormData || {};
        this.referredForm.valueChanges.subscribe(selectedValue => {
            this._enrollSession.data.RetailOrderSummary.referredFormData = selectedValue;
        });
        this.accountInfoForm.valueChanges.subscribe(selectedValue => {
            this._enrollSession.data.RetailOrderSummary.accountInfoData = selectedValue;
        });
        this._enrollSession.data.RetailOrderSummary.shippingFormData = this._enrollSession.data.RetailOrderSummary.shippingFormData || {};

        this.shippingForm.valueChanges.subscribe(selectedValue => {
            this._enrollSession.data.RetailOrderSummary.shippingFormData = selectedValue;
            this._enrollSession.data.RetailOrderSummary.shippingFormData.OldState = this._enrollSession.data.RetailOrderSummary.shippingFormData.State;
            this._enrollSession.data.RetailOrderSummary.shippingFormData.OldCountryCode = this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode;
        });
        this.shippingForm.controls['ZipCode'].valueChanges.subscribe(value => {
            if (!this.shippingForm.controls['ZipCode'].touched && this._enrollSession.data.isShippedToAnother) {
                this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode = value;
            }
        });
        this.shippingMethodForm.valueChanges.subscribe(selectedValue => {
            this._enrollSession.data.RetailEnroll.ShippingMethod = selectedValue.shippingType;
            this._enrollSession.data.RetailEnroll.SecureShipping = selectedValue.SecureShipping;
            this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod);
            this._calculateRetailSmartOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod);
        });

        if (this._enrollSession.data.RetailOrderSummary.customerInfoData && Object.keys(this._enrollSession.data.RetailOrderSummary.customerInfoData)?.length !== 0) {
            var customerInfo = this._enrollSession.data.RetailOrderSummary.customerInfoData;
            if(this.IsCompanyName)
            {
                customerInfo.Company = this.customerDetail.Company;
                this.customerInfoForm.patchValue({
                    CompanyName: customerInfo.Company,
                    Email: customerInfo.Email,
                    ConfirmEmail: customerInfo.ConfirmEmail,
                    Phone: customerInfo.Phone || customerInfo.MobilePhone,
                    smsOptIn: customerInfo.smsOptIn,
                    emailOptIn: customerInfo.emailOptIn,
                    keepUpdate: customerInfo.keepUpdate,
                    address1: customerInfo.address1,
                    address2: customerInfo.address2,
                    CountryCode: customerInfo.CountryCode,
                    ZipCode: customerInfo.ZipCode,
                    City: customerInfo.City,
                    State: customerInfo.State
                });
            }
            else
            {
                this.customerInfoForm.patchValue({
                    FirstName: customerInfo.FirstName,
                    LastName: customerInfo.LastName,
                    Email: customerInfo.Email,
                    ConfirmEmail: customerInfo.ConfirmEmail,
                    Phone: customerInfo.Phone || customerInfo.MobilePhone,
                    smsOptIn: customerInfo.smsOptIn,
                    emailOptIn: customerInfo.emailOptIn,
                    keepUpdate: customerInfo.keepUpdate,
                    address1: customerInfo.address1,
                    address2: customerInfo.address2,
                    CountryCode: customerInfo.CountryCode,
                    ZipCode: customerInfo.ZipCode,
                    City: customerInfo.City,
                    State: customerInfo.State
                });
            }
        }
        else {
            this.customerInfoForm.patchValue({
                keepUpdate: true
            })
        }
        if (this._enrollSession.data.RetailOrderSummary.accountInfoData && Object.keys(this._enrollSession.data.RetailOrderSummary.accountInfoData)?.length !== 0) {
            var accountInfoData = this._enrollSession.data.RetailOrderSummary.accountInfoData;
            this.accountInfoForm.patchValue({
                loginName: accountInfoData.loginName
            });
        }
        if (!_.isEmpty(this._enrollSession.data.RetailOrderSummary.shippingFormData)) {
            let shippingInfo = this._enrollSession.data.RetailOrderSummary.shippingFormData;
            this.shippingForm.patchValue({
                firstName: shippingInfo.firstName || this.customerDetail.FirstName,
                lastName: shippingInfo.lastName || this.customerDetail.LastName,
                address1: shippingInfo.address1 || this.customerDetail.MainAddress1,
                address2: shippingInfo.address2 || this.customerDetail.MainAddress2,
                CountryCode: shippingInfo.CountryCode || this.customerDetail.MainCountry,
                ZipCode: shippingInfo.ZipCode || this.customerDetail.MainZip,
                City: shippingInfo.City || this.customerDetail.MainCity,
                State: shippingInfo.State || this.customerDetail.MainState
            })
        }
        let shippingMethodInfo = _.isEmpty(this._enrollSession.data.RetailOrderSummary.shippingMethodFormData) ? this._enrollSession.data.ShippingMethods[0].ShipMethodID : this._enrollSession.data.RetailEnroll.ShippingMethod;
        let shipInfo = shippingMethodInfo.toString();
        this.shippingMethodForm.get('shippingType').setValue(shipInfo);
        this.getCountryList();
        if (this.eligibleForRetailDeliveryFees()) {
            this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees = {
                price: 0.27,
                description: 'Retail Delivery Fees',
                quantity: 1,
                image: 'assets/images/image/retail-delivery-fees.png'
            };
        } else {
            this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees = {};
        }
        this.customerInfoForm.get("smsOptIn").setValue(true);
        this.customerInfoForm.get("emailOptIn").setValue(true);
        this._sharedMethodService.isOpen = true;
        this.SubscriptionProcessType = localStorage.getItem('SubscriptionProcessType') || "AutoOrderProcessTy1";
        localStorage.setItem("SubscriptionProcessType", this.SubscriptionProcessType);
        if (!this._enrollSession.data.isShippedToAnother) {
            this.removeShippingFormValidation();
        }
        if (this._enrollSession.data.RetailOrderSummary.referredFormData) {
            let referredInfo = this._enrollSession.data.RetailOrderSummary.referredFormData;
            this.referredForm.patchValue({
                referralCode: referredInfo.referralCode ? referredInfo.referralCode : this._enrollSession.data.webAlias
            });
            this.getCustomerSite(this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode, 1);
        }
    }
    createCustomerInfoForm(customerDetail): FormGroup {
        return this._formBuilder.group({
            FirstName: [customerDetail.FirstName, [Validators.required]],
            LastName: [customerDetail.LastName, [Validators.required]],
            address1: [customerDetail.MainAddress1, [Validators.required]],
            address2: [customerDetail.MainAddress2],
            CountryCode: [customerDetail.MainCountry, Validators.required],
            ZipCode: [customerDetail.MainZip, Validators.required],
            City: [customerDetail.MainCity, Validators.required],
            State: [customerDetail.MainState, Validators.required],
            Email: [customerDetail.Email, [Validators.required, Validators.email, Validators.pattern(/^([\w-\.+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,15}|[0-9]{1,3})(\]?)$/)]],
            ConfirmEmail: [customerDetail.Email, [Validators.required, Validators.pattern(/^([\w-\.+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,15}|[0-9]{1,3})(\]?)$/)]],
            Phone: [customerDetail.MobilePhone || customerDetail.Phone, [Validators.required, Validators.pattern(/^\d{3}-?\d{3}-?\d{4}$/)]],
            smsOptIn: [''],
            emailOptIn: [''],
        },
        {
            validator: [ this.MatchEmail('Email', 'ConfirmEmail')]
        }
        );
    }
    createCompanyInfoForm(customerDetail): FormGroup {
        return this._formBuilder.group({
            CompanyName: [customerDetail.Company, [Validators.required]],
            address1: [customerDetail.MainAddress1, [Validators.required]],
            address2: [customerDetail.MainAddress2],
            CountryCode: [customerDetail.MainCountry, Validators.required],
            ZipCode: [customerDetail.MainZip, Validators.required],
            City: [customerDetail.MainCity, Validators.required],
            State: [customerDetail.MainState, Validators.required],
            Email: [customerDetail.Email, [Validators.required, Validators.email, Validators.pattern(/^([\w-\.+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,15}|[0-9]{1,3})(\]?)$/)]],
            ConfirmEmail: [customerDetail.Email, [Validators.required, Validators.email, Validators.pattern(/^([\w-\.+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,15}|[0-9]{1,3})(\]?)$/)]],
            Phone: [customerDetail.MobilePhone || customerDetail.Phone, [Validators.required, Validators.pattern(/^\d{3}-?\d{3}-?\d{4}$/)]],
            smsOptIn: [''],
            emailOptIn: [''],
        },
        {
            validator: [ this.MatchEmail('Email', 'ConfirmEmail')]
        }
        );
    }
    validatePassword(password?: string) {

        const lowercaseRegex = new RegExp("(?=.*[a-z])"); // has at least one lower case letter
        if (lowercaseRegex.test(password)) {
            this.hasLower = true;
        } else {
            this.hasLower = false;
        }

        const uppercaseRegex = new RegExp("(?=.*[A-Z])"); //has at least one upper case letter
        if (uppercaseRegex.test(password)) {
            this.hasUpper = true;
        } else {
            this.hasUpper = false;
        }

        const numRegex = new RegExp("(?=.*\\d)"); // has at least one number
        if (numRegex.test(password)) {
            this.hasNum = true;
        } else {
            this.hasNum = false;
        }

        const specialcharRegex = new RegExp("[!@#$%^&*(),.?\":{}|<>]");
        if (specialcharRegex.test(password)) {
            this.hasSpecial = true;
        } else {
            this.hasSpecial = false;
        }

        const passwordLength = password?.length;
        if (passwordLength >= 8) {
            this.hasLength = true;
        } else {
            this.hasLength = false;
        }


    }
    togglePasswordShowHide() {
        this.showPassword = !this.showPassword;
    }
    togglePasswordShowHide2() {
        this.ConfirmPassword = !this.ConfirmPassword;
    }
    createShippingForm(customerDetail): FormGroup {
        return this._formBuilder.group({
            firstName: [customerDetail.FirstName, [Validators.required, trimValidator],],
            lastName: [customerDetail.LastName, [Validators.required, trimValidator],],
            address1: [customerDetail.MainAddress1, [Validators.required]],
            address2: [customerDetail.MainAddress2],
            CountryCode: [customerDetail.MainCountry, Validators.required],
            ZipCode: [customerDetail.MainZip, Validators.required],
            City: [customerDetail.MainCity, Validators.required],
            State: [customerDetail.MainState, Validators.required]
        });
    }
    createShippingMethodForm(): FormGroup {
        return this._formBuilder.group({
            shippingType: ['', [Validators.required]],
            SecureShipping: [this._enrollSession.data?.RetailEnroll && this._enrollSession.data.RetailEnroll?.SecureShipping ? this._enrollSession.data.RetailEnroll?.SecureShipping : '', [Validators.required]],
        });
    }
    createAccountInfoForm(customerDetail): FormGroup {
        return this._formBuilder.group({
            loginName: [customerDetail.Email, [Validators.required]],
            Password: ['', [Validators.required]],
            ConfirmPassword: ['', [Validators.required]],
        },
        {
            validator: [this.MatchPassword('Password', 'ConfirmPassword')]
        })
    }
    MatchPassword(Password: any, ConfirmPassword: any): any {
        return (customerInfoForm: FormGroup) => {
            const PasswordControl = customerInfoForm.controls[Password];
            const ConfirmPasswordControl = customerInfoForm.controls[ConfirmPassword];
            if (!PasswordControl || !ConfirmPasswordControl) {
                return null;
            }
            if (PasswordControl.value && PasswordControl.value?.length < 6) {

                return customerInfoForm.controls[Password].setErrors({ passwordLength: true });
            }

            if (!ConfirmPasswordControl.value) {
                return customerInfoForm.controls[ConfirmPassword].setErrors({ required: true });
            }

            if (PasswordControl.value && PasswordControl.value !== ConfirmPasswordControl.value) {
                return customerInfoForm.controls[ConfirmPassword].setErrors({ passwordMismatch: true });
            } else {
                return customerInfoForm.controls[ConfirmPassword].setErrors(null);
            }
        };
    }
    MatchEmail(Email: any, ConfirmEmail: any): any {
        return (customerInfoForm: FormGroup) => {
            const EmailControl = customerInfoForm.controls[Email];
            const ConfirmEmailControl = customerInfoForm.controls[ConfirmEmail];
            if (!EmailControl || !ConfirmEmailControl) {
                return null;
            }

            if (!ConfirmEmailControl.value) {
                return customerInfoForm.controls[ConfirmEmail].setErrors({ required: true });
            }

            if (EmailControl.value && EmailControl.value !== ConfirmEmailControl.value) {
                return customerInfoForm.controls[ConfirmEmail].setErrors({ EmailMismatch: true });
            }
            else {
                return customerInfoForm.controls[ConfirmEmail].setErrors(null);
            }
        };
    }
    getCountryList(): void {
        const getStatesRequest = { CountryCode: this.customerDetail.MainCountry || 'US' };
        this._restService.GetCountryRegions(getStatesRequest)
            .subscribe((result: any) => {
                try {
                    this.countriesListAll = result.countriesField;
                    this.countriesList = _.filter(result.countriesField, (item) => {
                        return (item.countryCodeField === 'US' || item.countryCodeField === 'CA');
                    });
                    this._enrollSession.data.countriesList = this.countriesList ? this.countriesList : '';
                    this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode = this._enrollSession.data.RetailOrderSummary?.customerInfoData?.CountryCode || this.customerDetail.MainCountry || result.countriesField[0].countryCodeField;
                    this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode = this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode || this.customerDetail.MainCountry || result.countriesField[0].countryCodeField;
                    this.countryChange(this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode,2);
                    if(this._enrollSession.data.isShippedToAnother){
                        this.countryChange(this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode,1);
                    }
                } catch (ex) {
                    console.log('ex', ex);

                }
            }
            );
    }
    countryChange(countryCode,type): void {
        if (['US'].indexOf(countryCode) === -1) {
            _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item, index) => {
                if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                    this.USOnlyOrderItems.push(item);
                }
            });
            _.each(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item, index) => {
                if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                    this.USOnlyAddonOrderItems.push(item);
                }
            });
            _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item, index) => {
                if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                    this.USOnlyAutoOrderItems.push(item);
                }
            });
            if (this.USOnlyOrderItems?.length > 0 || this.USOnlyAutoOrderItems?.length > 0 || this.USOnlyAddonOrderItems?.length > 0) {
                this.popupLoad('countrychange', countryCode, type, 'countrychange');
            }
            else {
                this.getStates(countryCode, type);
            }
        }
        else {
            this.getStates(countryCode, type);
        }
    }
    getStates(country, type): void {
        this.isStateCalling = true;
        const getStatesRequest = { CountryCode: country };
        this._restService.GetCountryRegions(getStatesRequest)
            .subscribe((result: any) => {
                try {
                    if (type !== 2) {
                        if (sessionStorage.getItem('temp1')) {
                            this._enrollSession.data.RetailOrderSummary.shippingFormData.TempCountryCode = sessionStorage.getItem('temp1');
                        }
                        this.ShippingStateList = result.regionsField;
                        this._enrollSession.data.ShippingStateList = this.ShippingStateList ? this.ShippingStateList : '';
                        this._enrollSession.data.stateListOnBilling = this._enrollSession.data.stateListOnBilling || result.regionsField;
                        if (this._enrollSession.data.RetailOrderSummary.shippingFormData.TempCountryCode && this._enrollSession.data.RetailOrderSummary.shippingFormData.TempCountryCode !== this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode) {
                            this._enrollSession.data.RetailOrderSummary.shippingFormData.State = ['US', 'CA'].indexOf(country) > -1 ?  result.regionsField[0].regionCodeField : result.regionsField[0].regionCodeField || '';
                            this._enrollSession.data.RetailOrderSummary.shippingFormData.OldState = ['US', 'CA'].indexOf(country) > -1 ? '' : result.regionsField[0].regionCodeField;
                            this._enrollSession.data.RetailOrderSummary.shippingFormData.TempCountryCode = this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode;
                            sessionStorage.setItem('temp1', this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode)
                            this._enrollSession.data.RetailEnroll.ShippingMethod = '';
                        }
                        else {
                            this._enrollSession.data.RetailOrderSummary.shippingFormData.State =  (this._enrollSession.data.RetailOrderSummary.shippingFormData.State ? this._enrollSession.data.RetailOrderSummary.shippingFormData.State : "");
                            this._enrollSession.data.RetailOrderSummary.shippingFormData.OldState = this._enrollSession.data.RetailOrderSummary.shippingFormData.OldState || result.regionsField[0].regionCodeField;
                            sessionStorage.setItem('temp1', this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode);
                        }
                        this._enrollSession.data.RetailOrderSummary.shippingFormData.OldCountryCode = this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode;
                        if (['US', 'CA'].indexOf(this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode) > -1 && ['NE', 'KS'].indexOf(this._enrollSession.data.RetailOrderSummary.shippingFormData.State) > -1) {
                            this.stateChange(this._enrollSession.data.RetailOrderSummary.shippingFormData.State, type);
                        }
                    }
                    else {
                        if (sessionStorage.getItem('temp2')) {
                            this._enrollSession.data.RetailOrderSummary.customerInfoData.TempCountryCode = sessionStorage.getItem('temp2');
                        }
                        this.stateList = result.regionsField;
                        this._enrollSession.data.stateList = this.stateList ? this.stateList : '';
                        this._enrollSession.data.stateListOnBilling = this._enrollSession.data.stateListOnBilling || result.regionsField;
                        if (this._enrollSession.data.RetailOrderSummary.customerInfoData.TempCountryCode && this._enrollSession.data.RetailOrderSummary.customerInfoData.TempCountryCode !== this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode) {
                            this._enrollSession.data.RetailOrderSummary.customerInfoData.State = result.regionsField[0].regionCodeField || '';
                            this._enrollSession.data.RetailOrderSummary.customerInfoData.OldState = ['US', 'CA'].indexOf(country) > -1 ? '' : result.regionsField[0].regionCodeField;
                            this._enrollSession.data.RetailOrderSummary.customerInfoData.TempCountryCode = this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode;
                            sessionStorage.setItem('temp2', this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode)
                            this._enrollSession.data.RetailEnroll.ShippingMethod = '';
                        }
                        else {
                            this._enrollSession.data.RetailOrderSummary.customerInfoData.State = this.customerDetail.MainState ? this._enrollSession.data.RetailOrderSummary.customerInfoData.State || this.customerDetail.MainState : (this._enrollSession.data.RetailOrderSummary.customerInfoData.State ? this._enrollSession.data.RetailOrderSummary.customerInfoData.State : "");
                            this._enrollSession.data.RetailOrderSummary.customerInfoData.OldState = this._enrollSession.data.RetailOrderSummary.customerInfoData.OldState || result.regionsField[0].regionCodeField;
                            sessionStorage.setItem('temp2', this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode);
                        }
                        this._enrollSession.data.RetailOrderSummary.customerInfoData.OldCountryCode = this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode;
                        if (['US', 'CA'].indexOf(this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode) > -1 && ['NE', 'KS'].indexOf(this._enrollSession.data.RetailOrderSummary.customerInfoData.State) > -1) {
                            if (type==2) {
                                this.stateChange(this._enrollSession.data.RetailOrderSummary.customerInfoData.State, type);
                            }
                            else
                            {
                                this.stateChange(this._enrollSession.data.RetailOrderSummary.shippingFormData.State, type);
                            }
                        }
                        this._enrollSession.data.stateListOnBilling = result.regionsField;
                        if (this.billAdd) {

                            this._enrollSession.data.RetailOrderSummary.shippingFormData.BillState = ['US', 'CA'].indexOf(country) > -1 ? '' : result.regionsField[0].regionCodeField;
                        }

                    }
                    this.isStateCalling = false;
                    this.stateChange(this._enrollSession.data.RetailOrderSummary.customerInfoData.State, 2);
                } catch (ex) {
                    console.log('ex', ex);

                }
            }
            );
    }
    stateChange(stateCode, type): void {
        if(!this._enrollSession.data.isShippedToAnother && type == 2){
            this._enrollSession.data.RetailOrderSummary.shippingFormData.State = this._enrollSession.data.RetailOrderSummary.customerInfoData.State;
        }

        if (type==1 && ['US'].indexOf(this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode) > -1 && ['NE', 'KS'].indexOf(stateCode) > -1) {
            _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item, index) => {
                if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                    this.USOnlyOrderItems.push(item);
                }
            });
            _.each(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item, index) => {
                if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                    this.USOnlyAddonOrderItems.push(item);
                }
            });
            _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item, index) => {
                if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                    this.USOnlyAutoOrderItems.push(item);
                }
            });
            if (this.USOnlyOrderItems?.length > 0 || this.USOnlyAutoOrderItems?.length > 0 || this.USOnlyAddonOrderItems?.length > 0) {
                this.popupLoad('countrychange', '', type, 'statechange');
            }
            else {
                if (parseInt(type, 10) !== 2) {
                    this.removeMarketSpecificItems();
                }
                this.checkCalculateOrder();
            }
        }
        else  if (type==2 && ['US'].indexOf(this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode) > -1 && ['NE', 'KS'].indexOf(stateCode) > -1) {
            _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item, index) => {
                if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                    this.USOnlyOrderItems.push(item);
                }
            });
            _.each(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item, index) => {
                if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                    this.USOnlyAddonOrderItems.push(item);
                }
            });
            _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item, index) => {
                if (item.IsUSOnly === true && (item.ParentId === item.ItemCode || !item.ParentId)) {
                    this.USOnlyAutoOrderItems.push(item);
                }
            });
            if (this.USOnlyOrderItems?.length > 0 || this.USOnlyAutoOrderItems?.length > 0 || this.USOnlyAddonOrderItems?.length > 0) {
                this.popupLoad('countrychange', '', type, 'statechange');
            }
            else {
                if (parseInt(type, 10) !== 2) {
                    this.removeMarketSpecificItems();
                }
                this.checkCalculateOrder();
            }
        }
        else {
            if(!this.isStateCalling){
                this.checkCalculateOrder();
            }
            
        }
    }
    removeMarketSpecificItems(): void {
        if (this.USOnlyOrderItems?.length > 0 || this.USOnlyAutoOrderItems?.length > 0 || this.USOnlyAddonOrderItems?.length > 0) {
            _.each(this.USOnlyOrderItems, (item, index) => {
                this.removeInitOrder({ Quantity: item.Quantity, parentId: item.ItemCode, true: Boolean });
            });
            _.each(this.USOnlyAutoOrderItems, (item, index) => {
                this.removeSmartOrder({ Quantity: item.Quantity, parentId: item.ItemCode, true: Boolean });
            });
            _.each(this.USOnlyAddonOrderItems, (item, index) => {
                this.removeAddonOrder({ Quantity: item.Quantity, parentId: item.ItemCode, true: Boolean });
            });

            if (this.USOnlyOrderItems?.length > 0 || this.USOnlyAddonOrderItems?.length > 0) {
                this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
                    this.CalculateInitialOrderPrices(result);
                });
            }
            if (this.USOnlyAutoOrderItems?.length > 0) {
                this._calculateRetailSmartOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod);
            }
            this.USOnlyOrderItems = [];
            this.USOnlyAutoOrderItems = [];
            this.USOnlyAddonOrderItems = [];
        }
        else {
            this.calculateOrder();
        }
    }
    isAnyItemInCart = () => {
        return (this._enrollSession.data.RetailOrderSummary.Enrollpack && this._enrollSession.data.RetailOrderSummary.Enrollpack?.length > 0 || this._enrollSession.data.RetailOrderSummary.InitialOrders && this._enrollSession.data.RetailOrderSummary.InitialOrders?.length > 0 || this._enrollSession.data.RetailOrderSummary.AddOnOrders && this._enrollSession.data.RetailOrderSummary.AddOnOrders?.length > 0)
    }
    eligibleForRetailDeliveryFees = () => {
        return (this._enrollSession.data.RetailOrderSummary.shippingFormData.State == 'CO' && this.isAnyItemInCart() && this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.Tax > 0);
    }
    checkCalculateOrder(): void {
        this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailOrderSummary.shippingFormData;
        if (!this._enrollSession.data.RetailEnroll.webAlias && this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode) {
            this.getCustomerSite(this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode, 1);
        }
        this._enrollSession.data.RetailOrderSummary.shippingFormData.OldState = _.cloneDeep(this._enrollSession.data.RetailOrderSummary.shippingFormData.State);
        this._calculateRetailInitialOrder.calculate(this.shippingMethodForm.value.shippingType).then((result) => {
            this.CalculateInitialOrderPrices(result);
            this.checkShipMethod(this.shippingMethodForm.value.shippingType);
            this._enrollSession && this._enrollSession.data && this._enrollSession.data.ShippingMethods && this._enrollSession.data.ShippingMethods[0].ShipMethodID && this.shippingMethodForm.get('shippingType').setValue(this._enrollSession.data.ShippingMethods[0].ShipMethodID.toString());
        });
        this._calculateRetailSmartOrder.calculate(this.shippingMethodForm.value.shippingType).then((result) => {
        });
    }
    CalculateInitialOrderPrices(items): void {
        _.each(items.CustomDetails, (item) => {
            if (item.ParentItemCode && item.ParentItemCode !== null && item.ParentItemCode !== 'null') {
                this.CalculateInitialOrderPrice[item.ItemCode + '_' + item.ParentItemCode] = item.PriceEach;
            }
            else {
                this.CalculateInitialOrderPrice[item.ItemCode] = item.PriceEach;
            }

            //adding Retail Delivery Fees for Colorado State
            if (item.ItemCode == "CO_DeliveryFee" && this.eligibleForRetailDeliveryFees()) {
                this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees = {
                    price: item.PriceEach,
                    description: 'Retail Delivery Fees',
                    quantity: item.Quantity,
                    image: 'assets/images/image/retail-delivery-fees.png'
                };
            } else {
                this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees = {};
            }
        });
    }
    calculateOrder(): void {
        this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailOrderSummary.shippingFormData;
        this._calculateRetailInitialOrder.calculate(this.shippingMethodForm.value.shippingType).then((result) => {
            this.shippingMethodForm.value.shippingType = this.shippingMethodForm.value.shippingType || this._enrollSession.data.ShippingMethods[0].ShipMethodID;
            this.CalculateInitialOrderPrices(result);
            this.checkShipMethod(this.shippingMethodForm.value.shippingType);
        });
        this._calculateRetailSmartOrder.calculate(this.shippingMethodForm.value.shippingType).then((result) => {
        });

    }
    checkShipMethod(shipMethodId): void {
        var isExist = _.filter(this._enrollSession.data.ShippingMethods, function (item) {
            return parseInt(item.ShipMethodID, 10) == parseInt(shipMethodId, 10);
        });
        if (isExist?.length == 0) {
            this.shippingMethodForm.value.shippingType = this._enrollSession.data.ShippingMethods[0].ShipMethodID;
        }


    }
    removeInitOrder(Value: any): any {
        this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
        for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.InitialOrders?.length; arrIndex++) {
            if ((!this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ParentId ||
                this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ParentId === Value.parentId)
                && this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ItemCode === Value.parentId) {
                this._enrollSession.data.RetailOrderSummary.InitialOrders.splice(arrIndex, 1);
            }
        }

        this._enrollSession.data.InitialOrdersSubTotal = 0;
        this._enrollSession.data.InitialOrdersQVTotal = 0;
        _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            this._enrollSession.data.InitialOrdersSubTotal = this._enrollSession.data.InitialOrdersSubTotal + (item.Quantity * item.Price);
            this._enrollSession.data.InitialOrdersQVTotal = this._enrollSession.data.InitialOrdersQVTotal + (item.Quantity * item.BV);

        });

        this._enrollSession.data.RetailOrderSummary.InitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {

            return item.ParentId !== Value.parentId;
        });

        this._enrollSession.data.TotalItemLength = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            return !item.ParentId || (parseInt(item.ParentId, 10) === parseInt(item.ItemCode, 10));
        });
        const filterInitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            return item.ParentId !== undefined;
        });
        this._enrollSession.data.InitialOrdersSubItems = _.groupBy(filterInitialOrders, 'ParentId');
        if (!Value.check) {
            this.CheckRegisterType();
            this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
                this.CalculateInitialOrderPrices(result);
            });
        }
    }
    removeAddonOrder(Value: any): any {
        this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
        for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.AddOnOrders?.length; arrIndex++) {
            if ((!this._enrollSession.data.RetailOrderSummary.AddOnOrders[arrIndex].ParentId ||
                this._enrollSession.data.RetailOrderSummary.AddOnOrders[arrIndex].ParentId === Value.parentId)
                && this._enrollSession.data.RetailOrderSummary.AddOnOrders[arrIndex].ItemCode === Value.parentId) {
                this._enrollSession.data.RetailOrderSummary.AddOnOrders.splice(arrIndex, 1);
            }
        }

        this._enrollSession.data.AddOnOrdersSubTotal = 0;
        this._enrollSession.data.AddOnOrdersQVTotal = 0;
        _.each(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {
            this._enrollSession.data.AddOnOrdersSubTotal = this._enrollSession.data.AddOnOrdersSubTotal + (item.Quantity * item.Price);
            this._enrollSession.data.AddOnOrdersQVTotal = this._enrollSession.data.AddOnOrdersQVTotal + (item.Quantity * item.BV);

        });

        this._enrollSession.data.RetailOrderSummary.AddOnOrders = _.filter(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {

            return item.ParentId !== Value.parentId;
        });

        this._enrollSession.data.TotalItemLength = _.filter(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {
            return !item.ParentId || (parseInt(item.ParentId, 10) === parseInt(item.ItemCode, 10));
        });
        const filterAddOnOrders = _.filter(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {
            return item.ParentId !== undefined;
        });
        this._enrollSession.data.AddOnOrdersSubItems = _.groupBy(filterAddOnOrders, 'ParentId');
        if (!Value.check) {
            this.CheckRegisterType();
            this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
                this.CalculateInitialOrderPrices(result);
            });
        }
    }
    removeSmartOrder(Value: any): void {
        this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
        for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.SmartOrders?.length; arrIndex++) {
            if ((!this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ParentId ||
                this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ParentId === Value.parentId)
                && this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ItemCode === Value.parentId) {
                this._enrollSession.data.RetailOrderSummary.SmartOrders.splice(arrIndex, 1);
            }
        }

        this._enrollSession.data.RetailOrderSummary.SmartOrders = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
            return item.ParentId !== Value.parentId;
        });
        const filterResult = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
            return item.ParentId !== undefined;
        });
        this._enrollSession.data.SmartOrderSubItems = _.groupBy(filterResult, 'ParentId');
        if (!Value.check) {
            this._calculateRetailSmartOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod);
        }
        this._enrollSession.data.SmartOrdersSubTotal = 0;
        this._enrollSession.data.SmartOrderBVTotal = 0;
        _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
            this._enrollSession.data.SmartOrdersSubTotal = this._enrollSession.data.SmartOrdersSubTotal + (item.Quantity * item.Price);
            this._enrollSession.data.SmartOrderBVTotal = this._enrollSession.data.SmartOrderBVTotal + (item.Quantity * item.BV);
        });
    }
    CheckRegisterType(): void {
        this._enrollSession.data.isBeautyWellness = false;
        this._enrollSession.data.RetailEnroll.ShippingMethod = '';
        if (this._enrollSession.data.RetailOrderSummary?.paymentTypeData?.cardNumber === 'NP') {
            this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber = '';

        }
        if (this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 !== 'HealthProfessional') {
            if (this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'Nonprofit' ||
                this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'Influencer' ||
                this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'BeautyWellnessProfessional') {
                this._enrollSession.data.FileUploaded = this._enrollSession.data.FileUploaded || false;
            }
            else {
                this._enrollSession.data.FileUploaded = false;
            }
            this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
                this.CalculateInitialOrderPrices(result);
            });
        }
        else if (this._enrollSession.data.RetailEnroll.CommissionDDConfirmation1 === 'HealthProfessional' && this._enrollSession.data.InitialOrdersQVTotal < 500) {
            this._enrollSession.data.FileUploaded = false;
            this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
                this.CalculateInitialOrderPrices(result);
            });

        }
        else {
            this._enrollSession.data.FileUploaded = false;
            this._calculateRetailInitialOrder.calculate(this._enrollSession.data.RetailEnroll.ShippingMethod).then((result) => {
                this.CalculateInitialOrderPrices(result);
            });
        }

    }
    popupLoad(modaltype: any, countrycode: any = '', type: any = 0, action: string = ''): any {
        let componentName: any;
        if (modaltype === 'countrychange') {
            componentName = CountrychangeComponent;
        }
        const dialogRef = this._matDialog.open(componentName, {
            width: '630px',
            panelClass: []
        });
        dialogRef.afterClosed().subscribe(result => {
            if (modaltype === 'countrychange') {
                if (action === 'countrychange') {
                    this.ChangeCountryCloseAction(result, countrycode,type);
                }
                else if (action === 'statechange') {
                    this.ChangeCountryStateCloseAction(result, type);
                }
            }

        });
    }
    ChangeCountryCloseAction(modalaction, countrycode, type): any {
        if (modalaction === 'No') {
            this.USOnlyOrderItems = [];
            this.USOnlyAutoOrderItems = [];
            type == 1 ? this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode = 'US' : this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode = 'US';
            if (this._enrollSession.data.RetailOrderSummary.shippingFormData.OldCountryCode !== 'US') {
                this.getStates('US', type);
            }
        }
        else {
            this.getStates(countrycode, type);
            this.removeMarketSpecificItems();
        }
    }
    ChangeCountryStateCloseAction(modalaction, type): any {
        if (modalaction === 'No') {
            if (type && parseInt(type, 10) === 1) {
                this._enrollSession.data.RetailEnroll.State = this.stateList[0].regionCodeField;
                this._enrollSession.data.RetailEnroll.OldState = this.stateList[0].regionCodeField;
            }
            else {
                this._enrollSession.data.RetailEnroll.State = _.cloneDeep(this._enrollSession.data.RetailEnroll.OldState);
            }
            this.USOnlyOrderItems = [];
            this.USOnlyAutoOrderItems = [];
            this.USOnlyAddonOrderItems = [];
            this.checkCalculateOrder();
        }
        else {
            this.removeMarketSpecificItems();
            this.checkCalculateOrder();
        }
    }
    regexValidateZip1(event): void {
        const customerInfoForm = this.customerInfoForm ? this.customerInfoForm.getRawValue() : {};
        const regxExp = this._RgetRegexService.getRegex(customerInfoForm.CountryCode);
        const filter = new RegExp(regxExp);
        if (filter.test(event.target.value)) {
            this.invalidzip1 = false;
        } else if (event.target.value && (event.target.value.indexOf(' ') !== 0)) {
            this.invalidzip1 = true;
        }
        else {
            this.invalidzip1 = false;
        }
    }
    regexValidateZip2(event): void {
        const shippingForm = this.shippingForm ? this.shippingForm.getRawValue() : {};
        const regxExp = this._RgetRegexService.getRegex(shippingForm.CountryCode);
        const filter = new RegExp(regxExp);
        if (filter.test(event.target.value)) {
            this.invalidzip2 = false;
        } else if (event.target.value && (event.target.value.indexOf(' ') !== 0)) {
            this.invalidzip2 = true;
        }
        else {
            this.invalidzip2 = false;
        }
    }
    dateIsValid(date: Date): boolean {
        return moment().month() + 1 == date.getMonth() && date.getTime() > new Date().getTime() && (this.disableDaysOfMonth.indexOf(date.getDate()) == -1);
    }
    beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
        body.forEach(day => {
            if (!this.dateIsValid(day.date)) {
                day.cssClass = 'disabled-date';
            }
            const _selectedDay = moment(day.date).format('MM/DD/YYYY') === this._enrollSession.data.smartOrderDate ? day : "";
            if (_selectedDay) {
                _selectedDay.cssClass = 'cal-selected';
            }
        });
    }
    getFirstAndThirdWed(year, month) {
        let currmonth = (parseInt(month, 10) + 1);
        let arrdays = [];
        let dateinmonth = moment().daysInMonth();
        while (dateinmonth) {
            var current = moment().date(dateinmonth);
            arrdays.push(current);
            dateinmonth--;
        }

        var enabledDatesArr = [];
        for (var y = year; y <= 2030; y++) {
            for (var j = currmonth; j <= 12; j++) {
                //enabledDatesArr.push(moment(j + '/1/' + y).format('MM-DD-YYYY'));
                for (var i = 22; i <= 31; i++) {
                    var dat = j + '/' + i + '/' + y;
                    enabledDatesArr.push(moment(dat).format('MM-DD-YYYY'));
                }
            }
        }
        arrdays.forEach(element => {
            if (moment(element._d).isSameOrBefore(moment())) {
                enabledDatesArr.push(moment(element._d).format('MM-DD-YYYY'));
            }
        });
        return enabledDatesArr;
    }
    changeDate(date: Date): void {
        this.viewDate = date;
        this.dateOrViewChanged();
    }
    monthIsValid(date: Date): boolean {
        return date >= this.minDate && date <= this.maxDate;
    }
    dateOrViewChanged(): void {
        this.prevBtnDisabled = !this.monthIsValid(
            this.endOfPeriod(this.viewDate, this.subPeriod('month', this.viewDate, 1))
        );
        this.nextBtnDisabled = !this.monthIsValid(
            this.startOfPeriod(this.viewDate, this.addPeriod('month', this.viewDate, 1))
        );
        if (this.viewDate < this.minDate) {
            this.changeDate(this.minDate);
        } else if (this.viewDate > this.maxDate) {
            this.changeDate(this.maxDate);
        }
    }
    increment() {
        this.changeDate(this.addPeriod('month', this.viewDate, 1));
    }
    decrement() {
        this.changeDate(this.subPeriod('month', this.viewDate, 1));
    }
    dayClicked({ date, }: { date: Date; }): void {
        var maxMonth = getMonth(this.maxDate);
        if ((getMonth(date) && getMonth(date) <= maxMonth) || (getMonth(date) == 0 && getMonth(date) <= maxMonth)) {
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true)) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = moment(date).format('MM/DD/YYYY');
            this._enrollSession.data.smartOrderDate = this.viewDate;
            this.AutoShipStartDate = this.viewDate;
            localStorage.setItem("AutoShipStartDate", JSON.stringify(this.AutoShipStartDate));
        }
    }
    startOfPeriod(period: CalendarPeriod, date: Date): Date {
        return {
            day: startOfDay,
            month: startOfMonth,
        }['month'](date);
    }
    endOfPeriod(period: CalendarPeriod, date: Date): Date {
        return {
            day: endOfDay,
            week: endOfWeek,
            month: endOfMonth,
        }['month'](date);
    }
    addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
        return {
            day: addDays,
            week: addWeeks,
            month: addMonths,
        }['month'](date, amount);
    }
    subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
        return {
            day: subDays,
            week: subWeeks,
            month: subMonths,
        }['month'](date, amount);
    }
    EmailRegistered(emailData): void {
        let customExistinguser = JSON.parse(sessionStorage.getItem('CustomExistingCustomerType18&11'));
        if (!this.authtoken && !this.customerInfoForm.controls.Email['errors'] && !this.customerInfoForm.controls.ConfirmEmail['errors'] && this._enrollSession.data.RetailOrderSummary.customerInfoData.Email && ((this._enrollSession.data.CustomerID === null || this._enrollSession.data.CustomerID === '' || this._enrollSession.data.CustomerID === undefined) || (this._enrollSession.data.customertype == 11 || this._enrollSession.data.customertype == 18)||(customExistinguser && customExistinguser?.Customer && (Object.keys(customExistinguser.Customer)?.length !== 0)))) {
            const mailrequest = '?email=' + this._enrollSession.data.RetailOrderSummary.customerInfoData.Email;
            this._restService.IsEmailRegistered(mailrequest).subscribe((result: any) => {
                if (!result) {
                    this._toastr.error('Email Already Registered', 'Error');
                    this.customerInfoForm.get("Email").reset();
                } else {
                    this.accountInfoForm.patchValue({
                        loginName: this._enrollSession.data.RetailOrderSummary.customerInfoData.Email
                    });
                    this.customExistingCustomerFunctionality();
                }
            })
        }
    }
    customExistingCustomerFunctionality() {
        this._restService.GetCustomersByEmail(this._enrollSession.data.RetailOrderSummary.customerInfoData.Email).subscribe((result: any) => {
            if (result.Customer && result.Customer.customerIDField > 0 && (result.Customer.customerTypeField == 18 || result.Customer.customerTypeField == 11)) {
                this._enrollSession.data.CustomerID = result.Customer.customerIDField;
                this._enrollSession.data.customertype = result.Customer.customerTypeField;
                sessionStorage.setItem('CustomExistingCustomerType18&11', JSON.stringify(result));
                let enrollerId = result.Customer.enrollerIDField;
                if (enrollerId > 0 && this._enrollSession.data.RetailEnroll.EnrollerId > 0 && enrollerId != this._enrollSession.data.RetailEnroll.EnrollerId) {
                    const dialogRef = this._matDialog.open(ShippingWebAliasCheckDialogComponent, {
                        width: '500px',
                        maxWidth: '500px',
                        height: '400px',
                        panelClass: '',
                        disableClose: true,
                        data: {
                        }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                    });
                }
            }
        })
    }
    processTypeChange(value) {
        localStorage.setItem("SubscriptionProcessType", value);
        if (value === 'AutoOrderProcessTy1') {
            this._sharedMethodService.processType = 'Always Ship';
        }
        else if (value === 'AutoOrderProcessTy2') {
            this._sharedMethodService.processType = 'Backup Order';
        }
    }
    OpenDesktopCart() {
        this._sharedMethodService.isOpen = true;
    }
    ValidatePhoneNumber(): void {
        if ((this.authtoken && this.isExistPhoneNumber()) || !this.authtoken) {
            if (!this.customerInfoForm.controls['Phone'].errors && this.customerInfoForm.controls['Phone'].status == 'VALID') {
                let phone = this.customerInfoForm.get('Phone').value ?? "";
                if (phone && phone.split("").filter(s => s != "-").length == 10) {
                    phone = phone.split("").filter(s => s != "-").join("");
                    this._restService.ValidatePhoneNumber(phone)
                        .subscribe((res: any) => {
                            if (res && res.Status == 1) {
                                if (res.Data == false) {
                                    this._toastr.error('Phone Number already registered', 'Error');
                                    this.customerInfoForm.get('Phone').reset();
                                } else {
                                    this.customerInfoForm.get('Phone').setValue(this._AddDashesInPhone(phone));
                                }
                            }
                        })
                }
            }
        }
    }
    isExistPhoneNumber(): boolean {
        return (!this.customerDetail.Phone || this.customerDetail.Phone == null) && (!this.customerDetail.MobilePhone || this.customerDetail.MobilePhone == null)
    }
    private _AddDashesInPhone(phoneNumber: string): string {
        try {
            if (phoneNumber && phoneNumber.split("").filter(s => s != "-").length == 10) {
                let phoneCharArray = phoneNumber.split("").filter(s => s != "-");
                let dashAddedPhone = phoneCharArray.slice(0, 3).join("") + "-" + phoneCharArray.slice(3, 6).join("") + "-" + phoneCharArray.slice(6).join("");
                return dashAddedPhone ? dashAddedPhone : phoneNumber;
            } else {
                return phoneNumber;
            }
        } catch (error) {
            return phoneNumber;
        }
    }
    shipToAnotherAdd(isShippedToAnother) {
        if (isShippedToAnother === false) {
            this.getStates(this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode,1);
            this._enrollSession.data.isShippedToAnother = true;
            this._enrollSession.addValidators(this.shippingForm, "firstName");
            this._enrollSession.addValidators(this.shippingForm, "lastName");
            this._enrollSession.addValidators(this.shippingForm, "address1");
            this._enrollSession.addValidators(this.shippingForm, "CountryCode");
            this._enrollSession.addValidators(this.shippingForm, "ZipCode");
            this._enrollSession.addValidators(this.shippingForm, "City");
            this._enrollSession.addValidators(this.shippingForm, "State");
            let shippingInfo = this._enrollSession.data.RetailOrderSummary.customerInfoData;
            this.shippingForm.patchValue({
                firstName: '',
                lastName: '',
                address1: '',
                address2: '',
                CountryCode: shippingInfo.CountryCode,
                ZipCode: '',
                City: '',
                State: shippingInfo.State
            });
        }
        else {
            this._enrollSession.data.isShippedToAnother = false;
            this.removeShippingFormValidation();
            this.checkCalculateOrder();
        }
    }
    removeShippingFormValidation()
    {
        this._enrollSession.removeValidators(this.shippingForm, "firstName");
        this._enrollSession.removeValidators(this.shippingForm, "lastName");
        this._enrollSession.removeValidators(this.shippingForm, "address1");
        this._enrollSession.removeValidators(this.shippingForm, "CountryCode");
        this._enrollSession.removeValidators(this.shippingForm, "ZipCode");
        this._enrollSession.removeValidators(this.shippingForm, "City");
        this._enrollSession.removeValidators(this.shippingForm, "State");
        let shippingInfo = this._enrollSession.data.RetailOrderSummary.customerInfoData;
        this.shippingForm.patchValue({
            firstName: shippingInfo.FirstName,
            lastName: shippingInfo.LastName,
            address1: shippingInfo.address1,
            address2: shippingInfo.address2,
            CountryCode: shippingInfo.CountryCode,
            ZipCode: shippingInfo.ZipCode,
            City: shippingInfo.City,
            State: shippingInfo.State
        });
    }
    getCustomerSite(webAlias, type): void {
        try {
            if (webAlias) {
                const request = '?WebAlias=' + webAlias;
                this._restService.GetCustomerSite(request).subscribe((result: any) => {
                    if (type === 1) {
                        if (result.Data) {
                            this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
                            this._enrollSession.data.RetailEnroll.SponsorId = result.Data.customerIDField;
                            this._enrollSession.data.RetailEnroll.EnrollerId = result.Data.customerIDField;
                            this._enrollSession.data.RetailEnroll.webAliasField = result.Data.webAliasField;
                            this._enrollSession.data.CustomerWebAlias = result.Data.webAliasField;
                            this._enrollSession.data.ValidGiftCardCode = this._enrollSession.data.RetailEnroll.webAliasField;
                            this._enrollSession.data.RetailEnroll.webAlias = result.Data.firstNameField + ' ' + result.Data.lastNameField;
                            this._enrollSession.data.ReferredBy = result.Data.webAliasField;
                            this._enrollSession.data.RetailEnroll.isValidwebalias = true;
                        } else {
                            this._enrollSession.data.RetailEnroll.isValidwebalias = 'false';
                            this._toastr.error('Invalid WebAlias.', 'Error');
                            this._enrollSession.data.CustomerWebAlias = '';
                            this._enrollSession.data.ReferredBy = '';
                            this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode = '';

                        }
                    }
                    else {
                        if (result.Data) {
                            this._enrollSession.data.ReferredByGiftCard = '';
                            this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode = '';
                            this._toastr.error('This Referral Code is not Available', 'Error');
                        }
                        else {
                            
                        }
                    }
                }, (err) => {
                    console.log(err);
                    this._enrollSession.data.RetailOrderSummary.referredFormData.referralCode = '';
                    this._toastr.error('Somthing is wrong Please try again.', 'Error');
                }, () => {

                });
            }
        } catch (e) {
            this._toastr.error('Somthing is wrong Please try again.', 'Error');
        }
    }
    secureShippingMethod(checkSecureShipping?:boolean)
    {
        console.log("secureshipping",checkSecureShipping);
        if (checkSecureShipping) {
            localStorage.setItem("SecureShipping",'true');
        } else {
            localStorage.setItem("SecureShipping", 'false');
            const dialogRef = this._matDialog.open(secureShippingCheckNoDialogComponent, {
                width: '500px',
                maxWidth: '500px',
                height: '400px',
                panelClass: '',
                disableClose: true,
                data: {

                }
            });
            dialogRef.afterClosed().subscribe(result => {
            });
        }
    }
}
