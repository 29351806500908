<div class="dialog-content-wrapper" id="two-way-wrapper">
    <div style="display:flex;overflow-y: hidden;" class="pop-up-background">
      <div class="pop-up-div">
        <h1 class="pop-up-headline">Update Details</h1>

        <!------------------------------------- Shipping Details -------------------------------------------->
        <div class="w-form" *ngIf="data == 'shippingDetails'">
          <form [formGroup]="ReviewshippingForm" id="email-form2" name="email-form" class="shipping-info-form">
            <div fxLayout="column">
              <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                <div fxLayout="column" fxFlex="49">
                  <div>
                    <input type="text" class="form-field w-input" maxlength="256" name="First-Name"
                      formControlName="firstName" placeholder="First Name*" id="First-Name" required>
                  </div>
                  <div class="error-box">
                    <span class="help-block"
                      *ngIf="!ReviewshippingForm.get('firstName').valid&&ReviewshippingForm.get('firstName').touched">
                      <span *ngIf="ReviewshippingForm.get('firstName').errors['required']">This field is required</span>
                    </span>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="49">
                  <div>
                    <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
                      formControlName="lastName" placeholder="Last Name*" id="Last-Name-2" required>
                  </div>
                  <div class="error-box">
                    <span class="help-block p-l-10"
                      *ngIf="!ReviewshippingForm.get('lastName').valid&&ReviewshippingForm.get('lastName').touched">
                      <span *ngIf="ReviewshippingForm.get('lastName').errors['required']">This field is required</span>
                    </span>
                  </div>
                </div>
              </div>
              <div fxLayout="row" fxFlex="100">
                <div fxLayout="column" fxFlex="100">
                  <div>
                    <input type="text" class="form-field w-input" maxlength="256" name="Address-1"
                      formControlName="address1" placeholder="Address 1*" id="Address-1" required>
                  </div>
                  <div class="error-box">
                    <span class="help-block"
                      *ngIf="!ReviewshippingForm.get('address1').valid&&ReviewshippingForm.get('address1').touched">
                      <span *ngIf="ReviewshippingForm.get('address1').errors['required']">This field is required</span>
                    </span>
                  </div>
                </div>
              </div>
              <div fxLayout="row" fxFlex="100">
                <input type="text" class="form-field w-input" maxlength="256" name="address-2" formControlName="address2"
                  placeholder="Address 2 (Optional)" id="address-3">
              </div>
              <div fxLayout="row" fxFlex="100">
                <div fxLayout="column" fxFlex="100">
                  <div>
                    <input type="text" class="form-field w-input" maxlength="256" name="City" formControlName="City"
                      placeholder="City*" id="City-2" required>
                  </div>
                  <div class="error-box">
                    <span class="help-block p-l-10"
                      *ngIf="!ReviewshippingForm.get('City').valid&&ReviewshippingForm.get('City').touched">
                      <span *ngIf="ReviewshippingForm.get('City').errors['required']">This field is required</span>
                    </span>
                  </div>
                </div>
              </div>
              <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                <div fxLayout="column" fxFlex="32">
                  <div>
                    <select name="CountryCode" formControlName="CountryCode" class="form-field w-input"
                      [(ngModel)]="_enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode"
                      (change)="countryChange(_enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode,1)">
                      <option *ngFor="let country of _enrollSession.data.countriesList" [value]='country.countryCodeField'>{{
                        country.countryNameField}} </option>
                    </select>
                  </div>
                  <div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="32">
                  <div>
                    <div>
                      <select name="State" id="" formControlName="State" class="form-field w-input"
                        [(ngModel)]="!_enrollSession.data.RetailOrderSummary.shippingFormData.State ? ('') : _enrollSession.data.RetailOrderSummary.shippingFormData.State"
                        (change)="stateChange(_enrollSession.data.RetailOrderSummary.shippingFormData.State,1)">
                        <option *ngFor="let state of _enrollSession.data.ShippingStateList" [ngValue]='state.regionCodeField'>
                          {{state.regionNameField}}</option>
                      </select>
                    </div>
                    <div class="error-box">
                      <span class="help-block p-l-10"
                        *ngIf="!ReviewshippingForm.get('State').valid&&ReviewshippingForm.get('State').touched">
                        <span *ngIf="ReviewshippingForm.get('State').errors['required']">This field is required</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="32">
                  <div>
                    <input type="text" class="form-field  w-input" maxlength="256" name="Zip-Code"
                      formControlName="ZipCode" placeholder="Zip Code*" id="Zip-Code-2" required
                      (keyup)="regexValidateZip2($event)"
                      (focusout)="_enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode?checkCalculateOrder():''">
                  </div>
                  <div class="error-box">
                    <span class="error-zip" *ngIf="invalidzip2">INVALID ZIP</span>
                    <span class="help-block"
                      *ngIf="!ReviewshippingForm.get('ZipCode').valid&&ReviewshippingForm.get('ZipCode').touched">
                      <span *ngIf="ReviewshippingForm.get('ZipCode').errors['required']">This field is required</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!------------------------------------- Billing Details ---------------------------------------------->
        <div class="w-form" *ngIf="data == 'billingDetails'">
          <form [formGroup]="ReviewbillingAddressForm" id="email-form" name="email-form" class="shipping-info-form">
            <ng-container>
              <div fxLayout="column" class="p-20-15-10">
                <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                  <div fxLayout="column" fxFlex="49">
                    <div>
                      <input type="text" class="form-field w-input" maxlength="256" name="First-Name" formControlName="firstName" placeholder="First Name*" id="First-Name" required>
                    </div>
                    <div class="error-box">
                      <span class="help-block" *ngIf="!ReviewbillingAddressForm.get('firstName').valid&&ReviewbillingAddressForm.get('firstName').touched">
                        <span *ngIf = "ReviewbillingAddressForm.get('firstName').errors['required']">This field is required</span>
                      </span>
                    </div>
                  </div>
                  <div fxLayout="column" fxFlex="49">
                    <div>
                      <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name" formControlName="lastName" placeholder="Last Name*" id="Last-Name-2" required>
                    </div>
                    <div class="error-box">
                      <span class="help-block p-l-10" *ngIf="!ReviewbillingAddressForm.get('lastName').valid&&ReviewbillingAddressForm.get('lastName').touched">
                        <span *ngIf = "ReviewbillingAddressForm.get('lastName').errors['required']">This field is required</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div fxLayout="row" fxFlex="100">
                  <div fxLayout="column" fxFlex="100">
                    <div>
                      <input type="text" class="form-field w-input" maxlength="256" name="Address-1" formControlName="address1" placeholder="Address 1*" id="Address-1" required>                    </div>
                    <div class="error-box">
                      <span class="help-block" *ngIf="!ReviewbillingAddressForm.get('address1').valid&&ReviewbillingAddressForm.get('address1').touched">
                        <span *ngIf = "ReviewbillingAddressForm.get('address1').errors['required']">This field is required</span>
                      </span>
                    </div>
                 </div>
                 </div>
                 <div fxLayout="row" fxFlex="100">
                  <input type="text" class="form-field w-input" maxlength="256" name="address-2" formControlName="address2" placeholder="Address 2 (Optional)" id="address-3">
                 </div>
                 <div fxLayout="row" fxFlex="100">
                  <div fxLayout="column" fxFlex="100">
                    <div>
                      <input type="text" class="form-field w-input" maxlength="256" name="City" formControlName="City" placeholder="City*" id="City-2" required>
                    </div>
                    <div class="error-box">
                      <span class="help-block p-l-10" *ngIf="!ReviewbillingAddressForm.get('City').valid&&ReviewbillingAddressForm.get('City').touched">
                        <span *ngIf = "ReviewbillingAddressForm.get('City').errors['required']">This field is required</span>
                      </span>
                    </div>
                 </div>
               </div>
                <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                  <div fxLayout="column" fxFlex="32">
                    <div>
                      <select name="CountryCode" formControlName="CountryCode" class="form-field w-input" [(ngModel)]="_enrollSession.data.RetailOrderSummary.billingAddressData.CountryCode"
                      (change)="getStates(this._enrollSession.data.RetailOrderSummary.billingAddressData.CountryCode,3)">
                        <option *ngFor="let country of _enrollSession.data.countriesList" [value]='country.countryCodeField'>{{ country.countryNameField}} </option>
                      </select>
                    </div>
                    <div>
                    </div>
                  </div>
                  <div fxLayout="column" fxFlex="32">
                    <div>
                      <div>
                        <select name="State" formControlName="State" class="form-field w-input" [(ngModel)]="!_enrollSession.data.RetailOrderSummary.billingAddressData.State ? '' : _enrollSession.data.RetailOrderSummary.billingAddressData.State" required>
                          <option *ngFor="let state of _enrollSession.data.stateList"
                          [ngValue]='state.regionCodeField'>{{ state.regionNameField}}</option>
                        </select>
                      </div>
                      <div class="error-box">   
                       <span class="help-block p-l-10" *ngIf="!ReviewbillingAddressForm.get('State').valid&&ReviewbillingAddressForm.get('State').touched">
                        <span *ngIf = "ReviewbillingAddressForm.get('State').errors['required']">This field is required</span>
                        </span>
                      </div>
                   </div>
                  </div>
                  <div fxLayout="column" fxFlex="32">
                    <div>
                     <input type="text" class="form-field  w-input" maxlength="256" name="Zip-Code" formControlName="ZipCode" placeholder="Zip Code*" id="Zip-Code-2"  (keyup)="regexValidateZip1($event)" required>
                    </div>
                    <div class="error-box">
                     <span class="error-zip" *ngIf="invalidzip1">INVALID ZIP</span>
                      <span class="help-block" *ngIf="!ReviewbillingAddressForm.get('ZipCode').valid&&ReviewbillingAddressForm.get('ZipCode').touched">
                        <span *ngIf = "ReviewbillingAddressForm.get('ZipCode').errors['required']">This field is required</span>
                      </span>
                    </div>
                 </div>
                </div>
              </div>
            </ng-container>
          </form>
        </div>

        <!-------------------------------------- Shipping Method ---------------------------------------------->
        <div class="w-form" *ngIf="data == 'shippingMethod'">
          <form [formGroup]="ReviewshippingMethodForm" id="email-form" name="email-form3" class="shipping-method-form">
            <div *ngFor="let shipping of _enrollSession.data.ShippingMethods">
              <label class="shipping-radio-button-field w-radio m-b-1">
                <input type="radio" name="shippingType" formControlName="shippingType" value="{{shipping.ShipMethodID}}"
                  class="w-form-formradioinput shipping-method-radio-button w-radio-input">
                <span class="shipping-radio-button-label w-form-label">{{shipping.Description}}</span>
                <div class="shipping-price-text">${{shipping.ShippingAmount |number : '1.2-2'}}</div>
              </label>
            </div>
            <div>
              <label class="secure-shipping-align billing-address-radio-button-field w-radio">
                <span class="f-s-12 secureShippingTagLine">Protect your order with Signature Required ($6 fee) &nbsp; - &nbsp;</span>
                  <div class="display-flex-radio">
                    <label>
                      <input type="radio" id="radio-3" name="SecureShipping" value="yes" formControlName="SecureShipping" class="w-form-formradioinput shipping-method-radio-button" (click)="secureShippingMethod(true)">
                    <span class="billing-address-radio-button-label w-form-label">Yes &nbsp; &nbsp;</span>
                    </label>
                  </div>
                  <div class="display-flex-radio">
                    <label>
                      <input type="radio" id="radio-4" name="SecureShipping" value="no" formControlName="SecureShipping" class="w-form-formradioinput shipping-method-radio-button" (click)="secureShippingMethod(false)" checked="true">
                    <span class="billing-address-radio-button-label w-form-label">No</span>
                    </label>
                  </div>
              </label>
            </div>
          </form>
        </div>

        <!--------------------------------------- Payment Method ----------------------------------------------->
        <div class="payment-form-block w-form" *ngIf="data == 'paymentMethod'">
          <form [formGroup]="ReviewpaymentTypeForm" id="email-form-2" class="payment-form" *ngIf="!authService.isLoggedIn()">
            <div fxLayout="column">
              <label class="radio-button-field w-radio p-0">
                <span class="radio-button-label w-form-label">Credit/Debit Card</span>
              </label>

              <label class="f-s-14 p-l-10 w-form-label">Card Number*</label>

              <input type="number" class="payment-text-field w-input" maxlength="16" name="cardNumber" formControlName="cardNumber" placeholder="Card number*" id="Card-Number" required>
              <span class="help-block" *ngIf="!ReviewpaymentTypeForm.get('cardNumber').valid&&ReviewpaymentTypeForm.get('cardNumber').touched">
                <span *ngIf = "ReviewpaymentTypeForm.get('cardNumber').errors['required']">This field is required</span>
              </span>
              
              <input type="text" class="payment-text-field w-input" maxlength="256" name="nameOnCard" formControlName="nameOnCard"  placeholder="Name on card*" id="Name-on-card" required>
              <span class="help-block" *ngIf="!ReviewpaymentTypeForm.get('nameOnCard').valid&&ReviewpaymentTypeForm.get('nameOnCard').touched">
                <span *ngIf = "ReviewpaymentTypeForm.get('nameOnCard').errors['required']">This field is required</span>
              </span>
              <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                  <div fxFlex="49" fxLayout="column">
                    <div>
                      <select name="expMonth" formControlName="expMonth" class="payment-text-field w-input" placeholder="Select Month*"  [(ngModel)]="!_enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth?'':_enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth">
                        <option value="" disabled selected hidden>Select Month*</option>
                        <option *ngFor="let month of monthList" [value]='month.key'>
                          {{month.value}}</option>
                      </select>
                    </div>
                    <div>
                      <span class="help-block" *ngIf="!ReviewpaymentTypeForm.get('expMonth').valid&&ReviewpaymentTypeForm.get('expMonth').touched">
                        <span *ngIf = "ReviewpaymentTypeForm.get('expMonth').errors['required']">This field is required</span>
                      </span>
                    </div>
                  </div>
                  <div fxFlex="49" fxLayout="column">
                    <div>
                      <select name="expYear" formControlName="expYear" class="payment-text-field w-input" placeholder="Select Year*"  [(ngModel)]="!_enrollSession.data.RetailOrderSummary.paymentTypeData.expYear?'':_enrollSession.data.RetailOrderSummary.paymentTypeData.expYear">
                        <option value="" selected disabled hidden>Select Year*</option>
                        <option *ngFor="let year of yearList" [value]='year.key'>
                          {{ year.value}}</option>
                      </select>
                    </div>
                    <div>
                      <span class="help-block" *ngIf="!ReviewpaymentTypeForm.get('expYear').valid&&ReviewpaymentTypeForm.get('expYear').touched">
                        <span *ngIf = "ReviewpaymentTypeForm.get('expYear').errors['required']">This field is required</span>
                      </span>
                    </div>
                  </div>
              </div>
              <input type="text" class="payment-text-field w-input" maxlength="4" name="securityCode" formControlName="securityCode"  placeholder="Security code*" id="Security-code" required>
              <span class="help-block" *ngIf="!ReviewpaymentTypeForm.get('securityCode').valid&&ReviewpaymentTypeForm.get('securityCode').touched">
                  <span *ngIf="ReviewpaymentTypeForm.get('securityCode').errors['required']">This field is required</span>
                  <span *ngIf="!ReviewpaymentTypeForm.get('securityCode').errors['required'] && !ReviewpaymentTypeForm.get('securityCode').valid">Invalid Security code</span>
              </span>
            </div>
          </form>
          <div class="payment-form-block w-form" *ngIf="authService.isLoggedIn()">
            <form [formGroup]="ReviewpaymentTypeForm" id="email-form-2" class="payment-form">
              <label class="billing-address-radio-button-field-top w-radio" *ngIf="creditCardNumberDisplayField">
                <input type="radio" id="radio" name="primaryPaymentCard" value="true" formControlName="primaryPaymentCard" class="w-form-formradioinput shipping-method-radio-button w-radio-input" (click)="differentPaymentMethod('paymentSame')">
                <span class="billing-address-radio-button-label w-form-label" [textContent]="'Card ending in' + creditCardNumberDisplayField"></span><br>
                <span class="billing-address-radio-button-label w-form-label s_expiration" [textContent]="'Expiration ' + expirationMonthField +'/'+ expirationYearField"></span>
              </label>
              <label class="billing-address-radio-button-field-top w-radio" *ngIf="!creditCardNumberDisplayField">
                <span class="billing-address-radio-button-label w-form-label f-w-800" [textContent]="'No Card Saved'"></span><br>
              </label>
              <div *ngIf="_calculateRetailOrderService.balanceFieldPoint>1000">
              <label class="billing-address-radio-button-field w-radio" [ngClass]="{'b-top':creditCardNumberDisplayField}">
                  <div>
                    <input type="radio" id="radio-3" name="RewardPoints" (click)="ClickToOpenDiscount($event)" value="false" class="w-form-formradioinput shipping-method-radio-button w-radio-input">
                  <span class="billing-address-radio-button-label w-form-label">Reward Points</span>
                  </div>
              </label>
            </div>
              <ng-container>
                <div class="DiscountBox" *ngIf="isOpenDiscountDropdown && _calculateRetailOrderService.balanceFieldPoint>1000">
                  <span class="discount-heading">Points can be redeemed on the order subtotal :</span>
                  <div class="discountSelection" fxFlex="49" fxFlex.sm="column" fxFlex.xs="column">
                    <select name="DiscountPoints" (change)="_calculateRetailOrderService.Selected($event)" class="form-field discountform">
                      <option [value]="0">Choose Discount</option>
                      <option *ngFor="let discount of _calculateRetailOrderService.DiscountOption" [value]="discount">
                        ${{discount}} Discount : {{discount * 100}} Point</option>
                    </select>
                    <div fxFlex>
                      <button class="button-redeeem hover-white" (click)="_calculateRetailOrderService.RedeemDiscount(true); _calculateRetailOrderService.isSelected = true">Redeem</button>
                    </div>
                  </div>
                </div>
              </ng-container>
              <label class="billing-address-radio-button-field w-radio" [ngClass]="{'b-top':creditCardNumberDisplayField}">
                <input type="radio" id="radio-3" name="primaryPaymentCard" value="false" formControlName="primaryPaymentCard" class="w-form-formradioinput shipping-method-radio-button w-radio-input" (click)="differentPaymentMethod('paymentDifferent')">
                <span class="billing-address-radio-button-label w-form-label">Add New Payment</span>
              </label>
              <ng-container *ngIf="ReviewpaymentTypeForm.get('primaryPaymentCard').value == 'false'">
                <div fxLayout="column">
                  <label class="radio-button-field w-radio p-0">
                    <span class="radio-button-label w-form-label">Credit/Debit Card</span>
                  </label>
                  <input type="number" class="payment-text-field w-input" maxlength="16" name="cardNumber" formControlName="cardNumber" placeholder="Card number*" id="Card-Number" required>
                  <span class="help-block" *ngIf="!ReviewpaymentTypeForm.get('cardNumber').valid&&ReviewpaymentTypeForm.get('cardNumber').touched">
                  <span *ngIf="ReviewpaymentTypeForm.get('cardNumber').errors['required']">This field is required</span>
                  </span>
                  <input type="text" class="payment-text-field w-input" maxlength="256" name="nameOnCard" formControlName="nameOnCard"  placeholder="Name on card*" id="Name-on-card" required>
                  <span class="help-block" *ngIf="!ReviewpaymentTypeForm.get('nameOnCard').valid&&ReviewpaymentTypeForm.get('nameOnCard').touched">
                    <span *ngIf = "ReviewpaymentTypeForm.get('nameOnCard').errors['required']">This field is required</span>
                  </span>
                  <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                      <div fxFlex="49" fxLayout="column">
                        <div>
                          <select name="expMonth" formControlName="expMonth" class="payment-text-field w-input" placeholder="Select Month*"  [(ngModel)]="!_enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth?'':_enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth">
                            <option value="" disabled selected hidden>Select Month*</option>
                            <option *ngFor="let month of monthList" [value]='month.key'>
                              {{month.value}}</option>
                          </select>
                        </div>
                        <div>
                          <span class="help-block" *ngIf="!ReviewpaymentTypeForm.get('expMonth').valid&&ReviewpaymentTypeForm.get('expMonth').touched">
                            <span *ngIf = "ReviewpaymentTypeForm.get('expMonth').errors['required']">This field is required</span>
                          </span>
                        </div>
                      </div>
                      <div fxFlex="49" fxLayout="column">
                        <div>
                          <select name="expYear" formControlName="expYear" class="payment-text-field w-input" placeholder="Select Year*"  [(ngModel)]="!_enrollSession.data.RetailOrderSummary.paymentTypeData.expYear?'':_enrollSession.data.RetailOrderSummary.paymentTypeData.expYear" required>
                            <option value="" selected disabled hidden>Select Year*</option>
                            <option *ngFor="let year of yearList" [value]='year.key'>{{ year.value}}</option>
                          </select>
                        </div>
                        <div>
                          <span class="help-block" *ngIf="!ReviewpaymentTypeForm.get('expYear').valid&&ReviewpaymentTypeForm.get('expYear').touched">
                            <span *ngIf = "ReviewpaymentTypeForm.get('expYear').errors['required']">This field is required</span>
                          </span>
                        </div>
                      </div>
                  </div>
                  <input type="text" class="payment-text-field w-input" maxlength="4" name="securityCode" formControlName="securityCode"  placeholder="Security code*" id="Security-code" required>
                  <span class="help-block" *ngIf="!ReviewpaymentTypeForm.get('securityCode').valid&&ReviewpaymentTypeForm.get('securityCode').touched">
                      <span *ngIf="ReviewpaymentTypeForm.get('securityCode').errors['required']">This field is required</span>
                      <span *ngIf="!ReviewpaymentTypeForm.get('securityCode').errors['required'] && !ReviewpaymentTypeForm.get('securityCode').valid">Invalid Security code</span>
                  </span>
                </div>
              </ng-container>
            </form>
          </div>
        </div>

        <!-------------------------------------- submit Button ---------------------------------------------->
        <div class="pop-up-button-div">
          <button mat-button class="pop-up-button pop-up-button-no w-button" (click)="Close()">Cancel
            </button> &nbsp;
          <button mat-button aria-current="page" class="pop-up-button w-button w--current" [mat-dialog-close]=""
            cdkFocusInitial (click)="onSave()">Save</button>
        </div>
      </div>
    </div>
  </div>
  
  
  